import React from "react";
import Box from "@mui/material/Box";

const Panel: React.FC<any> = (props) => {
  const { children, sx, ...rest } = props;

  return (
    <Box p={3} {...rest} sx={{ ...sx, backgroundColor: "#ffffff" }}>
      {children}
    </Box>
  );
};

export default Panel;
