import React, { useState, useEffect, useCallback } from "react";
import MUIAutocomplete from "@mui/material/Autocomplete";
import TextField from "@shared/ui/atoms/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import debounce from "lodash/debounce";
import { AutocompleteProps } from "./Autocomplete.types";
import useStyles from "./Autocomplete.styles";

const Autocomplete: React.FC<AutocompleteProps> = (props) => {
  const {
    placeholder: propPlaceholder,
    onChange: propOnChange,
    value: propValue,
    inputProps: propInputProps,
    renderOption: propRenderOption,
    getOptionLabel: propGetOptionLabel,
    fetchOptions: propFetchOptions,
    onInputChange: propOnInputChange,
    error: propError,
    helperText: propHelperText
  } = props;

  const { classes } = useStyles();
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [spinner, setSpinner] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const loadData = async (value: any) => {
    setSpinner(true);

    const result = await propFetchOptions(value);

    if (result) {
      setSpinner(false);
      setOptions(result);
    }
  };

  const debounceLoadData = useCallback(
    debounce((event: any, value: any) => {
      if (event.type !== "change") return;
      if (value.length < 3) return;

      loadData(value);
    }, 750),
    []
  );

  const addSpinnerToEndAdornment = (endAdornment: any) => {
    const children = React.Children.toArray(endAdornment.props.children);

    if (spinner === true) {
      children.pop();

      children.push(
        <CircularProgress
          key="CircularProgress"
          color="primary"
          size="1.75rem"
          style={{ padding: 6 }}
        />
      );
    }

    return React.cloneElement(endAdornment, {}, children);
  };

  useEffect(() => {
    setInputValue(propValue);
  }, []);

  return (
    <>
      <MUIAutocomplete
        value={null}
        inputValue={inputValue}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        getOptionLabel={(option) => propGetOptionLabel(option)}
        options={options}
        filterOptions={() => options}
        fullWidth
        freeSolo
        size="small"
        onInputChange={(event: any, newValue: any) => {
          setOptions([]);
          setInputValue(newValue);
          propOnInputChange(event, newValue);
          debounceLoadData(event, newValue);
        }}
        onChange={(event: any, value: any, reason: string) => {
          if (reason !== "selectOption") return;

          setOptions([]);
          propOnChange(event, value);
        }}
        renderInput={({ inputProps, ...restParams }) => (
          <TextField
            {...restParams}
            error={propError}
            helperText={propHelperText}
            placeholder={propPlaceholder}
            InputProps={{
              inputProps,
              ...restParams.InputProps,
              ...propInputProps,
              endAdornment: addSpinnerToEndAdornment(
                restParams.InputProps.endAdornment
              )
            }}
            className={classes.root}
          />
        )}
        renderOption={(renderOptionProps: any, option: any) =>
          propRenderOption(renderOptionProps, option)
        }
      />
    </>
  );
};

export default Autocomplete;
