import React from "react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import {
  useGetSessionsQuery,
  useDeleteSessionsMutation,
  useDeleteAllSessionsMutation
} from "@api/TranstubeCore/sessionsApi";
import UserPanelTemplate from "@shared/ui/templates/UserPanelTemplate";
import Typography from "@shared/ui/atoms/Typography";
import Panel from "@shared/ui/molecules/Panel/Panel";
import { Box, Stack } from "@mui/material";
import LinearDeterminate from "@shared/ui/molecules/LinearDeterminate/LinearDeterminate";
import convertDateAndTime from "@shared/utils/convertDateAndTime";
import { removeCredentials, selectCurrentUser } from "@store/authSlice";
import { useDispatch, useSelector } from "react-redux";
import Button from "@shared/ui/atoms/Button";
import CheckMarkIcon from "@shared/ui/atoms/icons/CheckMarkIcon/CheckMarkIcon";

const AccountSessions: React.FC<any> = () => {
  const { data: sessionsResult = { data: undefined } } = useGetSessionsQuery(
    {}
  );
  const currentUser = useSelector(selectCurrentUser);
  const { data: sessions } = sessionsResult;
  const [deleteSession] = useDeleteSessionsMutation();
  const [deleteAllSessions] = useDeleteAllSessionsMutation();
  const dispatch = useDispatch();

  const handleClickDestroySession = async (session: any) => {
    await deleteSession({
      id: session.id
    })
      .unwrap()
      .then(() => {
        if (currentUser.meta.current_session_id === session.id) {
          dispatch(removeCredentials());
        }
      });
  };

  const handleClickAllDestroySession = async () => {
    await deleteAllSessions({})
      .unwrap()
      .then(() => {
        dispatch(removeCredentials());
      });
  };

  const logOut = (session: any) => {
    return (
      <Button variant="text" onClick={() => handleClickDestroySession(session)}>
        Wyloguj
      </Button>
    );

    return <></>;
  };

  const currentBrowser = (session: any) => {
    if (currentUser.meta.current_session_id === session.id) {
      return (
        <>
          <CheckMarkIcon color="turquoise.light" height="25px" width="25px" />
          <Typography variant="body2" color="text.turquoise.dark">
            <strong>{session.browserName}</strong> (to urządzenie) <br />{" "}
            {session.platformName} {session.platformVersion}
          </Typography>
        </>
      );
    }

    return (
      <>
        <Box sx={{ width: "25px", height: "25px" }} />
        <Typography variant="body2" color="text.turquoise.dark">
          <strong>{session.browserName}</strong> <br /> {session.platformName}{" "}
          {session.platformVersion}
        </Typography>
      </>
    );
  };

  if (!sessions) return <LinearDeterminate />;

  return (
    <UserPanelTemplate
      seoProps={{
        title: "Sesje"
      }}
    >
      <Panel>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                pb: 3
              }}
            >
              <Typography variant="h5" component="h1">
                Twoje sesje
              </Typography>
              <Button
                variant="text"
                onClick={() => handleClickAllDestroySession()}
              >
                Wyloguj ze wszystkich urządzeń
              </Button>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box>
              <Typography variant="body2" color="text.turquoise.dark">
                {" "}
                <strong>Urządzenie</strong>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box>
              <Typography variant="body2" color="text.turquoise.dark">
                <strong>Ostatnie logowanie</strong>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box>
              <Typography variant="body2" color="text.turquoise.dark">
                <strong>IP</strong>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Divider light />
          </Grid>
          {sessions.map((session: any) => (
            <React.Fragment key={session.id}>
              <Grid item xs={4} sx={{ alignSelf: "center" }}>
                <Stack direction="row" spacing={2} alignItems="center">
                  {currentBrowser(session)}
                </Stack>
              </Grid>
              <Grid item xs={4} sx={{ alignSelf: "center" }}>
                <Typography variant="body2" color="text.turquoise.dark">
                  {convertDateAndTime(session.createdAt)}
                </Typography>
              </Grid>
              <Grid item xs={2} sx={{ alignSelf: "center" }}>
                <Typography variant="body2" color="text.turquoise.dark">
                  {session.ipAddress}
                </Typography>
              </Grid>
              <Grid item xs={2} sx={{ alignSelf: "center" }}>
                {logOut(session)}
              </Grid>
              <Grid item xs={12}>
                <Divider light />
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Panel>
    </UserPanelTemplate>
  );
};

export default AccountSessions;
