import React from "react";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import Stack from "@mui/material/Stack";
import Popover from "@mui/material/Popover";
import Typography from "@shared/ui/atoms/Typography";
import UserAvatar from "../UserAvatar/UserAvatar";

const UserNameBadge: React.FC<any> = (props) => {
  const { color, displayName, numberRatings, averageRating, size, to } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box display="flex">
      <Stack spacing={1} direction="row">
        <UserAvatar
          size={size}
          color={color}
          to={to}
          sx={{ cursor: "pointer" }}
        >
          {displayName}
        </UserAvatar>

        <Box
          display="inline-block"
          alignSelf="center"
          aria-owns={open ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <Typography
            variant="body1"
            color="text.turquoise.dark"
            sx={{ wordBreak: "break-word" }}
          >
            <strong>{displayName}</strong>
          </Typography>

          <Box
            display="flex"
            aria-owns={open ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            <StarIcon
              sx={{
                fontSize: "1rem",
                position: "relative",
                top: "1px",
                right: "2px",
                color: "#708c91"
              }}
            />
            <Typography variant="body2" color="text.grey.main">
              {averageRating}/5.0
            </Typography>

            <Popover
              id="mouse-over-popover"
              sx={{
                pointerEvents: "none",
                "& .MuiPaper-elevation": { background: "#E5F6FD" }
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Typography variant="body2" color="text.grey.main" sx={{ p: 1 }}>
                {numberRatings} ocen
              </Typography>
            </Popover>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default UserNameBadge;
