import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

const useSectionStyles = makeStyles()((theme: Theme) => ({
  navLink: {
    textDecoration: "none",
    "&.active .arrow": {
      display: "block"
    },
    ".arrow": {
      display: "none",
      fontSize: "32px",
      color: "#1E99C0"
    },
    "&:hover .arrow": {
      display: "block",
      color: "#4fc1e3"
    }
  },
  box: {
    "&.MuiBox-root": {
      justifyContent: "space-between",
      padding: theme.spacing(1),
      backgroundColor: "#ffffff",
      height: "55px"
    }
  },
  boxItem: {
    "&.MuiBox-root": {
      padding: theme.spacing(0.7),
      justifyContent: "center",
      display: "flex",
      alignItems: "center"
    }
  }
}));

export default useSectionStyles;
