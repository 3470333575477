import { combineReducers, configureStore } from "@reduxjs/toolkit";
import api from "@api/TranstubeCore/api";

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from "redux-persist";

import storage from "redux-persist/lib/storage";
import authReducer from "./authSlice";
import dialogReducer from "./dialogSlice";
import snackbarReducer from "./snackbarSlice";

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  auth: authReducer,
  dialog: dialogReducer,
  snackbar: snackbarReducer
});

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: [api.reducerPath]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat(api.middleware),
  devTools: true
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
