import * as yup from "yup";

const validationSchema = (i18n: any) => {
  return yup.object({
    password: yup.string().min(8).required(),
    passwordConfirmation: yup
      .string()
      .required()
      .when("password", {
        is: (val: string) => !!(val && val.length > 0),
        then: yup
          .string()
          .oneOf(
            [yup.ref("password")],
            i18n.t(
              "yup.validations.user.passwordConfirmation.passwords_entered_must_be_same"
            )
          )
      })
  });
};

export default validationSchema;
