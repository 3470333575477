const shortenQuestion = (question: any) => {
  if (question.length >= 100) {
    const cutLetters = `${question.substring(0, 100)}...`;

    return cutLetters;
  }

  return `${question}`;
};

export default shortenQuestion;
