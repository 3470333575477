const handleFormError = (
  pointers: any,
  error: any,
  i18n: any,
  actions: any
) => {
  const pointer = pointers[error.source.pointer];

  if (!pointer) return;

  const i18nKey = ["api", "errors", pointer.i18n, error.code].join(".");
  const fallbackI18nKey = ["api", "errors", error.code].join(".");

  actions.setFieldError(
    pointer.field,
    i18n.t([i18nKey, fallbackI18nKey], { defaultValue: error.detail })
  );
};

export default handleFormError;
