import { TCancelChange, TGetAccount, TUpdateAccount } from "./accountApi.types";
import api from "./api";

export const accountApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAccount: builder.query<TGetAccount, any>({
      query: ({ params }) => ({
        url: "/account",
        params: {
          include: "company",
          ...params
        }
      })
    }),
    updateAccount: builder.mutation<TUpdateAccount, any>({
      query: ({ body }) => ({
        url: `/account`,
        method: "PUT",
        params: {
          include: "company"
        },
        body: { ...body }
      })
    }),
    cancelChange: builder.mutation<TCancelChange, any>({
      query: () => ({
        url: `/account/email/cancel_change`,
        method: "POST",
        params: {
          include: "company"
        },
        body: {}
      })
    })
  })
});

export const {
  useGetAccountQuery,
  useLazyGetAccountQuery,
  useUpdateAccountMutation,
  useCancelChangeMutation
} = accountApi;
