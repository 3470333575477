import * as React from "react";
import MuiAccordion from "@mui/material/Accordion";

const CustomAccordion: React.FC<any> = (props) => {
  const { children } = props;

  return (
    <div style={{ marginBottom: 7 }}>
      <MuiAccordion square disableGutters>
        {children}
      </MuiAccordion>
    </div>
  );
};

export default CustomAccordion;
