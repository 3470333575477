import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.common.white
  },
  hrDecoration: {
    width: 50,
    borderColor: "#1e99c0",
    borderRadius: 10
  },
  image: {
    height: "72px"
  },
  connector: {
    backgroundColor: "#dae4e7",
    height: "64px"
  },
  dot: {
    borderColor: "#4fc1e3",
    padding: "8px"
  }
}));

export default useStyles;
