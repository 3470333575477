import * as React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

import Typography from "@shared/ui/atoms/Typography";
import location from "@assets/images/stepper/location.svg";
import search from "@assets/images/stepper/search.svg";
import card from "@assets/images/stepper/credit_cards.svg";
import car from "@assets/images/stepper/car.svg";
import end from "@assets/images/stepper/location_end.svg";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator
} from "@mui/lab";
import { Trans, useTranslation } from "react-i18next";
import useStyles from "./Stepper.styles";

const CustomizedSteppers = () => {
  const { classes } = useStyles();
  const { i18n } = useTranslation();

  const items: any = [
    {
      title: i18n.t("pages.home.stepper.add_an_order"),
      description: i18n.t("pages.home.stepper.add_an_order_description"),
      icon: <img src={location} alt="location" className={classes.image} />
    },
    {
      title: i18n.t("pages.home.stepper.choose_the_best_offer"),
      description: i18n.t(
        "pages.home.stepper.choose_the_best_offer_description"
      ),
      icon: <img src={search} alt="search" className={classes.image} />
    },
    {
      title: i18n.t(
        "pages.home.stepper.make_an_advance_payment_for_the_selected_ride"
      ),
      description: i18n.t(
        "pages.home.stepper.make_an_advance_payment_for_the_selected_ride_description"
      ),
      icon: <img src={card} alt="card" className={classes.image} />
    },
    {
      title: i18n.t("pages.home.stepper.agree_on_the_details_of_the_ride"),
      description: i18n.t(
        "pages.home.stepper.agree_on_the_details_of_the_ride_description"
      ),
      icon: <img src={car} alt="car" className={classes.image} />
    },
    {
      title: i18n.t("pages.home.stepper.get_there"),
      description: i18n.t("pages.home.stepper.get_there_description"),
      icon: <img src={end} alt="end" className={classes.image} />
    }
  ];

  return (
    <Box className={classes.root} py={8}>
      <Container>
        <Box textAlign="center" mb={8}>
          <Typography variant="h4" component="h2">
            <Trans i18nKey="pages.home.stepper.title" />
          </Typography>

          <hr className={classes.hrDecoration} />
        </Box>

        <Timeline position="alternate">
          {items.map((item: any, index: any) => (
            <TimelineItem key={item.title}>
              <TimelineOppositeContent
                sx={{ m: "auto 0" }}
                align={index % 2 === 0 ? "right" : "left"}
                color="text.secondary"
              >
                {item.icon}
              </TimelineOppositeContent>

              <TimelineSeparator>
                <TimelineConnector className={classes.connector} />
                <TimelineDot
                  className={classes.dot}
                  variant="outlined"
                  color="primary"
                />
                <TimelineConnector className={classes.connector} />
              </TimelineSeparator>

              <TimelineContent sx={{ m: "auto 0", px: 2 }}>
                <Typography variant="body1" color="text.turquoise.dark">
                  <strong>{item.title}</strong>
                </Typography>

                <Typography variant="body1" mt={1}>
                  {item.description}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Container>
    </Box>
  );
};

export default CustomizedSteppers;
