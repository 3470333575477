import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    right: "25px",
    bottom: "25px",
    background: theme.palette.primary.main,
    width: "40px",
    height: "40px",
    borderRadius: "0px",
    boxShadow: "none",
    zIndex: 3,
    svg: {
      height: "15px",
      color: theme.palette.common.white
    },
    ":active": {
      transform: "none"
    }
  }
}));

export default useStyles;
