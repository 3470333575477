import React from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import HelpTemplate from "@shared/ui/templates/HelpTemplate";
import Typography from "@shared/ui/atoms/Typography";
import Panel from "@shared/ui/molecules/Panel/Panel";
import { useTranslation } from "react-i18next";

const CanIExchangeMyContactDetails = () => {
  const { i18n } = useTranslation();

  return (
    <HelpTemplate
      seoProps={{
        title: i18n.t("pages.canIExchangeMyContactDetails.head.title")
      }}
    >
      <Grid item xs={12} sm={12} md={8} mt={3}>
        <Panel>
          <Stack spacing={2}>
            <Typography variant="h5" component="h1">
              Czy mogę wymieniać się swoimi danymi kontaktowymi?
            </Typography>

            <Box pt={1}>
              <Stack spacing={2}>
                <Typography>
                  Nie możesz podawać swoich danych szczegółowych w wiadomościach
                  na{" "}
                  <Box display="inline-block">
                    <Typography color="text.turquoise.main">
                      <strong>TransKing.eu</strong>
                    </Typography>
                  </Box>{" "}
                  , ponieważ nie przestrzeganie tej zasady skutkuje
                  zablokowaniem bądź usunięciem konta.
                </Typography>
                <Typography>
                  Dane kontaktowe przewoźnika zostają udostępnione w momencie
                  zawarcia transakcji.
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </Panel>
      </Grid>
    </HelpTemplate>
  );
};

export default CanIExchangeMyContactDetails;
