const displayNameInAvatar = (value: any) => {
  if (value) {
    const words = value.split(" ");

    const firstWord = words[0]?.charAt(0);
    const secondWord = words[1]?.charAt(0);

    if (firstWord === "" || firstWord === undefined) return "";
    if (secondWord === "" || secondWord === undefined) return "";

    return (firstWord + secondWord).toUpperCase();
  }

  return "";
};

export default displayNameInAvatar;
