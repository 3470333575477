import * as yup from "yup";
import dayjs from "dayjs";
import validationSchemaCategory from "../category/Category.validationSchema";

const yesterday = new Date(Date.now() - 86400000);
const maxDate = dayjs("2099-12-31");

const validationSchema = (i18n: any) => {
  return yup.object({
    fromLocation: yup.object({
      id: yup.string().required(),
      address: yup.string().required(),
      formattedAddress: yup.string().required(),
      countryIsoCode: yup.string().required(),
      latitude: yup.number().required(),
      longitude: yup.number().required()
    }),
    toLocation: yup.object({
      id: yup.string().required(),
      address: yup.string().required(),
      formattedAddress: yup.string().required(),
      countryIsoCode: yup.string().required(),
      latitude: yup.number().required(),
      longitude: yup.number().required()
    }),
    polyline: yup.string().required(),
    distance: yup.number().required().min(0),
    duration: yup.number().required().min(0),
    isSpecifyShippingDates: yup.boolean().required(),
    startOn: yup
      .date()
      .nullable()
      .when("isSpecifyShippingDates", {
        is: (isSpecifyShippingDates: boolean) => isSpecifyShippingDates,
        then: yup
          .date()
          .nullable()
          .required()
          .typeError(i18n.t("yup.validations.transport_order.start_on.invalid"))
          .min(
            yesterday,
            i18n.t("yup.validations.transport_order.start_on.min")
          )
          .max(
            maxDate,
            i18n.t("yup.validations.transport_order.start_on.invalid")
          )
      }),
    endOn: yup
      .date()
      .nullable()
      .when("isSpecifyShippingDates", {
        is: (isSpecifyShippingDates: boolean) => isSpecifyShippingDates,
        then: yup
          .date()
          .nullable()
          .required()
          .typeError(i18n.t("yup.validations.transport_order.end_on.invalid"))
          .min(
            yup.ref("startOn"),
            i18n.t("yup.validations.transport_order.end_on.min")
          )
          .max(
            maxDate,
            i18n.t("yup.validations.transport_order.end_on.invalid")
          )
      }),
    ...validationSchemaCategory(i18n).fields
  });
};

export default validationSchema;
