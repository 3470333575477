import api from "./api";
import {
  TGetPublicTransportOrder,
  TGetPublicTransportOrders,
  TGetTransportOrder,
  TGetTransportOrders
} from "./transportOrdersApi.types";
import { TResource, TTransportOrder } from "./types";

export const transportOrdersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPublicTransportOrders: builder.query<TGetPublicTransportOrders, any>({
      query: ({ params }) => ({
        url: "/public/transport_orders",
        params: {
          include: [
            "transport_order_line_items",
            "from_location",
            "to_location",
            "auction",
            "auction.user"
          ].join(","),
          sort: ["-created_at", "id"].join(","),
          ...params
        }
      }),
      providesTags: ["PublicTransportOrder"]
    }),
    getPublicTransportOrder: builder.query<TGetPublicTransportOrder, any>({
      query: ({ id }) => ({
        url: `/public/transport_orders/${id}`,
        params: {
          include: [
            "transport_order_line_items",
            "from_location",
            "to_location",
            "auction",
            "auction.user",
            "auction.best_offer",
            "auction.best_offer.user",
            "auction.winning_offer",
            "auction.winning_offer.user"
          ].join(",")
        }
      }),
      providesTags: ["PublicTransportOrder"]
    }),
    getTransportOrders: builder.query<TGetTransportOrders, any>({
      query: ({ params }) => ({
        url: "/transport_orders",
        params: {
          include: [
            "transport_order_line_items",
            "from_location",
            "to_location",
            "auction",
            "auction.user"
          ].join(","),
          sort: ["-created_at", "id"].join(","),
          ...params
        }
      }),
      providesTags: ["TransportOrder"]
    }),
    getTransportOrder: builder.query<TGetTransportOrder, any>({
      query: ({ id }) => ({
        url: `/transport_orders/${id}`,
        params: {
          include: [
            "transport_order_line_items",
            "from_location",
            "to_location",
            "auction",
            "auction.user",
            "auction.best_offer",
            "auction.best_offer.user",
            "auction.winning_offer",
            "auction.winning_offer.user"
          ].join(",")
        }
      }),
      providesTags: ["TransportOrder"]
    }),
    createTransportOrder: builder.mutation<TResource<TTransportOrder>, any>({
      query: ({ body }) => ({
        url: "/transport_orders",
        method: "POST",
        body: { ...body }
      })
    })
  })
});

export const {
  useLazyGetPublicTransportOrdersQuery,
  useGetPublicTransportOrderQuery,
  useLazyGetPublicTransportOrderQuery,
  useGetTransportOrdersQuery,
  useLazyGetTransportOrdersQuery,
  useGetTransportOrderQuery,
  useLazyGetTransportOrderQuery,
  useCreateTransportOrderMutation
} = transportOrdersApi;
