import { useEffect } from "react";
import { withRouter } from "react-router-dom";

const ScrollToTop = (props: any) => {
  const { location, children } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return children;
};

export default withRouter(ScrollToTop);
