import api from "./api";

export const usersPasswordApi = api.injectEndpoints({
  endpoints: (builder) => ({
    reset: builder.mutation({
      query: ({ body }) => ({
        url: "/users/password/reset",
        method: "POST",
        body: { ...body }
      })
    }),
    update: builder.mutation({
      query: ({ body }) => ({
        url: "/users/password",
        method: "PUT",
        body: { ...body }
      })
    })
  })
});

export const { useResetMutation, useUpdateMutation } = usersPasswordApi;
