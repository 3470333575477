const typography = {
  fontFamily: "Roboto, sans-serif",
  fontWeightBold: 500,
  display1: {
    fontFamily: "Ubuntu, sans-serif",
    fontWeight: 500,
    fontSize: "4.5rem",
    lineHeight: "1.25"
  },
  display2: {
    fontFamily: "Ubuntu, sans-serif",
    fontWeight: 500,
    fontSize: "3.75rem",
    lineHeight: "1.25"
  },
  h1: {
    fontFamily: "Ubuntu, sans-serif",
    fontWeight: 500,
    fontSize: "3rem",
    lineHeight: "1.25"
  },
  h2: {
    fontFamily: "Ubuntu, sans-serif",
    fontWeight: 500,
    fontSize: "2.25rem",
    lineHeight: "1.25"
  },
  h3: {
    fontFamily: "Ubuntu, sans-serif",
    fontWeight: 500,
    fontSize: "1.875rem",
    lineHeight: "1.25"
  },
  h4: {
    fontFamily: "Ubuntu, sans-serif",
    fontWeight: 500,
    fontSize: "1.5rem",
    lineHeight: "1.5"
  },
  h5: {
    fontFamily: "Ubuntu, sans-serif",
    fontWeight: 500,
    fontSize: "1.25rem",
    lineHeight: "1.5"
  },
  h6: {
    fontFamily: "Ubuntu, sans-serif",
    fontWeight: 500,
    fontSize: "1.125rem",
    lineHeight: "1.5"
  },
  subtitle1: {
    fontSize: "1rem",
    lineHeight: "1.5"
  },
  subtitle2: {
    fontSize: "0.875rem",
    lineHeight: "1.5"
  },
  body1: {
    fontSize: "1rem",
    lineHeight: "1.5"
  },
  body2: {
    fontSize: "0.875rem",
    lineHeight: "1.5"
  },
  body3: {
    fontSize: "0.75rem",
    lineHeight: "1.5"
  },
  caption: {
    fontSize: "0.75rem",
    lineHeight: "1.5"
  }
};

export default typography;
