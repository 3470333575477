import { useTheme } from "@mui/material/styles";
import { get } from "lodash";

const useColorFromPalette = (colorName: string) => {
  const theme = useTheme();
  const colorValue = get(theme.palette, colorName);

  return colorValue;
};

export default useColorFromPalette;
