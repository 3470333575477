import React from "react";
import ReactDOM from "react-dom";
import Router from "@routes/Router";
import theme from "@shared/themes/base";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider, CssBaseline } from "@mui/material";
import i18next from "@src/locales/i18next";
import { persistor, store } from "@store/store";
import { StyledEngineProvider } from "@mui/material/styles";
import { I18nextProvider } from "react-i18next";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { plPL } from "@mui/x-date-pickers/locales";
import "dayjs/locale/pl";
import "./index.css";

dayjs.locale("pl");

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={i18next.language}
      localeText={
        plPL.components.MuiLocalizationProvider.defaultProps.localeText
      }
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Router />
            </ThemeProvider>
          </StyledEngineProvider>
        </PersistGate>
      </Provider>
    </LocalizationProvider>
  </I18nextProvider>,
  document.getElementById("root")
);
<link rel="canonical" href="https://www.trans.eu/pl/" />;
