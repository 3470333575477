import api from "./api";
import { TGenerateToken, TRefreshToken } from "./authApi.types";

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    generateToken: builder.mutation<TGenerateToken, any>({
      query: ({ body }) => ({
        url: "/auth/token",
        method: "POST",
        params: {
          include: "company"
        },
        body: { ...body }
      })
    }),
    refreshToken: builder.mutation<TRefreshToken, any>({
      query: ({ body }) => ({
        url: "/auth/refresh_token",
        method: "POST",
        params: {
          include: "company"
        },
        body: { ...body }
      })
    })
  })
});

export const { useGenerateTokenMutation, useRefreshTokenMutation } = authApi;
