import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import qs from "qs";

const useQueryParams = <T>(): T => {
  const { search } = useLocation();

  const queryParams = useMemo(
    () => qs.parse(search, { ignoreQueryPrefix: true }) as unknown as T,
    [search]
  );

  return queryParams;
};

export default useQueryParams;
