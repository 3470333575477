import React from "react";
import EmailError from "@assets/images/icons/email_error.svg";
import SVG from "react-inlinesvg";

const EmailErrorIcon: React.FC<any> = (props) => {
  return (
    <SVG
      src={EmailError}
      width="50px"
      height="50px"
      color="#1ea1f2"
      {...props}
    />
  );
};

export default EmailErrorIcon;
