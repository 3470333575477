import * as React from "react";
import { Box, Grid } from "@mui/material";
import LoadingButton from "@shared/ui/atoms/LoadingButton/LoadingButton";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { useUpdateMutation } from "@api/TranstubeCore/usersPasswordApi";
import routes from "@routes/routes";
import PasswordTextField from "@shared/ui/atoms/PasswordTextField";
import Stack from "@mui/material/Stack";
import Link from "@shared/ui/atoms/Link";
import ContainerTemplate from "@shared/ui/templates/ContainerTemplate";
import Typography from "@shared/ui/atoms/Typography";
import LockIcon from "@shared/ui/atoms/icons/LockIcon";
import Panel from "@shared/ui/molecules/Panel/Panel";
import handleFormError from "@shared/utils/handleFormError";
import { Trans, useTranslation } from "react-i18next";
import useQueryParams from "@shared/hooks/useQueryParams";
import useStyles from "./ChangePassword.styles";
import validationSchema from "./ChangePassword.validationSchema";

const ChangePassword: React.FC<any> = () => {
  const { classes } = useStyles();
  const { token } = useQueryParams<any>();
  const history = useHistory();
  const [UpdatePassword] = useUpdateMutation();
  const { i18n } = useTranslation();

  const successPage = () => {
    return history.replace(routes.root.resetPasswordCompleted());
  };

  const mainPage = () => {
    return history.push(routes.root());
  };

  const handleSubmit = async (values: any, actions: any) => {
    const updatePasswordParams = {
      body: {
        data: {
          attributes: {
            token,
            password: values.password
          }
        }
      }
    };

    await UpdatePassword(updatePasswordParams)
      .unwrap()
      .then(() => {
        actions.resetForm();
        successPage();
      })
      .catch((e: any) => {
        const pointers = {
          "/data": {
            field: "base",
            i18n: "base"
          },
          "/data/attributes/password": {
            field: "password",
            i18n: "user.password"
          }
        };

        e.data?.errors?.forEach((error: any) => {
          if (error.source.pointer === "/data/attributes/token") {
            history.push(routes.root());
          }

          handleFormError(pointers, error, i18n, actions);
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      passwordConfirmation: "",
      base: ""
    },
    validationSchema: validationSchema(i18n),
    onSubmit: handleSubmit
  });

  return (
    <ContainerTemplate
      seoProps={{
        title: "Zmień hasło"
      }}
    >
      <div className={classes.root}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: "65vh" }}
        >
          <Grid item xs={12} sm={12} md={5}>
            <Panel p={8}>
              <Box pt={2} textAlign="center">
                <LockIcon />
              </Box>
              <Box pt={2} pb={4} textAlign="center">
                <Typography variant="h5" component="h1">
                  <Trans i18nKey="order_page.change_password.change_password" />
                </Typography>
              </Box>
              <form onSubmit={formik.handleSubmit}>
                <Stack spacing={2}>
                  <Box>
                    <Stack spacing={1}>
                      <Typography variant="body2" color="text.turquoise.dark">
                        <strong>Hasło</strong>
                      </Typography>
                      <PasswordTextField
                        name="password"
                        fullWidth
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.password &&
                          Boolean(formik.errors.password)
                        }
                        helperText={
                          formik.touched.password && formik.errors.password
                        }
                        placeholder="Wpisz hasło"
                      />
                    </Stack>
                  </Box>
                  <Box>
                    <Stack spacing={1}>
                      <Typography variant="body2" color="text.turquoise.dark">
                        <strong>Powtórz hasło</strong>
                      </Typography>
                      <PasswordTextField
                        fullWidth
                        name="passwordConfirmation"
                        value={formik.values.passwordConfirmation}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.passwordConfirmation &&
                          Boolean(formik.errors.passwordConfirmation)
                        }
                        helperText={
                          formik.touched.passwordConfirmation &&
                          formik.errors.passwordConfirmation
                        }
                        placeholder="Powtórz hasło"
                      />
                    </Stack>
                  </Box>
                  <Box pb={4}>
                    <LoadingButton
                      color="primary"
                      variant="contained"
                      fullWidth
                      type="submit"
                      loading={Boolean(formik.isSubmitting)}
                    >
                      <Trans i18nKey="buttons.save_changes" />
                    </LoadingButton>
                  </Box>
                </Stack>
              </form>
              <Stack spacing={1} direction="row" justifyContent="center" pb={2}>
                <Typography variant="body2">Chcesz się cofnąć?</Typography>
                <Link variant="body2" onClick={mainPage}>
                  Strona główna
                </Link>
              </Stack>
            </Panel>
          </Grid>
        </Grid>
      </div>
    </ContainerTemplate>
  );
};

export default ChangePassword;
