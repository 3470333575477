const calculateDistanceKm = (distance: any) => {
  if (distance === null) return "-----";

  const meter = parseFloat(distance);
  const km = Math.round(meter / 100) / 10;

  return `${km} km`;
};

export default calculateDistanceKm;
