import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  fontColor: {
    "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
      color: theme.palette.text.primary
    }
  }
}));

export default useStyles;
