import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

const useSectionStyles = makeStyles()((theme: Theme) => ({
  fabButton: {
    "&.MuiButtonBase-root": {
      background: "#ffffff",
      boxShadow: "none",
      width: 35,
      height: 35,
      "&:hover": {
        background: "#fafafa"
      }
    }
  },
  svgNavigation: {
    "&.MuiSvgIcon-root": {
      color: theme.palette.primary.main,
      transform: "rotate(90deg)",
      fontSize: 17,
      "&:hover": {
        color: theme.palette.primary.main
      }
    }
  },
  input: {
    "&.MuiTextField-root": {
      border: "none",
      color: "#dadada",
      "& .MuiFormHelperText-root": {
        borderRadius: "5px"
      }
    }
  },
  textFieldInput: {
    background: "#ffffff",
    paddingLeft: theme.spacing(3)
  }
}));

export default useSectionStyles;
