import React from "react";
import MUILoadingButton from "@mui/lab/LoadingButton";

const LoadingButton: React.FC<any> = (props) => {
  const { children, loading, onClick, ...buttonProps } = props;

  return (
    <MUILoadingButton
      color="primary"
      variant="contained"
      size="medium"
      type="submit"
      loading={loading}
      onClick={onClick}
      {...buttonProps}
    >
      {children}
    </MUILoadingButton>
  );
};

export default LoadingButton;
