import React from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import HelpTemplate from "@shared/ui/templates/HelpTemplate";
import Typography from "@shared/ui/atoms/Typography";
import Panel from "@shared/ui/molecules/Panel/Panel";
import ContactMailLink from "@shared/ui/molecules/ContactMailLink";
import { useTranslation } from "react-i18next";

const ForgotMyPassword = () => {
  const { i18n } = useTranslation();

  return (
    <HelpTemplate
      seoProps={{
        title: i18n.t("pages.forgotMyPassword.head.title")
      }}
    >
      <Grid item xs={12} sm={12} md={8} mt={3}>
        <Panel>
          <Stack spacing={2}>
            <Typography variant="h5" component="h1">
              Zapomniałem hasła
            </Typography>

            <Box pt={1}>
              <Stack spacing={2}>
                <Typography>
                  Kliknij na stronie głównej przycisk{" "}
                  <Box display="inline" sx={{ color: "#1E99C0" }}>
                    <strong>Zaloguj się</strong>{" "}
                  </Box>{" "}
                  , a następnie wybierz{" "}
                  <Box display="inline" sx={{ color: "#1E99C0" }}>
                    <strong>Nie pamiętam hasła</strong>{" "}
                  </Box>{" "}
                  . Podaj e-mail, który był wpisany podczas rejestracji w
                  formularzu. Nowe hasło zostanie wysłane na ten adres e-mail.
                </Typography>
                <Box display="flex">
                  <Typography sx={{ pr: "5px" }}>
                    Jeżeli w dalszym ciągu masz problem z odzyskaniem hasła,
                    napisz do nas
                  </Typography>
                  <ContactMailLink />
                </Box>
              </Stack>
            </Box>
          </Stack>
        </Panel>
      </Grid>
    </HelpTemplate>
  );
};

export default ForgotMyPassword;
