import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import OfferPage from "@pages/offers/[id]";
import OrderPage from "@pages/transport-orders/[id]";
import ChangePassword from "@pages/account/reset-password/[token]";
import SuccessPage from "@pages/reset-password-completed";
import CompleteOrder from "@pages/transport-order-created";
import ReviewCreated from "@pages/review-created/ReviewCreated";
import CompletePayment from "@pages/checkout-completed";
import Home from "@pages/index";
import Category from "@pages/transport-orders/new/category";
import Schedule from "@pages/transport-orders/new/schedule";
import Details from "@pages/transport-orders/new/details";
import Summary from "@pages/transport-orders/new/summary";
import List from "@pages/transport-orders";
import HelpTemplate from "@shared/ui/templates/HelpTemplate";
import WhatAreTheBenefitsOfTransking from "@pages/help/what-are-the-benefits-of-transking";
import HowToCreateAnAccount from "@pages/help/how-to-create-an-account";
import ForgotMyPassword from "@pages/help/forgot-my-password";
import HowToCreateAnOrder from "@pages/help/how-to-create-an-order";
import HowToSearchForOrders from "@pages/help/how-to-search-for-orders";
import HowTranskingWorks from "@pages/help/how-transking-works";
import HowCanIPayForTheOffer from "@pages/help/how-can-i-pay-for-the-offer";
import WhereCanISeeOffersFromCarriers from "@pages/help/where-can-i-see-offers-from-carriers";
import HowCanIMakeAnOfferAsACarrier from "@pages/help/how-can-i-make-an-offer-as-a-carrier";
import HowCanIContactYouRegardingTheOffer from "@pages/help/how-can-i-contact-you-regarding-the-offer";
import CanIExchangeMyContactDetails from "@pages/help/can-i-exchange-my-contact-details";
import DoIHaveToChooseTheBestOffer from "@pages/help/do-i-have-to-choose-the-best-offer";
import WhatHappensAfterTheOfferIsPaidFor from "@pages/help/what-happens-after-the-offer-is-paid-for";
import HowToGiveAnOpinionToTheCarrier from "@pages/help/how-to-give-an-opinion-to-the-carrier";
import WhereCanIViewAllMyIssuedOrders from "@pages/help/where-can-i-view-all-my-issued-orders";
import AccountInformation from "@pages/account";
import AccountSessions from "@pages/account/sessions";
import Review from "@pages/reviews/new/[token]";
import UserProfileDetails from "@pages/users/[id]";
import Messages from "@pages/transport-orders/[id]/messages";
import Offers from "@pages/transport-orders/[id]/offers";
import Contact from "@pages/contact";
import ConfirmEmail from "@pages/account/confirm-email/[token]";
import EmailConfirmed from "@pages/email-confirmed";
import PurchaseOrders from "@pages/account/purchase-orders/PurchaseOrders";
import PurchaseOrder from "@pages/account/purchase-orders/[id]/PurchaseOrder";
import OffersAccount from "@pages/account/offers/Offers";
import TransportOrders from "@pages/account/transport-orders/TransportOrders";
import Offer from "@pages/account/offers/[id]";
import NoMatch from "@pages/noMatch/NoMatch";
import TermsOfService from "@pages/TermsOfService/TermsOfService";
import PrivacyPolicy from "@pages/privacy-policy/PrivacyPolicy";
import CookiePolicy from "@pages/cookie-policy/CookiePolicy";
import Invoices from "@pages/account/invoices/Invoices";
import ServiceRates from "@pages/service-rates/ServiceRates";
import routes from "./routes";
import PrivateRoute from "./PrivateRoute";
import Localize from "./localize";
import ScrollToTop from "./ScrollToTop";

const Router: React.FC = () => (
  <BrowserRouter>
    <Localize>
      <ScrollToTop>
        <Switch>
          <Route component={Home} exact strict path={routes.root.pattern} />
          <Route
            component={HelpTemplate}
            exact
            strict
            path={routes.root.help.pattern}
          />
          <Route
            component={Contact}
            exact
            strict
            path={routes.root.contact.pattern}
          />
          <Route
            component={TermsOfService}
            exact
            strict
            path={routes.root.termsOfService.pattern}
          />
          <Route
            component={PrivacyPolicy}
            exact
            strict
            path={routes.root.privacyPolicy.pattern}
          />
          <Route
            component={CookiePolicy}
            exact
            strict
            path={routes.root.cookiePolicy.pattern}
          />
          <Route
            component={ServiceRates}
            exact
            strict
            path={routes.root.serviceRates.pattern}
          />
          <Route
            component={Category}
            exact
            strict
            path={routes.root.transportOrders.new.category.pattern}
          />
          <Route
            component={Schedule}
            exact
            strict
            path={routes.root.transportOrders.new.schedule.pattern}
          />
          <Route
            component={Details}
            exact
            strict
            path={routes.root.transportOrders.new.details.pattern}
          />
          <Route
            component={Summary}
            exact
            strict
            path={routes.root.transportOrders.new.summary.pattern}
          />
          <Route
            component={List}
            exact
            strict
            path={routes.root.transportOrders.pattern}
          />
          <Route
            component={OrderPage}
            exact
            strict
            path={routes.root.transportOrders.transportOrder.pattern}
          />
          <Route
            component={OfferPage}
            exact
            strict
            path={routes.root.offers.offer.pattern}
          />
          <Route
            component={ChangePassword}
            exact
            strict
            path={routes.root.account.resetPassword.pattern}
          />
          <Route
            component={ConfirmEmail}
            exact
            strict
            path={routes.root.account.confirmEmail.pattern}
          />
          <Route
            component={EmailConfirmed}
            exact
            strict
            path={routes.root.emailConfirmed.pattern}
          />
          <Route
            component={SuccessPage}
            exact
            strict
            path={routes.root.resetPasswordCompleted.pattern}
          />
          <Route
            component={CompleteOrder}
            exact
            strict
            path={routes.root.transportOrderCreated.pattern}
          />
          <Route
            component={ReviewCreated}
            exact
            strict
            path={routes.root.reviewCreated.pattern}
          />
          <Route
            component={CompletePayment}
            exact
            strict
            path={routes.root.checkoutCompleted.pattern}
          />
          <Route
            component={Review}
            exact
            strict
            path={routes.root.reviews.new.pattern}
          />
          <Route
            component={Messages}
            exact
            strict
            path={routes.root.transportOrders.transportOrder.messages.pattern}
          />
          <Route
            component={Offers}
            exact
            strict
            path={routes.root.transportOrders.transportOrder.offers.pattern}
          />
          <Route
            component={UserProfileDetails}
            exact
            strict
            path={routes.root.users.user.pattern}
          />
          <Route
            component={WhatAreTheBenefitsOfTransking}
            exact
            strict
            path={routes.root.help.whatAreTheBenefitsOfTransking.pattern}
          />
          <Route
            component={HowToCreateAnAccount}
            exact
            strict
            path={routes.root.help.howToCreateAccount.pattern}
          />
          <Route
            component={ForgotMyPassword}
            exact
            strict
            path={routes.root.help.forgotMyPassword.pattern}
          />
          <Route
            component={HowToCreateAnOrder}
            exact
            strict
            path={routes.root.help.howToCreateAnOrder.pattern}
          />
          <Route
            component={HowToSearchForOrders}
            exact
            strict
            path={routes.root.help.howToSearchForOrders.pattern}
          />
          <Route
            component={HowTranskingWorks}
            exact
            strict
            path={routes.root.help.howTranskingWorks.pattern}
          />
          <Route
            component={HowCanIPayForTheOffer}
            exact
            strict
            path={routes.root.help.howCanIPayForTheOffer.pattern}
          />
          <Route
            component={WhereCanISeeOffersFromCarriers}
            exact
            strict
            path={routes.root.help.whereCanISeeOffersFromCarriers.pattern}
          />
          <Route
            component={HowCanIMakeAnOfferAsACarrier}
            exact
            strict
            path={routes.root.help.howCanIMakeAnOfferAsACarriers.pattern}
          />
          <Route
            component={HowCanIContactYouRegardingTheOffer}
            exact
            strict
            path={routes.root.help.howCanIContactYouRegardingTheOffer.pattern}
          />
          <Route
            component={CanIExchangeMyContactDetails}
            exact
            strict
            path={routes.root.help.canIExchangeMyContactDetails.pattern}
          />
          <Route
            component={DoIHaveToChooseTheBestOffer}
            exact
            strict
            path={routes.root.help.doIHaveToChooseTheBestOffer.pattern}
          />
          <Route
            component={WhatHappensAfterTheOfferIsPaidFor}
            exact
            strict
            path={routes.root.help.whatHappensAfterTheOfferIsPaidFor.pattern}
          />
          <Route
            component={HowToGiveAnOpinionToTheCarrier}
            exact
            strict
            path={routes.root.help.howToGiveAnOpinionToTheCarrier.pattern}
          />
          <Route
            component={WhereCanIViewAllMyIssuedOrders}
            exact
            strict
            path={routes.root.help.whereCanIViewAllMyIssuedOrders.pattern}
          />
          <PrivateRoute
            component={AccountInformation}
            exact
            strict
            path={routes.root.account.pattern}
          />
          <PrivateRoute
            component={AccountSessions}
            exact
            strict
            path={routes.root.account.sessions.pattern}
          />
          <PrivateRoute
            render={(props: any) => (
              <TransportOrders status="active" {...props} />
            )}
            exact
            strict
            path={routes.root.account.transportOrders.pattern}
          />
          <PrivateRoute
            render={(props: any) => (
              <TransportOrders status="inactive" {...props} />
            )}
            exact
            strict
            path={routes.root.account.transportOrders.inactive.pattern}
          />
          <PrivateRoute
            component={PurchaseOrders}
            exact
            strict
            path={routes.root.account.purchaseOrders.pattern}
          />
          <PrivateRoute
            component={PurchaseOrder}
            exact
            strict
            path={routes.root.account.purchaseOrders.purchaseOrder.pattern}
          />
          <PrivateRoute
            component={Offer}
            exact
            strict
            path={routes.root.account.offers.offer.pattern}
          />
          <PrivateRoute
            component={OffersAccount}
            exact
            strict
            path={routes.root.account.offers.pattern}
          />
          <PrivateRoute
            component={Invoices}
            exact
            strict
            path={routes.root.account.invoices.pattern}
          />
          <Route component={NoMatch} exact path="*" />
        </Switch>
      </ScrollToTop>
    </Localize>
  </BrowserRouter>
);

export default Router;
