import React from "react";
import Typography from "../Typography";

const PanelHeader: React.FC<any> = (props) => {
  const { children, ...rest } = props;

  return (
    <Typography variant="h6" component="h3" pt={1} pb={4} {...rest}>
      {children}
    </Typography>
  );
};

export default PanelHeader;
