import { makeStyles } from "tss-react/mui";

const styles = makeStyles()(() => ({
  logoLink: {
    width: "145px",
    lineHeight: 0
  },
  navLink: {
    textDecoration: "none"
  }
}));

export default styles;
