import { Box, Grid, Stack } from "@mui/material";
import Typography from "@shared/ui/atoms/Typography";
import Panel from "@shared/ui/molecules/Panel";
import ContainerTemplate from "@shared/ui/templates/ContainerTemplate";
import React from "react";
import { useTranslation } from "react-i18next";

const CookiePolicy: React.FC<any> = () => {
  const { i18n } = useTranslation();

  return (
    <ContainerTemplate
      seoProps={{
        title: i18n.t("pages.cookiePolicy.head.title"),
        description: i18n.t("pages.cookiePolicy.head.description"),
        robots: "follow, noindex"
      }}
    >
      <Grid xs={12} sm={12} md={12}>
        <Panel>
          <Box>
            <Typography variant="h5" component="h1">
              Polityka cookies
            </Typography>
          </Box>
          <Stack spacing={0.5}>
            <Box pt={2}>
              <Typography variant="body2">
                <strong>1. Informacje ogólne</strong>
              </Typography>
            </Box>
            <Typography variant="body2">
              <strong>1.1.</strong> Pliki cookies to drobne informacje tekstowe
              zapisywane w Urządzeniu Użytkownika, przeznaczone do korzystania
              ze stron internetowych. Pozwalają na rozpoznanie Urządzenia
              Użytkownika i właściwe wyświetlenie strony internetowej według
              preferencji Użytkownika. Nie zawierają one wirusów ani złośliwego
              oprogramowania.
            </Typography>
            <Typography variant="body2">
              <strong>1.2.</strong> Pliki cookies używane przez Usługodawcę są
              zaszyfrowane w sposób uniemożliwiający dostęp do nich osobom
              nieuprawnionym.
            </Typography>
            <Typography variant="body2">
              <strong>1.3.</strong> Pliki cookies mogą być kontrolowane przez
              Użytkownika za pomocą ustawień używanej przez niego przeglądarki
              internetowej w każdym czasie. Szczegółowe informacje dotyczące
              zmiany ustawień plików cookies dostępne są w ustawieniach
              przeglądarki internetowej.
            </Typography>
            <Typography variant="body2">
              <strong>1.4.</strong> Przy pierwszej wizycie w Serwisie Użytkownik
              jest informowany o używaniu plików cookies.
            </Typography>

            <Typography variant="body2">
              <strong>1.5.</strong> Użytkownik korzystając z ustawień
              przeglądarki ma możliwość usunięcia danych przechowywanych w local
              storage i session storage.
            </Typography>
            <Typography variant="body2">
              <strong>1.6.</strong> Wyrazy występujące w niniejszej polityce
              cookies i rozpoczynające się dużą literą należy rozumieć zgodnie z
              ich definicją zawartą w Regulaminie dostępnym w Serwisie.
            </Typography>
            <Box pt={1}>
              <Typography variant="body2">
                <strong>2. Cel przetwarzania danych</strong>
              </Typography>
            </Box>
            <Typography variant="body2">
              <strong>2.1.</strong> Usługodawca jest uprawniony do przetwarzania
              danych zawartych w plikach cookies w następujących celach:
            </Typography>
            <Typography variant="body2">
              2.1.1. dostosowywania zawartości i funkcjonalności Serwisu do
              indywidualnych preferencji Użytkownika,
            </Typography>
            <Typography variant="body2">
              2.1.2. prowadzenia przez Usługodawcę statystyk dotyczących
              analizowania aktywności Użytkowników.
            </Typography>
            <Box pt={1}>
              <Typography variant="body2">
                <strong>3. Rodzaje plików cookies</strong>
              </Typography>
            </Box>
            <Typography variant="body2">
              <strong>3.1.</strong> Usługodawca wykorzystuje następujące typy
              plików cookies:
            </Typography>
            <Typography variant="body2">
              3.1.1. sesyjne - pozostają do momentu opuszczenia Serwisu lub
              wyłączenia przeglądarki internetowej,
            </Typography>
            <Typography variant="body2">
              3.1.2. stałe - zakończenie sesji danej przeglądarki lub wyłączenie
              Urządzenia nie powoduje ich usunięcia z Urządzenia.
            </Typography>
            <Box pt={1}>
              <Typography variant="body2">
                <strong>4. Inne narzędzia</strong>
              </Typography>
            </Box>
            <Typography variant="body2">
              <strong>4.1.</strong> Usługodawca używa również local storage i
              session storage, czyli pamięci wewnętrznej przeglądarki
              internetowej.
            </Typography>
            <Typography variant="body2">
              <strong>4.2.</strong> Dzięki local storage i session storage
              przeglądarka Użytkownika może w dowolnym momencie odwołać się do
              danych tam przechowywanych.
            </Typography>
          </Stack>
        </Panel>
      </Grid>
    </ContainerTemplate>
  );
};

export default CookiePolicy;
