import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

const useSectionStyles = makeStyles()((theme: Theme) => ({
  paperAutocomplete: {
    "&.MuiPaper-root": {
      padding: theme.spacing(4),
      textAlign: "center"
    }
  },
  imageWidth: {
    width: "170px"
  }
}));

export default useSectionStyles;
