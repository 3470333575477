import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

const useSectionStyles = makeStyles()((theme: Theme) => ({
  box: {
    "&.MuiBox-root": {
      border: `1px solid ${theme.palette.border.turquoise.main}`,
      width: "56px",
      height: "56px"
    }
  }
}));

export default useSectionStyles;
