import * as React from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@shared/ui/atoms/TextField";
import FormGroup from "@mui/material/FormGroup";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { useFormik } from "formik";
import LoadingButton from "@shared/ui/atoms/LoadingButton";
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputAdornment,
  Stack
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import PersonIcon from "@mui/icons-material/Person";
import BaseDialog from "@shared/ui/atoms/Dialog";
import Link from "@shared/ui/atoms/Link";
import Checkbox from "@shared/ui/atoms/Checkbox";
import PasswordTextField from "@shared/ui/atoms/PasswordTextField";
import {
  showLoginDialog,
  hideRegisterDialog,
  selectRegister
} from "@store/dialogSlice";
import { useCreateUserMutation } from "@api/TranstubeCore/usersApi";
import CustomMuiTelInput from "@shared/ui/atoms/CustomMuiTelInput";
import Typography from "@shared/ui/atoms/Typography";
import { Trans, useTranslation } from "react-i18next";
import handleFormError from "@shared/utils/handleFormError";
import Alert from "@shared/ui/atoms/Alert";
import { useGenerateTokenMutation } from "@api/TranstubeCore/authApi";
import { setCredentials } from "@store/authSlice";
import routes from "@routes/routes";
import { Link as RouterLink } from "react-router-dom";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import { useSyncCompanyMutation } from "@api/TranstubeCore/companiesApi";
import debounce from "lodash/debounce";
import { useCallback } from "react";
import validationSchema from "./SignUpDialog.validationSchema";
import useStyles from "./SignUpDialog.styles";

const SignUp: React.FC<any> = () => {
  const { classes } = useStyles();
  const [createUser] = useCreateUserMutation();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const [generateToken] = useGenerateTokenMutation();
  const [syncCompany] = useSyncCompanyMutation();
  const [company, setCompany] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleSubmit = async (values: any, actions: any) => {
    let createUseParams;

    if (values.accountType === "company") {
      createUseParams = {
        body: {
          data: {
            attributes: {
              email: values.email,
              password: values.password,
              name: values.name,
              phone_number: values.phoneNumber,
              company_id: values.company.id
            }
          }
        }
      };
    } else {
      createUseParams = {
        body: {
          data: {
            attributes: {
              email: values.email,
              password: values.password,
              name: values.name,
              phone_number: values.phoneNumber
            }
          }
        }
      };
    }

    const genetateTokenParams = {
      body: {
        data: {
          attributes: {
            email: values.email,
            password: values.password
          }
        }
      }
    };

    const createUserResult = await createUser(createUseParams)
      .unwrap()
      .then((paypload: any) => paypload.data)
      .catch((ex: any) => {
        const pointers = {
          "/data": {
            field: "base",
            i18n: "base"
          },
          "/data/attributes/email": {
            field: "email",
            i18n: "user.email"
          },
          "/data/attributes/password": {
            field: "password",
            i18n: "user.password"
          },
          "/data/attributes/name": {
            field: "name",
            i18n: "user.name"
          },
          "/data/attributes/phone_number": {
            field: "phoneNumber",
            i18n: "user.phoneNumber"
          },
          "/data/attributes/company_id": {
            field: "company.nip",
            i18n: "company.nip.company_id"
          }
        };

        ex.data?.errors?.forEach((error: any) => {
          handleFormError(pointers, error, i18n, actions);
        });

        return null;
      });

    if (!createUserResult) return;

    await generateToken(genetateTokenParams)
      .unwrap()
      .then((payload) => {
        dispatch(
          setCredentials({
            user: payload.data,
            accessToken: payload.meta.access_token,
            refreshToken: payload.meta.refresh_token
          })
        );
      });

    actions.resetForm();
    dispatch(hideRegisterDialog());
  };

  const formik = useFormik({
    initialValues: {
      base: null,
      accountType: "private_person",
      company: {
        id: null,
        nip: ""
      },
      email: "",
      password: "",
      passwordConfirmation: "",
      name: "",
      phoneNumber: "+48",
      acceptTerms: false
    },
    validationSchema: validationSchema(i18n),
    onSubmit: handleSubmit
  });

  const handleOpen = useSelector(selectRegister);

  const closeDialogRegister = () => {
    formik.resetForm();
    dispatch(hideRegisterDialog());
  };

  const openDialogLogin = () => {
    closeDialogRegister();
    dispatch(showLoginDialog());
  };

  const regulations = () => {
    return (
      <>
        Akceptuję postanowienia{" "}
        <Link
          target="_blank"
          variant="body2"
          component={RouterLink}
          color="text.turquoise.main"
          to={routes.root.termsOfService()}
          sx={{ padding: "0 !important", margin: "0 !important" }}
        >
          regulaminu
        </Link>{" "}
        i{" "}
        <Link
          target="_blank"
          variant="body2"
          component={RouterLink}
          color="text.turquoise.main"
          to={routes.root.privacyPolicy()}
          sx={{ padding: "0 !important", margin: "0 !important" }}
        >
          politykę prywatności
        </Link>
        .
      </>
    );
  };

  const fetchCompany = async (nip: string) => {
    setLoading(true);

    const companyParams = {
      body: {
        data: {
          attributes: {
            nip
          }
        }
      }
    };

    const result = await syncCompany(companyParams)
      .unwrap()
      .then((payload) => payload.data)
      .catch(() => null);

    setLoading(false);

    if (result === null) return;

    formik.setFieldValue("company.id", result.id);

    setCompany([
      {
        id: result.id,
        name: result.name,
        streetAddress: result.streetAddress,
        postCode: result.postCode,
        city: result.city
      }
    ]);
  };

  const debounceLoadCompanyData = useCallback(
    debounce((event: any) => {
      const nip = String(event.target.value);

      if (nip.length < 10) return;

      fetchCompany(nip);
    }, 750),
    []
  );

  const displayCompany = () => {
    const number = String(formik.values.company.nip);

    if (company !== null && number.length >= 10 && loading === false) {
      return (
        <>
          {company?.map((item: any) => (
            <Box sx={{ border: "1px solid #96c5e8", p: 2 }} key={item.id}>
              <Typography variant="body2" color="text.grey.main">
                {item.name}
              </Typography>
              <Typography variant="body2" color="text.grey.main">
                {item.streetAddress}
              </Typography>
              <Typography variant="body2" color="text.grey.main">
                {item.postCode} {item.city}
              </Typography>
            </Box>
          ))}
        </>
      );
    }

    return <></>;
  };

  return (
    <BaseDialog handleOpen={handleOpen} handleClose={closeDialogRegister}>
      <DialogTitle sx={{ textAlign: "center" }}>Zarejestruj się</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={2}>
            {formik.errors.base && (
              <Alert severity="error">{formik.errors.base}</Alert>
            )}

            <RadioGroup
              name="accountType"
              value={formik.values.accountType}
              onChange={formik.handleChange}
            >
              <Box display="flex">
                <FormControlLabel
                  value="private_person"
                  control={<Radio />}
                  label="Osoba prywatna"
                />
                <FormControlLabel
                  value="company"
                  control={<Radio />}
                  label="Firma"
                />
              </Box>
            </RadioGroup>

            {formik.values.accountType === "company" && (
              <Stack spacing={1}>
                <Typography variant="body2" color="text.turquoise.dark">
                  <strong>NIP</strong>
                </Typography>

                <TextField
                  type="number"
                  name="company.nip"
                  value={formik.values.company.nip}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue("company", {
                      id: null,
                      nip: event.target.value
                    });

                    setCompany(null);

                    debounceLoadCompanyData(event);
                  }}
                  error={
                    formik.touched.company?.nip &&
                    Boolean(formik.errors.company?.nip)
                  }
                  helperText={
                    formik.touched.company?.nip && formik.errors.company?.nip
                  }
                  placeholder="Wpisz NIP"
                  InputProps={{
                    startAdornment: (
                      <BusinessCenterIcon className={classes.inputIcon} />
                    ),
                    endAdornment: (
                      <>
                        {loading === true ? (
                          <InputAdornment position="end">
                            <CircularProgress
                              key="CircularProgress"
                              color="primary"
                              size="1.75rem"
                              style={{ padding: 6 }}
                            />
                          </InputAdornment>
                        ) : null}{" "}
                      </>
                    )
                  }}
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none"
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield"
                    }
                  }}
                />
                {displayCompany()}
              </Stack>
            )}
            <Stack spacing={1}>
              <Typography variant="body2" color="text.turquoise.dark">
                <strong>Adres e-mail</strong>
              </Typography>

              <TextField
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                placeholder="Wpisz adres e-mail"
                InputProps={{
                  startAdornment: (
                    <MailOutlineIcon className={classes.inputIcon} />
                  )
                }}
                autoComplete="on"
              />
            </Stack>
            <Stack spacing={1}>
              <Typography variant="body2" color="text.turquoise.dark">
                <strong>Hasło</strong>
              </Typography>
              <PasswordTextField
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                placeholder="Wpisz hasło"
              />
            </Stack>
            <Stack spacing={1}>
              <Typography variant="body2" color="text.turquoise.dark">
                <strong>Powtórz hasło</strong>
              </Typography>
              <PasswordTextField
                name="passwordConfirmation"
                value={formik.values.passwordConfirmation}
                onChange={formik.handleChange}
                error={
                  formik.touched.passwordConfirmation &&
                  Boolean(formik.errors.passwordConfirmation)
                }
                helperText={
                  formik.touched.passwordConfirmation &&
                  formik.errors.passwordConfirmation
                }
                placeholder="Powtórz hasło"
              />
            </Stack>
            <Stack spacing={1}>
              <Typography variant="body2" color="text.turquoise.dark">
                <strong>Imię i nazwisko</strong>
              </Typography>
              <TextField
                name="name"
                type="text"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                fullWidth
                placeholder="Wpisz imię i nazwisko"
                InputProps={{
                  startAdornment: <PersonIcon className={classes.inputIcon} />
                }}
              />
            </Stack>
            <Stack spacing={1}>
              <Typography variant="body2" color="text.turquoise.dark">
                <strong>
                  <Trans i18nKey="labels.phoneNumber" />
                </strong>
              </Typography>
              <CustomMuiTelInput
                name="phoneNumber"
                value={formik.values.phoneNumber}
                onChange={(value: any) => {
                  formik.setFieldValue("phoneNumber", value);
                }}
                error={
                  formik.touched.phoneNumber &&
                  Boolean(formik.errors.phoneNumber)
                }
                helperText={
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                }
              />
            </Stack>
            <Stack spacing={1}>
              <FormGroup>
                <FormControl>
                  <Checkbox
                    onChange={formik.handleChange}
                    name="acceptTerms"
                    label={regulations()}
                    checked={formik.values.acceptTerms}
                  />
                  <FormHelperText
                    error={
                      formik.touched.acceptTerms &&
                      Boolean(formik.errors.acceptTerms)
                    }
                  >
                    {formik.touched.acceptTerms && formik.errors.acceptTerms}
                  </FormHelperText>
                </FormControl>
              </FormGroup>
            </Stack>

            <LoadingButton loading={Boolean(formik.isSubmitting)}>
              <Trans i18nKey="buttons.register" />
            </LoadingButton>
          </Stack>
        </form>
      </DialogContent>

      <DialogContent>
        <Stack spacing={1} direction="row" justifyContent="center">
          <Typography variant="body2" color="text.grey.main">
            <Trans i18nKey="ui.register.information_text" />
          </Typography>

          <Link variant="body2" onClick={openDialogLogin}>
            <Trans i18nKey="ui.menu.login" />
          </Link>
        </Stack>
      </DialogContent>
    </BaseDialog>
  );
};

export default SignUp;
