import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

const useSectionStyles = makeStyles()((theme: Theme) => ({
  boxAvatar: {
    "&.MuiBox-root": {
      backgroundColor: "white",
      padding: theme.spacing(2),
      justifyContent: "space-between",
      alignItems: "center"
    }
  },
  displayName: {
    "&.MuiTypography-root": {
      fontSize: "16px",
      paddingLeft: theme.spacing(1)
    }
  },
  reviewUser: {
    "&.MuiTypography-root": {
      fontSize: "0.875rem",
      paddingLeft: theme.spacing(0.5)
    }
  },
  starIcon: {
    "&.MuiSvgIcon-root": {
      fontSize: "1rem"
    }
  },
  typographySize: {
    "&.MuiTypography-root": {
      fontSize: "0.875rem"
    }
  },
  flag: {
    border: `1px solid ${theme.palette.border.grey.light}`,
    width: 20
  },
  boxIcon: {
    "&.MuiBox-root": {
      border: `1px solid ${theme.palette.border.turquoise.main}`,
      padding: theme.spacing(0.2),
      marginRight: theme.spacing(1)
    }
  },
  passengerIcon: {
    "&.MuiSvgIcon-root": {
      fontSize: "30px",
      color: "#1E99C0"
    }
  },
  boxSize: {
    "&.MuiBox-root": {
      height: "95px"
    }
  }
}));

export default useSectionStyles;
