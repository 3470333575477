import currencyPrice, { formattedCurrencyPrice } from "./currencyPrice";

const totalCurrencyPrice = (selectedValue: any, offer: any, formik: any) => {
  const commissionCurrencyPrice = currencyPrice(offer.commission.grossPrice);

  if (selectedValue === "addTwoCoffee") {
    return commissionCurrencyPrice.multiply(1.1);
  }

  if (selectedValue === "addThreeCoffee") {
    return commissionCurrencyPrice.multiply(1.25);
  }

  if (selectedValue === "addMoreCoffee") {
    const coffeeCurrencyPrice = currencyPrice(formik.values.coffeePrice);

    return commissionCurrencyPrice.add(coffeeCurrencyPrice);
  }

  return commissionCurrencyPrice;
};

const formattedTotalCurrencyPrice = (
  selectedValue: any,
  offer: any,
  formik: any
) => {
  const value = totalCurrencyPrice(selectedValue, offer, formik);

  return formattedCurrencyPrice(value, offer.commission.currencyIsoCode);
};

export { formattedTotalCurrencyPrice };
export default totalCurrencyPrice;
