import api from "./api";
import { TResources, TSession } from "./types";

export const sessionsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSessions: builder.query<TResources<TSession>, any>({
      query: ({ params }) => ({
        url: "/sessions",
        sort: ["-created_at", "id"].join(","),
        params: {
          ...params
        }
      }),
      providesTags: ["Session"]
    }),
    deleteSessions: builder.mutation({
      query: ({ id }) => ({
        url: `/sessions/${id}`,
        method: "DELETE",
        body: {
          data: {
            attributes: {}
          }
        }
      }),
      invalidatesTags: ["Session"]
    }),
    deleteAllSessions: builder.mutation({
      query: () => ({
        url: "/sessions/destroy_all",
        method: "DELETE",
        body: {}
      }),
      invalidatesTags: ["Session"]
    })
  })
});

export const {
  useGetSessionsQuery,
  useDeleteSessionsMutation,
  useDeleteAllSessionsMutation
} = sessionsApi;
