import React from "react";
import ContainerTemplate from "@shared/ui/templates/ContainerTemplate";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useGetPublicUserQuery } from "@api/TranstubeCore/usersApi";
import { useGetPublicTransportOrderQuery } from "@api/TranstubeCore/transportOrdersApi";
import { useCreateReviewMutation } from "@api/TranstubeCore/reviewsApi";
import { useFormik } from "formik";
import LoadingButton from "@shared/ui/atoms/LoadingButton/LoadingButton";
import CustomRating from "@shared/ui/atoms/CustomRating/CustomRating";
import routes from "@routes/routes";
import UserNameBadge from "@shared/ui/molecules/UserNameBadge/UserNameBadge";
import Typography from "@shared/ui/atoms/Typography";
import Panel from "@shared/ui/molecules/Panel/Panel";
import LinearDeterminate from "@shared/ui/molecules/LinearDeterminate/LinearDeterminate";
import useQueryParams from "@shared/hooks/useQueryParams";
import TextArea from "@shared/ui/atoms/TextArea";
import { useTranslation } from "react-i18next";
import handleFormError from "@shared/utils/handleFormError";
import useStyles from "./Review.styles";
import validationSchema from "./Review.validationSchema";

const Review: React.FC = () => {
  const { classes } = useStyles();
  const history = useHistory();
  const { i18n } = useTranslation();
  const { token } = useQueryParams<any>();

  const decodeToken = () => {
    try {
      const decoded: any = jwt_decode(token);
      const currentTime = Date.now() / 1000;

      if (decoded.exp < currentTime) {
        history.push(routes.root());
      }

      return decoded;
    } catch {
      history.push(routes.root());
    }

    return {};
  };

  const {
    reviewed_user_id: reviewedUserId,
    transport_order_id: transportOrderId
  } = decodeToken();

  const [createReview] = useCreateReviewMutation();

  const { data: userReviewedResult = {} }: any = useGetPublicUserQuery({
    id: reviewedUserId
  });

  const { data: reviewedUser } = userReviewedResult;

  const { data: transportOrderResult = { data: undefined } } =
    useGetPublicTransportOrderQuery({
      id: transportOrderId
    });

  const { data: transportOrder } = transportOrderResult;

  const handleSubmit = async (values: any, actions: any) => {
    const createReviewParams = {
      body: {
        data: {
          attributes: {
            review_token: token,
            content: values.contentReview,
            rating: String(values.rating)
          }
        }
      }
    };

    await createReview(createReviewParams)
      .unwrap()
      .then(() => {
        history.push(routes.root.reviewCreated());
      })
      .catch((e: any) => {
        const pointers = {
          "/data/attributes/content": {
            field: "contentReview",
            i18n: "review"
          }
        };

        e.data?.errors?.forEach((error: any) => {
          if (error.source.pointer === "/data") {
            history.push(routes.root());
          }

          handleFormError(pointers, error, i18n, actions);
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      contentReview: "",
      rating: null
    },
    validationSchema: validationSchema(i18n),
    onSubmit: handleSubmit
  });

  if (!reviewedUser || !transportOrder) return <LinearDeterminate />;

  return (
    <ContainerTemplate
      seoProps={{
        title: i18n.t("pages.review.head.title"),
        robots: "follow, noindex"
      }}
    >
      <Grid container mt={3} mb={3} alignItems="center" justifyContent="center">
        <Grid item xs={12} md={6}>
          <Grid
            item
            xs={12}
            justifyContent="center"
            sx={{ minWidth: "100%", textAlign: "center", pb: 1 }}
          />
          <Panel>
            <Stack spacing={2}>
              <Grid item xs={12} sx={{ pb: 3 }}>
                <Stack spacing={2}>
                  <Typography
                    variant="h5"
                    color="text.turquoise.dark"
                    align="left"
                  >
                    Wystaw opinię
                  </Typography>
                  <Box pt={1}>
                    <Typography variant="body2" color="text.grey.main">
                      Poświęć chwilę na wystawnie opinii użytkownikowi.
                    </Typography>
                  </Box>
                </Stack>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={5} md={5}>
                  <UserNameBadge
                    to={routes.root.users.user({ id: reviewedUser?.id })}
                    color={reviewedUser?.avatarColor}
                    displayName={reviewedUser?.displayName}
                    numberRatings={reviewedUser?.meta?.review_count}
                    averageRating={reviewedUser?.meta?.average_rating}
                  />
                </Grid>

                <Grid
                  item
                  md={7}
                  display={{ xs: "none", sm: "none", md: "block" }}
                >
                  <Box>
                    <Typography
                      variant="body2"
                      color="text.grey.main"
                      align="right"
                    >
                      <strong>Numer zamówienia</strong>
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.grey.main"
                      align="right"
                    >
                      {transportOrder?.number}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  display={{ xs: "block", sm: "block", md: "none" }}
                  pt={2}
                >
                  <Box>
                    <Typography
                      variant="body2"
                      color="text.grey.main"
                      align="left"
                    >
                      Numer zamówienia:
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.grey.main"
                      align="left"
                    >
                      <strong>{transportOrder?.id}</strong>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Divider light />
              <form onSubmit={formik.handleSubmit}>
                <Grid item xs={12}>
                  <Stack spacing={2}>
                    <Box>
                      <Stack spacing={1}>
                        <Box>
                          <Typography
                            variant="body2"
                            color="text.turquoise.dark"
                            align="left"
                          >
                            <strong>Ocena</strong>
                          </Typography>
                        </Box>
                        <Box>
                          <CustomRating
                            name="rating"
                            value={formik.values.rating}
                            onChange={(event: any, newValue: any) => {
                              formik.setFieldValue("rating", newValue);
                            }}
                            error={
                              formik.touched.rating &&
                              Boolean(formik.errors.rating)
                            }
                            helperText={
                              formik.touched.rating && formik.errors.rating
                            }
                          />
                        </Box>
                      </Stack>
                    </Box>
                    <Stack spacing={1}>
                      <Box>
                        <Typography
                          variant="body2"
                          color="text.turquoise.dark"
                          align="left"
                        >
                          <strong>Komentarz</strong>
                        </Typography>
                      </Box>
                      <TextArea
                        name="contentReview"
                        onChange={formik.handleChange}
                        value={formik.values.contentReview}
                        placeholder="Dodaj opinię"
                        error={
                          formik.touched.contentReview &&
                          Boolean(formik.errors.contentReview)
                        }
                        helperText={
                          formik.touched.contentReview &&
                          formik.errors.contentReview
                        }
                      />
                    </Stack>

                    <Box textAlign="right">
                      <LoadingButton
                        color="primary"
                        variant="contained"
                        fullWidth
                        type="submit"
                        className={classes.button}
                        loading={Boolean(formik.isSubmitting)}
                      >
                        Wyślij
                      </LoadingButton>
                    </Box>
                  </Stack>
                </Grid>
              </form>
            </Stack>
          </Panel>
        </Grid>
      </Grid>
    </ContainerTemplate>
  );
};

export default Review;
