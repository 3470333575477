import api from "./api";
import { TInvoice, TResources } from "./types";

export const invoicesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getInvoices: builder.query<TResources<TInvoice>, any>({
      query: ({ params }) => ({
        url: "/invoices",
        params: {
          sort: ["-issued_at", "-created_at", "id"].join(","),
          ...params
        }
      }),
      providesTags: ["Invoice"]
    }),
    downloadInvoice: builder.mutation({
      query: ({ id }) => ({
        url: `/invoices/${id}/download_attachment`,
        method: "POST",
        body: {
          data: {
            attributes: {}
          }
        },
        responseHandler: async (response: any) => {
          if (response.status !== 200) return null;

          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const filename = response.headers.get("X-File-Name");
          const link = document.createElement("a");

          link.href = url;

          link.setAttribute("download", filename);
          link.click();

          return { url, filename };
        },
        cache: "no-cache"
      })
    })
  })
});

export const { useGetInvoicesQuery, useDownloadInvoiceMutation } = invoicesApi;
