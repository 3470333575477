import React from "react";
import { Grid, InputAdornment, Box, Stack } from "@mui/material";
import LoadingButton from "@shared/ui/atoms/LoadingButton/LoadingButton";
import { useFormik } from "formik";
import LocationAutocomplete from "@shared/ui/molecules/LocationAutocomplete";
import DestinationMarkerIcon from "@shared/ui/atoms/icons/DestinationMarkerIcon";
import OriginMarkerIcon from "@shared/ui/atoms/icons/OriginMarkerIcon";
import { useLazyGetLocationQuery } from "@api/TranstubeCore/mapApi";
import Button from "@shared/ui/atoms/Button/Button";
import Popover from "@mui/material/Popover";
import Checkbox from "@shared/ui/atoms/Checkbox/Checkbox";
import CustomTextField from "@shared/ui/atoms/TextField";
import Typography from "@shared/ui/atoms/Typography";
import { Trans, useTranslation } from "react-i18next";
import DatePicker from "@shared/ui/molecules/DatePicker";
import validationSchema from "./Filter.validationSchema";
import { FilterProps } from "./Filter.types";

const Filter: React.FC<FilterProps> = (props) => {
  const { initialValues, handleSubmit } = props;
  const [getLocation] = useLazyGetLocationQuery();
  const { i18n } = useTranslation();

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema: validationSchema()
  });

  const [distanceEl, setDistanceEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const [calendarEl, setCalendarEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const [preferencesEl, setPreferencesEl] =
    React.useState<HTMLButtonElement | null>(null);

  const handleClickDistance = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDistanceEl(event.currentTarget);
  };

  const handleClickCalendar = (event: React.MouseEvent<HTMLButtonElement>) => {
    setCalendarEl(event.currentTarget);
  };

  const handleClickPreferences = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setPreferencesEl(event.currentTarget);
  };

  const handleCloseDistance = () => {
    setDistanceEl(null);
  };

  const handleCloseCalendar = () => {
    setCalendarEl(null);

    const { startOn } = formik.values;
    const { endOn } = formik.values;

    if (startOn !== null) {
      if (endOn === null) {
        formik.setFieldValue("startOn", null);
      } else if (!endOn.isValid()) {
        formik.setFieldValue("startOn", null);
        formik.setFieldValue("endOn", null);
      }
    } else if (endOn !== null) {
      if (startOn === null) {
        formik.setFieldValue("endOn", null);
      } else if (!startOn.isValid()) {
        formik.setFieldValue("startOn", null);
        formik.setFieldValue("endOn", null);
      }
    }
  };

  const handleClosePreferences = () => {
    setPreferencesEl(null);
  };

  const openDistance = Boolean(distanceEl);
  const idDistance = openDistance ? "simple-popover" : undefined;

  const openCalendar = Boolean(calendarEl);
  const idCalendar = openCalendar ? "simple-popover" : undefined;

  const openPreferences = Boolean(preferencesEl);
  const idPreferences = openPreferences ? "simple-popover" : undefined;

  const handleFromLocationAutocompleteChange = async (
    event: any,
    option: any
  ) => {
    await getLocation({ id: option.id })
      .unwrap()
      .then((payload) => {
        formik.setFieldValue("fromLocation", {
          id: option.id,
          name: option.address,
          latitude: payload.data.latitude,
          longitude: payload.data.longitude
        });
      });
  };

  const handleToLocationAutocompleteChange = async (
    event: any,
    option: any
  ) => {
    await getLocation({ id: option.id })
      .unwrap()
      .then((payload) => {
        formik.setFieldValue("toLocation", {
          id: option.id,
          name: option.address,
          latitude: payload.data.latitude,
          longitude: payload.data.longitude
        });
      });
  };

  return (
    <form onSubmit={formik.handleSubmit} autoComplete="off">
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Stack spacing={1}>
            <Typography variant="body2" color="text.turquoise.dark">
              <strong>Odbiór</strong>
            </Typography>
            <LocationAutocomplete
              placeholder={i18n.t("labels.from_location")}
              value={formik.values.fromLocation.name}
              onInputChange={() => formik.setFieldValue("fromLocation", {})}
              onChange={handleFromLocationAutocompleteChange}
              inputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <OriginMarkerIcon />
                  </InputAdornment>
                )
              }}
            />
          </Stack>
        </Grid>

        <Grid item xs={5}>
          <Stack spacing={1}>
            <Typography variant="body2" color="text.turquoise.dark">
              <strong>Dostawa</strong>
            </Typography>
            <LocationAutocomplete
              placeholder={i18n.t("labels.to_location")}
              value={formik.values.toLocation.name}
              onChange={handleToLocationAutocompleteChange}
              onInputChange={() => formik.setFieldValue("toLocation", {})}
              inputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <DestinationMarkerIcon />
                  </InputAdornment>
                )
              }}
            />
          </Stack>
        </Grid>

        <Grid item xs={2}>
          <Stack spacing={1}>
            <Box sx={{ height: "21px" }} />
            <Box>
              <LoadingButton
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                loading={Boolean(formik.isSubmitting)}
              >
                Szukaj
              </LoadingButton>
            </Box>
          </Stack>
        </Grid>

        <Stack direction="row" spacing={2} pl={2} pt={2}>
          <div>
            <Button
              variant="outlined"
              size="small"
              aria-describedby={idDistance}
              onClick={handleClickDistance}
            >
              Odłegłość
            </Button>

            <Popover
              id={idDistance}
              open={openDistance}
              anchorEl={distanceEl}
              onClose={handleCloseDistance}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
              PaperProps={{
                elevation: 0,
                sx: {
                  p: 2,
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    left: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0
                  }
                }
              }}
            >
              <Stack spacing={1}>
                <Typography variant="body2" color="text.turquoise.dark">
                  <strong>
                    <Trans i18nKey="labels.distance" />
                  </strong>
                </Typography>

                <CustomTextField
                  name="distance"
                  value={formik.values.distance}
                  onChange={formik.handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">km</InputAdornment>
                    ),
                    inputMode: "numeric",
                    pattern: "[0-9]*"
                  }}
                  aria-describedby="outlined-weight-helper-text"
                />
              </Stack>
            </Popover>
          </div>
          <div>
            <Button
              variant="outlined"
              size="small"
              aria-describedby={idCalendar}
              onClick={handleClickCalendar}
            >
              Data realizacji
            </Button>

            <Popover
              id={idCalendar}
              open={openCalendar}
              anchorEl={calendarEl}
              onClose={handleCloseCalendar}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
              PaperProps={{
                elevation: 0,
                sx: {
                  p: 2,
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    left: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0
                  }
                }
              }}
            >
              <Box display="flex">
                <Stack direction="row" spacing={1}>
                  <Stack spacing={2}>
                    <Box>
                      <Stack spacing={1}>
                        <Typography variant="body2" color="text.turquoise.dark">
                          <strong>
                            <Trans i18nKey="labels.start_on" />
                          </strong>
                        </Typography>

                        <DatePicker
                          value={formik.values.startOn}
                          error={
                            (formik.touched.startOn &&
                              Boolean(formik.errors.startOn)) ||
                            false
                          }
                          helperText={
                            formik.touched.startOn && formik.errors.startOn
                          }
                          onChange={(value: any, { validationError }: any) => {
                            if (validationError !== "invalidDate") {
                              formik.setFieldValue("startOn", value);

                              if (value !== null && value.isValid()) {
                                if (
                                  formik.values.endOn === null ||
                                  !formik.values.endOn.isValid()
                                ) {
                                  formik.setFieldValue("endOn", value);
                                } else if (formik.values.endOn < value) {
                                  formik.setFieldValue("endOn", value);
                                }
                              }
                            }
                          }}
                        />
                      </Stack>
                    </Box>

                    <Box>
                      <Stack spacing={1}>
                        <Typography variant="body2" color="text.turquoise.dark">
                          <strong>
                            <Trans i18nKey="labels.end_on" />
                          </strong>
                        </Typography>

                        <DatePicker
                          value={formik.values.endOn}
                          onChange={(value: any, { validationError }: any) => {
                            if (validationError !== "invalidDate") {
                              formik.setFieldValue("endOn", value);

                              if (value !== null && value.isValid()) {
                                if (
                                  formik.values.startOn === null ||
                                  !formik.values.startOn.isValid()
                                ) {
                                  formik.setFieldValue("startOn", value);
                                } else if (value < formik.values.startOn) {
                                  formik.setFieldValue("startOn", value);
                                }
                              }
                            }
                          }}
                          error={
                            (formik.touched.endOn &&
                              Boolean(formik.errors.endOn)) ||
                            false
                          }
                          helperText={
                            formik.touched.endOn && formik.errors.endOn
                          }
                        />
                      </Stack>
                    </Box>
                  </Stack>
                </Stack>
              </Box>
            </Popover>
          </div>

          <div>
            <Button
              variant="outlined"
              size="small"
              aria-describedby={idPreferences}
              onClick={handleClickPreferences}
            >
              Preferencje ofert
            </Button>

            <Popover
              id={idPreferences}
              open={openPreferences}
              anchorEl={preferencesEl}
              onClose={handleClosePreferences}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
              PaperProps={{
                elevation: 0,
                sx: {
                  p: 2,
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    left: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0
                  }
                }
              }}
            >
              <Stack direction="column" spacing={1}>
                <Box>
                  <Checkbox
                    name="preferences.privatePerson"
                    checked={formik.values.preferences.privatePerson}
                    onChange={formik.handleChange}
                    label={i18n.t("labels.private_person")}
                  />
                </Box>
                <Box>
                  <Checkbox
                    name="preferences.company"
                    checked={formik.values.preferences.company}
                    onChange={formik.handleChange}
                    label={i18n.t("labels.company")}
                  />
                </Box>
              </Stack>
            </Popover>
          </div>
        </Stack>
      </Grid>
    </form>
  );
};

export default Filter;
