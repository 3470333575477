import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

const useSectionStyles = makeStyles()((theme: Theme) => ({
  input: {
    textarea: {
      resize: "vertical",
      borderColor: theme.palette.border.grey.main,
      width: "100%",
      fontFamily: "Roboto, sans-serif",
      fontSize: "0.875rem"
    }
  },
  button: {
    "&.MuiButton-root": {
      width: "40%"
    }
  }
}));

export default useSectionStyles;
