const calculateDuration = (duration: any) => {
  if (duration === null) return "-----";

  const d = Number(duration);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);

  return `${h} h ${m} m`;
};

export default calculateDuration;
