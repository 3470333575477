import React from "react";
import Box from "@mui/material/Box";
import Typography from "@shared/ui/atoms/Typography";
import { Stack } from "@mui/material";
import TravelerIcon from "@shared/ui/atoms/icons/TravelerIcon";
import useStyles from "./PeopleTransportOrderLIneItem.styles";
import Panel from "../Panel";

const PeopleTransportOrderLIneItem: React.FC<any> = (props) => {
  const { transportOrderLineItem, sx } = props;
  const { classes } = useStyles();

  return (
    <>
      <Panel sx={{ ...sx, display: { xs: "none", sm: "none", md: "block" } }}>
        <Box display="flex">
          <Stack spacing={2} direction="row" alignItems="center">
            <Box
              className={classes.box}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <TravelerIcon />
            </Box>

            <Box display="inline">
              <Stack spacing={1}>
                <Box>
                  <Typography variant="body2" color="text.turquoise.dark">
                    <strong>Ilość</strong>
                  </Typography>
                </Box>
                <Box display="flex">
                  <Typography variant="body2" color="text.grey.main">
                    {transportOrderLineItem?.quantity} os.
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Panel>

      <Panel sx={{ ...sx, display: { xs: "block", sm: "block", md: "none" } }}>
        <Stack spacing={2}>
          <Box
            className={classes.box}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <TravelerIcon className="icon" />
          </Box>

          <Box display="inline">
            <Stack spacing={1}>
              <Typography variant="body2" color="text.turquoise.dark">
                <strong>Ilość</strong>
              </Typography>
              <Typography variant="body2" color="text.grey.main">
                {transportOrderLineItem?.quantity} os.
              </Typography>
            </Stack>
          </Box>
        </Stack>
      </Panel>
    </>
  );
};

export default PeopleTransportOrderLIneItem;
