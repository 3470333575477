import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    height: "100%",
    width: "100%",
    ".maplibregl-map": {
      height: "100%",
      width: "100%",
      font: "0.625rem / 20px Roboto, sans-serif",
      ".maplibregl-ctrl-bottom-right": {
        width: "100%",
        ".maplibregl-ctrl-attrib": {
          width: "100%",
          textAlign: "right",
          backgroundColor: theme.palette.background.paper,
          a: {
            color: theme.palette.text.turquoise.main,
            textDecoration: "underline",
            textDecorationColor: "inherit !important",
            "&:hover": {
              color: theme.palette.text.turquoise.light,
              textDecorationColor: "inherit !important"
            }
          }
        }
      }
    }
  }
}));

export default useStyles;
