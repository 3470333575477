import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import displayNameInAvatar from "@shared/utils/displayNameInAvatar";
import { useHistory } from "react-router-dom";

const UserAvatar: React.FC<any> = (props) => {
  const { size = 48, color, children, to, sx } = props;
  const [colors, setColors] = useState<any>([]);
  const history = useHistory();

  React.useEffect(() => {
    setColors(
      [
        { name: "violet", value: "#8C5AC9" },
        { name: "pink", value: "#E951B5" },
        { name: "purple", value: "#FF6191" },
        { name: "coral", value: "#FF8F69" },
        { name: "orange", value: "#FFC547" },
        { name: "olive", value: "#ACE1AF" },
        { name: "cyan", value: "#30D5C8" },
        { name: "blue", value: "#0083D5" },
        { name: "rust", value: "#D03B29" },
        { name: "lavender", value: "#97B7FF" },
        { name: "tan", value: "#B5876D" },
        { name: "mustard", value: "#FBDC58" }
      ].sort((x) => {
        return x.name === color ? -1 : 0;
      })
    );
  }, [color]);

  const calculateFontSize = () => {
    const baseSize = 16;
    const baseFontSize = 0.3;
    const fontSize = (((size - baseSize) / 8) * baseFontSize).toFixed(1);

    return `${fontSize}rem`;
  };

  const userProfileDetails = () => {
    if (!to) return null;

    return history.push(to);
  };

  return (
    <Avatar
      sx={{
        ...sx,
        bgcolor: colors.find((colorAvatar: any) => color === colorAvatar.name)
          ?.value,
        width: size,
        height: size,
        fontSize: calculateFontSize()
      }}
      variant="square"
      onClick={userProfileDetails}
    >
      {displayNameInAvatar(children)}
    </Avatar>
  );
};

export default UserAvatar;
