import { deserialize } from "deserialize-json-api";

const deserializeApiResponse = async (response: any): Promise<any> => {
  const result = await response;

  if (result?.data) return deserialize(result);

  return result;
};

export default deserializeApiResponse;
