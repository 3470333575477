import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

const useSectionStyles = makeStyles()((theme: Theme) => ({
  flag: {
    border: `1px solid ${theme.palette.border.grey.light}`,
    width: 20
  },
  iconLabel: {
    "&.MuiSvgIcon-root": {
      fontSize: "1.2rem"
    }
  },
  box: {
    "&.MuiBox-root": {
      border: `1px solid ${theme.palette.border.turquoise.main}`,
      padding: theme.spacing(0.5),
      width: "4.8rem",
      height: "3.8rem"
    }
  }
}));

export default useSectionStyles;
