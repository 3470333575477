import React from "react";
import TransportOrderWizardTemplate from "@shared/ui/templates/TransportOrderWizardTemplate";
import { useHistory, useLocation } from "react-router-dom";
import routes from "@routes/routes";
import Grid from "@mui/material/Grid";
import Panel from "@shared/ui/molecules/Panel/Panel";
import PanelHeader from "@shared/ui/atoms/PanelHeader";
import { Box, Button, FormHelperText, Stack } from "@mui/material";
import Typography from "@shared/ui/atoms/Typography";
import TravelerIcon from "@shared/ui/atoms/icons/TravelerIcon";
import OrdersIcon from "@shared/ui/atoms/icons/OrdersIcon";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@shared/ui/atoms/TextField";
import MultiplyIcon from "@shared/ui/atoms/icons/MultiplyIcon";
import ButtonForm from "@shared/ui/molecules/ButtonForm";
import { useFormik, getIn } from "formik";
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";
import validationSchema from "./Category.validationSchema";
import useStyles from "./Category.styles";
import { IFormik, ITransportOrderLineItem } from "./category.types";

const Category: React.FC<any> = () => {
  const history = useHistory();
  const { classes } = useStyles();
  const location = useLocation();
  const { i18n } = useTranslation();
  const routeParams = { ...location.state };

  const handleSubmit = (values: IFormik) => {
    history.replace(routes.root.transportOrders.new.schedule(), values);
  };

  const formik = useFormik<IFormik>({
    initialValues: {
      categories: {
        others: false,
        people: false
      },
      groupedTransportOrderLineItems: [],
      ...routeParams
    },
    validationSchema: validationSchema(i18n),
    onSubmit: handleSubmit
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const transportOrderLineItemsLength =
      formik.values.groupedTransportOrderLineItems.reduce(
        (sum, current) => sum + current.transportOrderLineItems.length,
        0
      );

    if (event.target.checked && transportOrderLineItemsLength >= 10) return;

    formik.handleChange(event);
    formik.setTouched({}, false);

    const groupedTransportOrderLineItems = [
      ...formik.values.groupedTransportOrderLineItems
    ];

    const category =
      event.target.name === "categories.people" ? "people" : "others";

    if (!event.target.checked) {
      formik.setFieldValue(
        "groupedTransportOrderLineItems",
        groupedTransportOrderLineItems.filter((e) => e.category !== category)
      );

      return;
    }

    let groupedTransportOrderLineItem = groupedTransportOrderLineItems.find(
      (e) => e.category === category
    );

    if (groupedTransportOrderLineItem !== undefined) return;

    let transportOrderLineItem = null;

    if (category === "people") {
      transportOrderLineItem = {
        id: new Date().getTime(),
        quantity: "",
        category
      };
    } else {
      transportOrderLineItem = {
        id: new Date().getTime(),
        quantity: "",
        dimensionX: "",
        dimensionY: "",
        dimensionZ: "",
        weight: "",
        category
      };
    }

    groupedTransportOrderLineItem = {
      category,
      transportOrderLineItems: [transportOrderLineItem]
    };

    groupedTransportOrderLineItems.push(groupedTransportOrderLineItem);

    formik.setFieldValue(
      "groupedTransportOrderLineItems",
      groupedTransportOrderLineItems
    );
  };

  const deletePackage = (item: ITransportOrderLineItem) => {
    const groupedTransportOrderLineItems = [
      ...formik.values.groupedTransportOrderLineItems
    ];

    const groupedTransportOrderLineItem = groupedTransportOrderLineItems.find(
      (e) => e.category === item.category
    );

    if (groupedTransportOrderLineItem === undefined) return;

    groupedTransportOrderLineItem.transportOrderLineItems =
      groupedTransportOrderLineItem.transportOrderLineItems.filter(
        (e) => e !== item
      );

    formik.setTouched({}, false);

    formik.setFieldValue(
      "transportOrderLineItems",
      groupedTransportOrderLineItems
    );
  };

  const mainPage = () => {
    history.replace(routes.root());
  };

  const addTransportOrderLineItem = (category: any) => {
    const groupedTransportOrderLineItems = [
      ...formik.values.groupedTransportOrderLineItems
    ];

    const groupedTransportOrderLineItem = groupedTransportOrderLineItems.find(
      (e) => e.category === category
    );

    if (groupedTransportOrderLineItem === undefined) return;

    let transportOrderLineItem = null;

    if (category === "people") {
      transportOrderLineItem = {
        id: new Date().getTime(),
        quantity: "",
        category
      };
    } else {
      transportOrderLineItem = {
        id: new Date().getTime(),
        quantity: "",
        dimensionX: "",
        dimensionY: "",
        dimensionZ: "",
        weight: "",
        category
      };
    }

    groupedTransportOrderLineItem.transportOrderLineItems?.push(
      transportOrderLineItem
    );

    formik.setTouched({}, false);

    formik.setFieldValue(
      "groupedTransportOrderLineItems",
      groupedTransportOrderLineItems
    );
  };

  const openBoxPeople = () => {
    return (
      <>
        {formik.values.groupedTransportOrderLineItems.map(
          ({ category, transportOrderLineItems }, gtoliIndex) =>
            transportOrderLineItems.map((transportOrderLineItem, toliIndex) =>
              transportOrderLineItem.category === "people" ? (
                <React.Fragment
                  key={`transportOrderLineItem-${transportOrderLineItem.id}`}
                >
                  <Panel
                    sx={{
                      backgroundColor: "#ffffff",
                      display: { xs: "none", sm: "none", md: "block" }
                    }}
                  >
                    <Box display="flex">
                      <Box
                        sx={{
                          mt: "35px",
                          width: {
                            md: "5%"
                          }
                        }}
                      >
                        <TravelerIcon />
                      </Box>
                      <Box sx={{ width: { md: "16%" } }}>
                        <Stack spacing={1}>
                          <Typography
                            variant="body2"
                            color="text.turquoise.dark"
                          >
                            <strong>Liczba</strong>
                          </Typography>
                          <TextField
                            name={`groupedTransportOrderLineItems.${gtoliIndex}.transportOrderLineItems.${toliIndex}.quantity`}
                            value={transportOrderLineItem.quantity}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.groupedTransportOrderLineItems?.[
                                gtoliIndex
                              ]?.transportOrderLineItems?.[toliIndex]
                                ?.quantity &&
                              Boolean(
                                getIn(
                                  getIn(
                                    formik.errors
                                      .groupedTransportOrderLineItems?.[
                                      gtoliIndex
                                    ],
                                    "transportOrderLineItems"
                                  )?.[toliIndex],
                                  "quantity"
                                )
                              )
                            }
                            helperText={
                              formik.touched.groupedTransportOrderLineItems?.[
                                gtoliIndex
                              ]?.transportOrderLineItems?.[toliIndex]
                                ?.quantity &&
                              getIn(
                                getIn(
                                  formik.errors
                                    .groupedTransportOrderLineItems?.[
                                    gtoliIndex
                                  ],
                                  "transportOrderLineItems"
                                )?.[toliIndex],
                                "quantity"
                              )
                            }
                            InputProps={{
                              endAdornment: (
                                <Typography
                                  variant="body2"
                                  color="text.turquoise.grey"
                                >
                                  os.
                                </Typography>
                              )
                            }}
                          />
                        </Stack>
                      </Box>
                    </Box>
                  </Panel>

                  <Panel
                    sx={{
                      backgroundColor: "#ffffff",
                      display: { xs: "block", sm: "block", md: "none" }
                    }}
                  >
                    <Stack spacing={2}>
                      <Box
                        sx={{
                          width: {
                            display: "flex-start",
                            alignItems: "center"
                          }
                        }}
                      >
                        <TravelerIcon />
                      </Box>
                      <Box display="flex">
                        <Box sx={{ width: { xs: "100%", sm: "100%" } }}>
                          <Stack spacing={1}>
                            <Typography
                              variant="body2"
                              color="text.turquoise.dark"
                            >
                              <strong>Liczba</strong>
                            </Typography>
                            <TextField
                              name={`groupedTransportOrderLineItems.${gtoliIndex}.transportOrderLineItems.${toliIndex}.quantity`}
                              value={transportOrderLineItem.quantity}
                              onChange={formik.handleChange}
                              error={
                                formik.touched.groupedTransportOrderLineItems?.[
                                  gtoliIndex
                                ]?.transportOrderLineItems?.[toliIndex]
                                  ?.quantity &&
                                Boolean(
                                  getIn(
                                    getIn(
                                      formik.errors
                                        .groupedTransportOrderLineItems?.[
                                        gtoliIndex
                                      ],
                                      "transportOrderLineItems"
                                    )?.[toliIndex],
                                    "quantity"
                                  )
                                )
                              }
                              helperText={
                                formik.touched.groupedTransportOrderLineItems?.[
                                  gtoliIndex
                                ]?.transportOrderLineItems?.[toliIndex]
                                  ?.quantity &&
                                getIn(
                                  getIn(
                                    formik.errors
                                      .groupedTransportOrderLineItems?.[
                                      gtoliIndex
                                    ],
                                    "transportOrderLineItems"
                                  )?.[toliIndex],
                                  "quantity"
                                )
                              }
                              InputProps={{
                                endAdornment: (
                                  <Typography
                                    variant="body2"
                                    color="text.turquoise.grey"
                                  >
                                    os.
                                  </Typography>
                                )
                              }}
                            />
                          </Stack>
                        </Box>
                      </Box>
                    </Stack>
                  </Panel>
                </React.Fragment>
              ) : (
                <React.Fragment
                  key={`transportOrderLineItem-${transportOrderLineItem.id}`}
                >
                  <Panel
                    sx={{
                      backgroundColor: "#ffffff",
                      display: { xs: "none", sm: "none", md: "block" }
                    }}
                  >
                    <Stack spacing={2}>
                      <Box
                        sx={{
                          display: { xs: "none", sm: "none", md: "flex" }
                        }}
                      >
                        <Box
                          sx={{
                            mt: "35px",
                            width: {
                              md: "5%"
                            }
                          }}
                        >
                          <OrdersIcon />
                        </Box>
                        <Box sx={{ width: { md: "16%" } }}>
                          <Stack spacing={1}>
                            <Typography
                              variant="body2"
                              color="text.turquoise.dark"
                            >
                              <strong>Wymiary</strong>
                            </Typography>
                            <TextField
                              name={`groupedTransportOrderLineItems.${gtoliIndex}.transportOrderLineItems.${toliIndex}.dimensionX`}
                              value={transportOrderLineItem.dimensionX}
                              onChange={formik.handleChange}
                              error={
                                formik.touched.groupedTransportOrderLineItems?.[
                                  gtoliIndex
                                ]?.transportOrderLineItems?.[toliIndex]
                                  ?.dimensionX &&
                                Boolean(
                                  getIn(
                                    getIn(
                                      formik.errors
                                        .groupedTransportOrderLineItems?.[
                                        gtoliIndex
                                      ],
                                      "transportOrderLineItems"
                                    )?.[toliIndex],
                                    "dimensionX"
                                  )
                                )
                              }
                              helperText={
                                formik.touched.groupedTransportOrderLineItems?.[
                                  gtoliIndex
                                ]?.transportOrderLineItems?.[toliIndex]
                                  ?.dimensionX &&
                                getIn(
                                  getIn(
                                    formik.errors
                                      .groupedTransportOrderLineItems?.[
                                      gtoliIndex
                                    ],
                                    "transportOrderLineItems"
                                  )?.[toliIndex],
                                  "dimensionX"
                                )
                              }
                              InputProps={{
                                endAdornment: (
                                  <Typography
                                    variant="body2"
                                    color="text.turquoise.grey"
                                  >
                                    cm
                                  </Typography>
                                )
                              }}
                            />
                          </Stack>
                        </Box>
                        <Box
                          sx={{
                            mt: "35.5px",
                            textAlign: "center",
                            width: {
                              md: "3%"
                            }
                          }}
                        >
                          <MultiplyIcon />
                        </Box>

                        <Box sx={{ width: { md: "16%" } }}>
                          <Stack spacing={1}>
                            <Box sx={{ height: "21px" }} />
                            <TextField
                              name={`groupedTransportOrderLineItems.${gtoliIndex}.transportOrderLineItems.${toliIndex}.dimensionY`}
                              value={transportOrderLineItem.dimensionY}
                              onChange={formik.handleChange}
                              error={
                                formik.touched.groupedTransportOrderLineItems?.[
                                  gtoliIndex
                                ]?.transportOrderLineItems?.[toliIndex]
                                  ?.dimensionY &&
                                Boolean(
                                  getIn(
                                    getIn(
                                      formik.errors
                                        .groupedTransportOrderLineItems?.[
                                        gtoliIndex
                                      ],
                                      "transportOrderLineItems"
                                    )?.[toliIndex],
                                    "dimensionY"
                                  )
                                )
                              }
                              helperText={
                                formik.touched.groupedTransportOrderLineItems?.[
                                  gtoliIndex
                                ]?.transportOrderLineItems?.[toliIndex]
                                  ?.dimensionY &&
                                getIn(
                                  getIn(
                                    formik.errors
                                      .groupedTransportOrderLineItems?.[
                                      gtoliIndex
                                    ],
                                    "transportOrderLineItems"
                                  )?.[toliIndex],
                                  "dimensionY"
                                )
                              }
                              InputProps={{
                                endAdornment: (
                                  <Typography
                                    variant="body2"
                                    color="text.turquoise.grey"
                                  >
                                    cm
                                  </Typography>
                                )
                              }}
                            />
                          </Stack>
                        </Box>
                        <Box
                          sx={{
                            mt: "35.5px",
                            textAlign: "center",
                            width: {
                              md: "3%"
                            }
                          }}
                        >
                          <MultiplyIcon />
                        </Box>

                        <Box sx={{ width: { md: "16%" } }}>
                          <Stack spacing={1}>
                            <Box sx={{ height: "21px" }} />
                            <TextField
                              name={`groupedTransportOrderLineItems.${gtoliIndex}.transportOrderLineItems.${toliIndex}.dimensionZ`}
                              value={transportOrderLineItem.dimensionZ}
                              onChange={formik.handleChange}
                              error={
                                formik.touched.groupedTransportOrderLineItems?.[
                                  gtoliIndex
                                ]?.transportOrderLineItems?.[toliIndex]
                                  ?.dimensionZ &&
                                Boolean(
                                  getIn(
                                    getIn(
                                      formik.errors
                                        .groupedTransportOrderLineItems?.[
                                        gtoliIndex
                                      ],
                                      "transportOrderLineItems"
                                    )?.[toliIndex],
                                    "dimensionZ"
                                  )
                                )
                              }
                              helperText={
                                formik.touched.groupedTransportOrderLineItems?.[
                                  gtoliIndex
                                ]?.transportOrderLineItems?.[toliIndex]
                                  ?.dimensionZ &&
                                getIn(
                                  getIn(
                                    formik.errors
                                      .groupedTransportOrderLineItems?.[
                                      gtoliIndex
                                    ],
                                    "transportOrderLineItems"
                                  )?.[toliIndex],
                                  "dimensionZ"
                                )
                              }
                              InputProps={{
                                endAdornment: (
                                  <Typography
                                    variant="body2"
                                    color="text.turquoise.grey"
                                  >
                                    cm
                                  </Typography>
                                )
                              }}
                            />
                          </Stack>
                        </Box>
                        <Box sx={{ width: { md: "3%" } }} alignSelf="center" />
                        <Box sx={{ width: { md: "16%" } }}>
                          <Stack spacing={1}>
                            <Typography
                              variant="body2"
                              color="text.turquoise.dark"
                            >
                              <strong>Waga (1 szt.)</strong>
                            </Typography>
                            <TextField
                              name={`groupedTransportOrderLineItems.${gtoliIndex}.transportOrderLineItems.${toliIndex}.weight`}
                              value={transportOrderLineItem.weight}
                              onChange={formik.handleChange}
                              error={
                                formik.touched.groupedTransportOrderLineItems?.[
                                  gtoliIndex
                                ]?.transportOrderLineItems?.[toliIndex]
                                  ?.weight &&
                                Boolean(
                                  getIn(
                                    getIn(
                                      formik.errors
                                        .groupedTransportOrderLineItems?.[
                                        gtoliIndex
                                      ],
                                      "transportOrderLineItems"
                                    )?.[toliIndex],
                                    "weight"
                                  )
                                )
                              }
                              helperText={
                                formik.touched.groupedTransportOrderLineItems?.[
                                  gtoliIndex
                                ]?.transportOrderLineItems?.[toliIndex]
                                  ?.weight &&
                                getIn(
                                  getIn(
                                    formik.errors
                                      .groupedTransportOrderLineItems?.[
                                      gtoliIndex
                                    ],
                                    "transportOrderLineItems"
                                  )?.[toliIndex],
                                  "weight"
                                )
                              }
                              InputProps={{
                                endAdornment: (
                                  <Typography
                                    variant="body2"
                                    color="text.turquoise.grey"
                                  >
                                    kg
                                  </Typography>
                                )
                              }}
                            />
                          </Stack>
                        </Box>
                        <Box sx={{ width: { md: "3%" } }} alignSelf="center" />
                        <Box sx={{ width: { md: "16%" } }}>
                          <Stack spacing={1}>
                            <Typography
                              variant="body2"
                              color="text.turquoise.dark"
                            >
                              <strong>Liczba</strong>
                            </Typography>
                            <TextField
                              name={`groupedTransportOrderLineItems.${gtoliIndex}.transportOrderLineItems.${toliIndex}.quantity`}
                              value={transportOrderLineItem.quantity}
                              onChange={formik.handleChange}
                              error={
                                formik.touched.groupedTransportOrderLineItems?.[
                                  gtoliIndex
                                ]?.transportOrderLineItems?.[toliIndex]
                                  ?.quantity &&
                                Boolean(
                                  getIn(
                                    getIn(
                                      formik.errors
                                        .groupedTransportOrderLineItems?.[
                                        gtoliIndex
                                      ],
                                      "transportOrderLineItems"
                                    )?.[toliIndex],
                                    "quantity"
                                  )
                                )
                              }
                              helperText={
                                formik.touched.groupedTransportOrderLineItems?.[
                                  gtoliIndex
                                ]?.transportOrderLineItems?.[toliIndex]
                                  ?.quantity &&
                                getIn(
                                  getIn(
                                    formik.errors
                                      .groupedTransportOrderLineItems?.[
                                      gtoliIndex
                                    ],
                                    "transportOrderLineItems"
                                  )?.[toliIndex],
                                  "quantity"
                                )
                              }
                              InputProps={{
                                endAdornment: (
                                  <Typography
                                    variant="body2"
                                    color="text.turquoise.grey"
                                  >
                                    szt.
                                  </Typography>
                                )
                              }}
                            />
                          </Stack>
                        </Box>

                        <Box
                          sx={{
                            mt: "35.5px",
                            pl: 1,
                            width: { md: "3%" }
                          }}
                        >
                          {transportOrderLineItems.length > 1 && (
                            <ClearIcon
                              sx={{ cursor: "pointer" }}
                              onClick={() =>
                                deletePackage(transportOrderLineItem)
                              }
                            />
                          )}
                        </Box>
                      </Box>

                      {transportOrderLineItems.length - 1 === toliIndex &&
                      formik.values.groupedTransportOrderLineItems.reduce(
                        (sum, current) =>
                          sum + current.transportOrderLineItems.length,
                        0
                      ) < 10 ? (
                        <Box
                          sx={{
                            display: { xs: "none", md: "flex" }
                          }}
                        >
                          <Box sx={{ width: { md: "5%" } }} />

                          <Box sx={{ width: { md: "95%" } }}>
                            <Button
                              variant="outlined"
                              onClick={() =>
                                addTransportOrderLineItem(category)
                              }
                            >
                              Kolejna paczka
                            </Button>
                          </Box>
                        </Box>
                      ) : (
                        <></>
                      )}
                    </Stack>
                  </Panel>

                  <Panel
                    sx={{
                      backgroundColor: "#ffffff",
                      display: { xs: "block", sm: "block", md: "none" }
                    }}
                  >
                    <Stack spacing={2}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between"
                        }}
                        alignContent="center"
                      >
                        <OrdersIcon />

                        {transportOrderLineItems.length > 1 && (
                          <ClearIcon
                            sx={{ cursor: "pointer" }}
                            onClick={() =>
                              deletePackage(transportOrderLineItem)
                            }
                          />
                        )}
                      </Box>

                      <Stack spacing={2}>
                        <Box display="flex">
                          <Box sx={{ width: { xs: "30%" } }}>
                            <Stack spacing={1}>
                              <Typography
                                variant="body2"
                                color="text.turquoise.dark"
                              >
                                <strong>Wymiary</strong>
                              </Typography>
                              <TextField
                                name={`groupedTransportOrderLineItems.${gtoliIndex}.transportOrderLineItems.${toliIndex}.dimensionX`}
                                value={transportOrderLineItem.dimensionX}
                                onChange={formik.handleChange}
                                error={
                                  formik.touched
                                    .groupedTransportOrderLineItems?.[
                                    gtoliIndex
                                  ]?.transportOrderLineItems?.[toliIndex]
                                    ?.dimensionX &&
                                  Boolean(
                                    getIn(
                                      getIn(
                                        formik.errors
                                          .groupedTransportOrderLineItems?.[
                                          gtoliIndex
                                        ],
                                        "transportOrderLineItems"
                                      )?.[toliIndex],
                                      "dimensionX"
                                    )
                                  )
                                }
                                helperText={
                                  formik.touched
                                    .groupedTransportOrderLineItems?.[
                                    gtoliIndex
                                  ]?.transportOrderLineItems?.[toliIndex]
                                    ?.dimensionX &&
                                  getIn(
                                    getIn(
                                      formik.errors
                                        .groupedTransportOrderLineItems?.[
                                        gtoliIndex
                                      ],
                                      "transportOrderLineItems"
                                    )?.[toliIndex],
                                    "dimensionX"
                                  )
                                }
                                InputProps={{
                                  endAdornment: (
                                    <Typography
                                      variant="body2"
                                      color="text.turquoise.grey"
                                    >
                                      cm
                                    </Typography>
                                  )
                                }}
                              />
                            </Stack>
                          </Box>

                          <Box
                            sx={{
                              mt: "35.5px",
                              textAlign: "center",
                              width: {
                                xs: "5%"
                              }
                            }}
                          >
                            <MultiplyIcon />
                          </Box>
                          <Box sx={{ width: { xs: "30%" } }}>
                            <Stack spacing={1}>
                              <Box sx={{ height: "21px" }} />
                              <TextField
                                name={`groupedTransportOrderLineItems.${gtoliIndex}.transportOrderLineItems.${toliIndex}.dimensionY`}
                                value={transportOrderLineItem.dimensionY}
                                onChange={formik.handleChange}
                                error={
                                  formik.touched
                                    .groupedTransportOrderLineItems?.[
                                    gtoliIndex
                                  ]?.transportOrderLineItems?.[toliIndex]
                                    ?.dimensionY &&
                                  Boolean(
                                    getIn(
                                      getIn(
                                        formik.errors
                                          .groupedTransportOrderLineItems?.[
                                          gtoliIndex
                                        ],
                                        "transportOrderLineItems"
                                      )?.[toliIndex],
                                      "dimensionY"
                                    )
                                  )
                                }
                                helperText={
                                  formik.touched
                                    .groupedTransportOrderLineItems?.[
                                    gtoliIndex
                                  ]?.transportOrderLineItems?.[toliIndex]
                                    ?.dimensionY &&
                                  getIn(
                                    getIn(
                                      formik.errors
                                        .groupedTransportOrderLineItems?.[
                                        gtoliIndex
                                      ],
                                      "transportOrderLineItems"
                                    )?.[toliIndex],
                                    "dimensionY"
                                  )
                                }
                                InputProps={{
                                  endAdornment: (
                                    <Typography
                                      variant="body2"
                                      color="text.turquoise.grey"
                                    >
                                      cm
                                    </Typography>
                                  )
                                }}
                              />
                            </Stack>
                          </Box>
                          <Box
                            sx={{
                              mt: "35.5px",
                              textAlign: "center",
                              width: {
                                xs: "5%"
                              }
                            }}
                          >
                            <MultiplyIcon />
                          </Box>

                          <Box sx={{ width: { xs: "30%" } }}>
                            <Stack spacing={1}>
                              <Box sx={{ height: "21px" }} />
                              <TextField
                                name={`groupedTransportOrderLineItems.${gtoliIndex}.transportOrderLineItems.${toliIndex}.dimensionZ`}
                                value={transportOrderLineItem.dimensionZ}
                                onChange={formik.handleChange}
                                error={
                                  formik.touched
                                    .groupedTransportOrderLineItems?.[
                                    gtoliIndex
                                  ]?.transportOrderLineItems?.[toliIndex]
                                    ?.dimensionZ &&
                                  Boolean(
                                    getIn(
                                      getIn(
                                        formik.errors
                                          .groupedTransportOrderLineItems?.[
                                          gtoliIndex
                                        ],
                                        "transportOrderLineItems"
                                      )?.[toliIndex],
                                      "dimensionZ"
                                    )
                                  )
                                }
                                helperText={
                                  formik.touched
                                    .groupedTransportOrderLineItems?.[
                                    gtoliIndex
                                  ]?.transportOrderLineItems?.[toliIndex]
                                    ?.dimensionZ &&
                                  getIn(
                                    getIn(
                                      formik.errors
                                        .groupedTransportOrderLineItems?.[
                                        gtoliIndex
                                      ],
                                      "transportOrderLineItems"
                                    )?.[toliIndex],
                                    "dimensionZ"
                                  )
                                }
                                InputProps={{
                                  endAdornment: (
                                    <Typography
                                      variant="body2"
                                      color="text.turquoise.grey"
                                    >
                                      cm
                                    </Typography>
                                  )
                                }}
                              />
                            </Stack>
                          </Box>
                        </Box>

                        <Box display="flex">
                          <Box sx={{ width: { xs: "48%" } }}>
                            <Stack spacing={1}>
                              <Typography
                                variant="body2"
                                color="text.turquoise.dark"
                              >
                                <strong>Waga (1 szt.)</strong>
                              </Typography>
                              <TextField
                                name={`groupedTransportOrderLineItems.${gtoliIndex}.transportOrderLineItems.${toliIndex}.weight`}
                                value={transportOrderLineItem.weight}
                                onChange={formik.handleChange}
                                error={
                                  formik.touched
                                    .groupedTransportOrderLineItems?.[
                                    gtoliIndex
                                  ]?.transportOrderLineItems?.[toliIndex]
                                    ?.weight &&
                                  Boolean(
                                    getIn(
                                      getIn(
                                        formik.errors
                                          .groupedTransportOrderLineItems?.[
                                          gtoliIndex
                                        ],
                                        "transportOrderLineItems"
                                      )?.[toliIndex],
                                      "weight"
                                    )
                                  )
                                }
                                helperText={
                                  formik.touched
                                    .groupedTransportOrderLineItems?.[
                                    gtoliIndex
                                  ]?.transportOrderLineItems?.[toliIndex]
                                    ?.weight &&
                                  getIn(
                                    getIn(
                                      formik.errors
                                        .groupedTransportOrderLineItems?.[
                                        gtoliIndex
                                      ],
                                      "transportOrderLineItems"
                                    )?.[toliIndex],
                                    "weight"
                                  )
                                }
                                InputProps={{
                                  endAdornment: (
                                    <Typography
                                      variant="body2"
                                      color="text.turquoise.grey"
                                    >
                                      kg
                                    </Typography>
                                  )
                                }}
                              />
                            </Stack>
                          </Box>
                          <Box sx={{ width: { xs: "4%" } }} />
                          <Box sx={{ width: { xs: "48%" } }}>
                            <Stack spacing={1}>
                              <Typography
                                variant="body2"
                                color="text.turquoise.dark"
                              >
                                <strong>Liczba</strong>
                              </Typography>
                              <TextField
                                name={`groupedTransportOrderLineItems.${gtoliIndex}.transportOrderLineItems.${toliIndex}.quantity`}
                                value={transportOrderLineItem.quantity}
                                onChange={formik.handleChange}
                                error={
                                  formik.touched
                                    .groupedTransportOrderLineItems?.[
                                    gtoliIndex
                                  ]?.transportOrderLineItems?.[toliIndex]
                                    ?.quantity &&
                                  Boolean(
                                    getIn(
                                      getIn(
                                        formik.errors
                                          .groupedTransportOrderLineItems?.[
                                          gtoliIndex
                                        ],
                                        "transportOrderLineItems"
                                      )?.[toliIndex],
                                      "quantity"
                                    )
                                  )
                                }
                                helperText={
                                  formik.touched
                                    .groupedTransportOrderLineItems?.[
                                    gtoliIndex
                                  ]?.transportOrderLineItems?.[toliIndex]
                                    ?.quantity &&
                                  getIn(
                                    getIn(
                                      formik.errors
                                        .groupedTransportOrderLineItems?.[
                                        gtoliIndex
                                      ],
                                      "transportOrderLineItems"
                                    )?.[toliIndex],
                                    "quantity"
                                  )
                                }
                                InputProps={{
                                  endAdornment: (
                                    <Typography
                                      variant="body2"
                                      color="text.turquoise.grey"
                                    >
                                      szt.
                                    </Typography>
                                  )
                                }}
                              />
                            </Stack>
                          </Box>
                        </Box>
                        {transportOrderLineItems.length - 1 === toliIndex &&
                        formik.values.groupedTransportOrderLineItems.reduce(
                          (sum, current) =>
                            sum + current.transportOrderLineItems.length,
                          0
                        ) < 10 ? (
                          <Box>
                            <Button
                              variant="outlined"
                              onClick={() =>
                                addTransportOrderLineItem(category)
                              }
                            >
                              Kolejna paczka
                            </Button>
                          </Box>
                        ) : (
                          <></>
                        )}
                      </Stack>
                    </Stack>
                  </Panel>
                </React.Fragment>
              )
            )
        )}
      </>
    );
  };

  return (
    <TransportOrderWizardTemplate step={0}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} md={12}>
            <Stack spacing={1}>
              <Panel sx={{ backgroundColor: "#ffffff" }}>
                <PanelHeader>Kategorie</PanelHeader>
                <Box>
                  <FormHelperText
                    error={
                      formik.touched.categories?.people &&
                      Boolean(formik.errors.categories?.people)
                    }
                  >
                    {formik.touched.categories?.people &&
                      formik.errors.categories?.people}
                  </FormHelperText>
                </Box>
                <Box display="flex">
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={
                          <>
                            {" "}
                            <Box
                              className={classes.box}
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Stack
                                spacing={1}
                                justifyContent="center"
                                alignItems="center"
                              >
                                <TravelerIcon className="icon" />
                                <Typography
                                  variant="caption"
                                  color="text.grey.main"
                                >
                                  Przewóz osób
                                </Typography>
                              </Stack>
                            </Box>
                          </>
                        }
                        checkedIcon={
                          <>
                            {" "}
                            <Box
                              className={classes.boxChecked}
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Stack
                                spacing={1}
                                justifyContent="center"
                                alignItems="center"
                              >
                                <TravelerIcon className="icon" />
                                <Typography
                                  variant="caption"
                                  color="text.grey.main"
                                >
                                  Przewóz osób
                                </Typography>
                              </Stack>
                            </Box>
                          </>
                        }
                        checked={formik.values.categories.people}
                        onChange={handleChange}
                        name="categories.people"
                      />
                    }
                    label=""
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={
                          <>
                            {" "}
                            <Box
                              className={classes.box}
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Stack
                                spacing={1}
                                justifyContent="center"
                                alignItems="center"
                              >
                                <OrdersIcon className="icon" />
                                <Typography
                                  variant="caption"
                                  color="text.grey.main"
                                >
                                  Pozostałe
                                </Typography>
                              </Stack>
                            </Box>
                          </>
                        }
                        checkedIcon={
                          <>
                            {" "}
                            <Box
                              className={classes.boxChecked}
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Stack
                                spacing={1}
                                justifyContent="center"
                                alignItems="center"
                              >
                                <OrdersIcon className="icon" />
                                <Typography
                                  variant="caption"
                                  color="text.grey.main"
                                >
                                  Pozostałe
                                </Typography>
                              </Stack>
                            </Box>
                          </>
                        }
                        checked={formik.values.categories.others}
                        onChange={handleChange}
                        name="categories.others"
                      />
                    }
                    label=""
                  />
                </Box>
              </Panel>
              {openBoxPeople()}
            </Stack>
          </Grid>
        </Grid>
        <ButtonForm
          comeBack={mainPage}
          buttonName={i18n.t("buttons.next")}
          formik={formik}
        />
      </form>
    </TransportOrderWizardTemplate>
  );
};

export default Category;
