import React from "react";
import Section from "@shared/ui/organisms/Home/Section";
import Stepper from "@shared/ui/organisms/Home/Stepper";
import Header from "@shared/ui/organisms/Home/Header";
import DefaultTemplate from "@shared/ui/templates/DefaultTemplate";

const Home = () => {
  return (
    <DefaultTemplate
      seoProps={{
        link: [
          {
            rel: "alternate",
            href: "https://www.transking.eu/pl/",
            hrefLang: "pl"
          },
          {
            rel: "alternate",
            href: "https://www.transking.eu/en/",
            hrefLang: "en"
          },
          {
            rel: "alternate",
            href: "https://www.transking.eu/",
            hrefLang: "x-default"
          }
        ]
      }}
    >
      <Header />
      <Section />
      <Stepper />
    </DefaultTemplate>
  );
};

export default Home;
