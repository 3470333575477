import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

const useSectionStyles = makeStyles()((theme: Theme) => ({
  flag: {
    border: `1px solid ${theme.palette.border.grey.light}`,
    width: 20
  },
  inputIcon: {
    "&.MuiSvgIcon-root": {
      marginRight: theme.spacing(1),
      fontSize: "1rem"
    }
  },
  iconLabel: {
    "&.MuiSvgIcon-root": {
      fontSize: "1.2rem"
    }
  }
}));

export default useSectionStyles;
