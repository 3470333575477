import React from "react";
import { Box, Container, Grid, Stack } from "@mui/material";
import isometricCitySVG from "@assets/images/isometric_city.svg";
import { Link as RouterLink } from "react-router-dom";
import devicesIcon from "@assets/images/devices_icon.png";
import routes from "@routes/routes";
import Typography from "@shared/ui/atoms/Typography";
import { Trans } from "react-i18next";
import Button from "@shared/ui/atoms/Button";
import useStyles from "./Header.styles";

const Header = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.root} py={8}>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={5}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} md={5}>
            <Stack direction="column" spacing={4}>
              <Typography variant="display2" component="h1">
                <Trans i18nKey="pages.home.header.title" />
              </Typography>

              <Typography variant="subtitle1">
                <Trans i18nKey="pages.home.header.description" />
              </Typography>

              <Stack direction="row" spacing={2}>
                <Button
                  component={RouterLink}
                  color="secondary"
                  to={routes.root.transportOrders.new.category()}
                  size="large"
                >
                  <Trans i18nKey="buttons.order_transport" />
                </Button>

                <Button
                  component={RouterLink}
                  variant="text"
                  to={routes.root.transportOrders()}
                  size="large"
                >
                  <Trans i18nKey="buttons.orders" />
                </Button>
              </Stack>
            </Stack>

            <Stack direction="row" spacing={2} alignItems="center" mt={4}>
              <img src={devicesIcon} alt="devices icon" />
              <Typography variant="body2">
                <Trans i18nKey="pages.home.header.responsive_description" />
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} md={7}>
            <img src={isometricCitySVG} alt="isometric city" />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Header;
