import { Grid } from "@mui/material";
import Stack from "@mui/material/Stack";
import Button from "@shared/ui/atoms/Button";
import routes from "@routes/routes";
import Typography from "@shared/ui/atoms/Typography";
import React from "react";
import { useHistory } from "react-router-dom";
import ContainerTemplate from "@shared/ui/templates/ContainerTemplate";

const NoMatch: React.FC<any> = () => {
  const history = useHistory();

  const mainPage = () => {
    history.push(routes.root());
  };

  return (
    <ContainerTemplate
      seoProps={{
        title: "404",
        robots: "follow, noindex"
      }}
    >
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={12} md={8}>
          <Stack spacing={2} alignItems="center">
            <Typography variant="h1" color="text.primary">
              <strong>404</strong>
            </Typography>

            <Typography variant="h4" color="text.primary">
              Przepraszamy. Nie znaleziono strony.
            </Typography>

            <Typography variant="body1" color="text.grey.main">
              Strona której szukasz nie istnieje. Spróbuj ponownie.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={12} textAlign="center" mt={3}>
          <Button variant="outlined" onClick={() => mainPage()}>
            Powrót
          </Button>
        </Grid>
      </Grid>
    </ContainerTemplate>
  );
};

export default NoMatch;
