import { createSlice } from "@reduxjs/toolkit";

const dialogSlice = createSlice({
  name: "dialog",
  initialState: {
    login: false,
    register: false,
    passwordReminder: false
  },
  reducers: {
    showLoginDialog: (state) => {
      state.login = true;
    },
    hideLoginDialog: (state) => {
      state.login = false;
    },
    showRegisterDialog: (state) => {
      state.register = true;
    },
    hideRegisterDialog: (state) => {
      state.register = false;
    },
    showPasswordReminderDialog: (state) => {
      state.passwordReminder = true;
    },
    hidePasswordReminderDialog: (state) => {
      state.passwordReminder = false;
    }
  }
});

export const {
  showLoginDialog,
  hideLoginDialog,
  showRegisterDialog,
  hideRegisterDialog,
  showPasswordReminderDialog,
  hidePasswordReminderDialog
} = dialogSlice.actions;

export default dialogSlice.reducer;

export const selectLogin = (state: any) => state.dialog.login;
export const selectRegister = (state: any) => state.dialog.register;
export const selectPasswordReminder = (state: any) =>
  state.dialog.passwordReminder;
