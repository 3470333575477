import api from "./api";
import { TCheckout, TResource } from "./types";

export const checkoutsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createCheckout: builder.mutation<TResource<TCheckout>, any>({
      query: ({ body }) => ({
        url: "/checkouts",
        method: "POST",
        body: { ...body }
      })
    })
  })
});

export const { useCreateCheckoutMutation } = checkoutsApi;
