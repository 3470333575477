import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";

const useStyles = makeStyles()((theme: Theme) => ({
  inputIcon: {
    "&.MuiSvgIcon-root": {
      marginRight: theme.spacing(2),
      fontSize: "1rem"
    }
  },
  inputEndIcon: {
    "&.MuiSvgIcon-root": {
      fontSize: "1rem"
    }
  }
}));

export default useStyles;
