import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useSectionStyles = makeStyles()((theme: Theme) => ({
  openPickerIcon: {
    "&.MuiSvgIcon-root": {
      fontSize: "1rem"
    }
  },
  toolbar: {
    "&.MuiPickersLayout-toolbar": {
      gridArea: "1 / 2 / auto / 4"
    }
  },
  popper: {
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    '&[data-popper-placement="bottom-start"]': {
      marginTop: "12px !important"
    },
    '&[data-popper-placement="top-start"]': {
      marginBottom: "12px !important"
    },
    ".MuiPickersDay-root": {
      "&.Mui-selected": {
        backgroundColor: `${theme.palette.primary.main} !important`
      },
      ":focus": {
        "&.Mui-selected": {
          backgroundColor: `${theme.palette.primary.main} !important`
        }
      },
      margin: "0 2px"
    }
  },
  mobilePaper: {
    ".MuiPickersDay-root": {
      "&.Mui-selected": {
        backgroundColor: `${theme.palette.primary.main} !important`
      },
      ":focus": {
        "&.Mui-selected": {
          backgroundColor: `${theme.palette.primary.main} !important`
        }
      },
      margin: "0 2px"
    }
  }
}));

export default useSectionStyles;
