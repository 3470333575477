import React from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import HelpTemplate from "@shared/ui/templates/HelpTemplate";
import Typography from "@shared/ui/atoms/Typography";
import Panel from "@shared/ui/molecules/Panel/Panel";
import Alert from "@shared/ui/atoms/Alert";
import { useTranslation } from "react-i18next";

const HowCanIPayForTheOffer = () => {
  const { i18n } = useTranslation();

  return (
    <HelpTemplate
      seoProps={{
        title: i18n.t("pages.howCanIPayForTheOffer.head.title")
      }}
    >
      <Grid item xs={12} sm={12} md={8} mt={3}>
        <Panel>
          <Stack spacing={2}>
            <Typography variant="h5" component="h1">
              W jaki sposób mogę opłacić ofertę?
            </Typography>

            <Box pt={1}>
              <Stack spacing={2}>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    <Typography>
                      Przejdź do zakładki{" "}
                      <Box display="inline-block">
                        <Typography color="text.turquoise.main">
                          <strong>Moje zlecenia</strong>
                        </Typography>
                      </Box>
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      Klikjnij w zlecenie i tam znajdziesz zakładkę{" "}
                      <Box display="inline-block">
                        <Typography color="text.turquoise.main">
                          <strong>Oferty</strong>
                        </Typography>
                      </Box>
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      Zaakceptuj ofertę która Cię interesuje, po kliknięciu
                      przycisku opłać zostaniesz przekierowany do strony
                      płatności prowizji
                    </Typography>
                  </li>

                  <li>
                    <Typography>
                      Jeśli płatność przebiegła prawidłowo, zleceniodawca
                      otrzyma dane kontaktowe przewoźnika, a przewoźnik
                      zleceniodawcy
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      Cena oferty nie uwzględnia prowizji i trzeba ją w całości
                      przekazać przewoźnikowi
                    </Typography>
                  </li>
                </ul>

                <Alert severity="info">
                  <Typography>
                    <Box display="inline-block">
                      <Typography color="text.turquoise.main">
                        <strong>TransKing.eu</strong>
                      </Typography>
                    </Box>{" "}
                    nie pośredniczy w przekazywaniu pieniędzy przewoźnikowi i
                    realizacji zleceń. Posiadamy możliwość wpłaty dodatkowych
                    środków na wsparcie naszego serwisu, która jest formą
                    darowizny.
                  </Typography>
                </Alert>
              </Stack>
            </Box>
          </Stack>
        </Panel>
      </Grid>
    </HelpTemplate>
  );
};

export default HowCanIPayForTheOffer;
