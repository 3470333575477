import api from "./api";
import {
  TGetOffer,
  TGetOffers,
  TGetPublicOffer,
  TGetPublicOffers
} from "./offersApi.types";
import { TOffer, TResource } from "./types";

export const offersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOffers: builder.query<TGetOffers, any>({
      query: ({ params }) => ({
        url: "/offers",
        params: {
          include: [
            "auction",
            "auction.transport_order",
            "user",
            "commission"
          ].join(","),
          sort: ["-created_at", "id"].join(","),
          ...params
        }
      }),
      providesTags: ["Offer"]
    }),
    getOffer: builder.query<TGetOffer, any>({
      query: ({ id }) => ({
        url: `/offers/${id}`,
        params: {
          include: [
            "auction",
            "auction.transport_order",
            "user",
            "commission"
          ].join(",")
        }
      }),
      providesTags: ["Offer"]
    }),
    cancelOffer: builder.mutation<TResource<TOffer>, any>({
      query: ({ id, body }) => ({
        url: `/offers/${id}/cancel`,
        method: "POST",
        body: { ...body }
      }),
      invalidatesTags: ["Offer"]
    }),
    acceptOffer: builder.mutation<TResource<TOffer>, any>({
      query: ({ offerId }) => ({
        url: `/offers/${offerId}/accept`,
        method: "POST",
        body: {
          data: {
            attributes: {}
          }
        }
      }),
      invalidatesTags: ["Offer"]
    }),
    createOffer: builder.mutation<TResource<TOffer>, any>({
      query: ({ body }) => ({
        url: "/offers",
        method: "POST",
        body: { ...body }
      }),
      invalidatesTags: ["Offer"]
    }),
    getPublicOffers: builder.query<TGetPublicOffers, any>({
      query: ({ params }) => ({
        url: "/public/offers",
        params: {
          include: ["auction", "auction.transport_order", "user"].join(","),
          sort: ["created_at", "id"].join(","),
          ...params
        }
      }),
      providesTags: ["Offer"]
    }),
    getPublicOffer: builder.query<TGetPublicOffer, any>({
      query: ({ id }) => ({
        url: `/public/offers/${id}`,
        params: {
          include: ["auction", "auction.transport_order", "user"].join(",")
        }
      }),
      providesTags: ["Offer"]
    })
  })
});

export const {
  useGetOffersQuery,
  useGetOfferQuery,
  useCancelOfferMutation,
  useGetPublicOffersQuery,
  useGetPublicOfferQuery,
  useAcceptOfferMutation,
  useCreateOfferMutation
} = offersApi;
