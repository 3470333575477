import addressFormatter from "@fragaria/address-formatter";
import htmlReactParser from "html-react-parser";

const formattedAddress = (row: any) => {
  const address = addressFormatter
    .format(
      {
        postcode: row.postCode,
        city: row.city,
        state: row.state,
        country: row.countryName,
        countryCode: row.countryIsoCode,
        streetName: row.streetName,
        streetNumber: row.streetNumber
      },
      {
        output: "array"
      }
    )
    .join("<br />");

  return htmlReactParser(address);
};

export default formattedAddress;
