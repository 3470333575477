import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

const useSectionStyles = makeStyles()((theme: Theme) => ({
  icon: {
    "&.MuiSvgIcon-root": {
      color: "#1E99C0",
      cursor: "pointer"
    }
  },
  emptyOffers: {
    "&.MuiTypography-root": {
      color: "#96c5e8"
    }
  },
  boxEmptyOffers: {
    "&.MuiBox-root": {
      textAlign: "center",
      border: "1px dashed #96c5e8",
      padding: theme.spacing(0.5)
    }
  },
  boxAvatar: {
    "&.MuiBox-root": {
      backgroundColor: "white",
      padding: theme.spacing(1),
      justifyContent: "space-between",
      alignItems: "center"
    }
  },
  displayName: {
    "&.MuiTypography-root": {
      fontSize: "0.875rem",
      paddingLeft: theme.spacing(1)
    }
  },
  reviewUser: {
    "&.MuiTypography-root": {
      fontSize: "0.875rem",
      paddingLeft: theme.spacing(0.5)
    }
  },
  starIcon: {
    "&.MuiSvgIcon-root": {
      fontSize: "1.25rem",
      paddingTop: theme.spacing(1)
    }
  },
  typographySize: {
    "&.MuiTypography-root": {
      fontSize: "0.875rem"
    }
  },
  bestOffer: {
    "&.MuiBox-root": {
      padding: theme.spacing(2),
      background: "#663D8F",
      textAlign: "center",

      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      minHeight: "200px"
    }
  },
  itemBackgroundColor: {
    "&.MuiBox-root": {
      backgroundColor: "#663D8F"
    }
  },
  currency: {
    "&.MuiTypography-root": {
      fontSize: 25,
      color: "#dadada",
      paddingRight: theme.spacing(1)
    }
  },
  textField: {
    "&.MuiInput-root": {
      fontSize: 25,
      "&.MuiFormControl-root": {
        color: "black",
        width: "70%",
        fontSize: 10,
        border: "none",
        "& input::placeholder": {
          fontSize: "0.875rem"
        },
        "&:active": {
          backgroundColor: "grey"
        }
      }
    }
  },
  fab: {
    "&.MuiButtonBase-root": {
      color: "#000000",
      background: "white",
      boxShadow: "none",
      "&:hover": {
        background: "white"
      }
    }
  },
  iconSvgWeight: {
    "&.MuiSvgIcon-root": {
      fontWeight: 1200
    }
  },
  fabIcon: {
    "&.MuiButtonBase-root": {
      color: "#000000",
      background: "white",
      boxShadow: "none",
      "&:hover": {
        background: "white"
      }
    }
  },
  typographyBestOffer: {
    "&.MuiTypography-root": {
      color: "white",
      fontSize: "2.188rem"
    }
  },
  displayNameOffer: {
    "&.MuiTypography-root": {
      color: "white",
      fontSize: "1.25rem"
    }
  },
  none: {
    "&.MuiSvgIcon-root": {
      backgroundColor: "none",
      cursor: "none",
      "&:hover": { backgroundColor: "white", cursor: "none" }
    }
  }
}));

export default useSectionStyles;
