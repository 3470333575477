import addressFormatter from "@fragaria/address-formatter";

const formattedAddressWithComma = (row: any) => {
  const address = addressFormatter
    .format(
      {
        postcode: row.postCode,
        city: row.city,
        state: row.state,
        country: row.countryName,
        countryCode: row.countryIsoCode,
        streetName: row.streetName,
        streetNumber: row.streetNumber
      },
      {
        output: "array"
      }
    )
    .join(", ");

  return address;
};

export default formattedAddressWithComma;
