import React from "react";
import MUITextField from "@shared/ui/atoms/TextField";

const TextArea: React.FC<any> = (props) => {
  const { InputProps = {} } = props;
  const { inputProps = {}, ...restInputProps } = InputProps;

  return (
    <MUITextField
      {...props}
      multiline
      minRows={5}
      InputProps={{
        inputProps: {
          spellCheck: "true",
          autoCorrect: "on",
          ...inputProps
        },
        ...restInputProps
      }}
    />
  );
};

export default TextArea;
