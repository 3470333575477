import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import UserPanelTemplate from "@shared/ui/templates/UserPanelTemplate";
import Box from "@mui/material/Box";
import Typography from "@shared/ui/atoms/Typography";
import Stack from "@mui/material/Stack";
import { Divider } from "@mui/material";
import coffee from "@assets/images/coffee.svg";
import share from "@assets/images/share.svg";
import { useGetPurchaseOrderQuery } from "@api/TranstubeCore/purchaseOrdersApi";
import { useParams } from "react-router-dom";
import { formattedCurrencyPrice } from "@shared/utils/currencyPrice";
import LinearDeterminate from "@shared/ui/molecules/LinearDeterminate/LinearDeterminate";
import NoMatch from "@pages/noMatch/NoMatch";
import { useLazyGetTransportOrderQuery } from "@api/TranstubeCore/transportOrdersApi";
import { useLazyGetUserQuery } from "@api/TranstubeCore/usersApi";
import Panel from "@shared/ui/molecules/Panel";
import UserAvatar from "@shared/ui/molecules/UserAvatar";
import routes from "@routes/routes";
import convertDate from "@shared/utils/convertDate";
import { Trans } from "react-i18next";
import PanelHeader from "@shared/ui/atoms/PanelHeader";
import useStyles from "./PurchaseOrder.styles";

const PurchaseOrder: React.FC = () => {
  const { classes } = useStyles();
  const { id } = useParams<any>();

  const { data: purchaseOrderResult = { data: undefined }, error = {} } =
    useGetPurchaseOrderQuery({
      id
    });

  const { data: purchaseOrder } = purchaseOrderResult;

  const [
    getTransportOrder,
    { data: transportOrderResult = { data: undefined } }
  ] = useLazyGetTransportOrderQuery();

  const { data: transportOrder } = transportOrderResult;

  const [getUser, { data: userResult = { data: undefined } }] =
    useLazyGetUserQuery();

  const { data: user } = userResult;

  useEffect(() => {
    if (
      transportOrder ||
      !purchaseOrder ||
      purchaseOrder.status !== "completed"
    )
      return;

    getTransportOrder({ id: purchaseOrder.meta.transport_order_id });
  }, [purchaseOrder]);

  useEffect(() => {
    if (user || !transportOrder) return;

    getUser({ id: transportOrder.auction.winningOffer.user.id });
  }, [transportOrder]);

  const displayUserData = () => {
    if (!transportOrder || !user) return <></>;

    return (
      <>
        <Grid item xs={12} md={8}>
          <Panel>
            <PanelHeader>Dane kontaktowe przewoźnika</PanelHeader>

            <Stack spacing={2}>
              <Box display="flex">
                <Stack spacing={1} direction="row" alignItems="center">
                  <UserAvatar
                    size={48}
                    color={user?.avatarColor}
                    to={routes.root.users.user({ id: user.id })}
                    sx={{ cursor: "pointer" }}
                  >
                    {user.displayName}
                  </UserAvatar>
                  <Typography variant="body2" color="text.turquoise.dark">
                    <strong>{user.displayName}</strong>
                  </Typography>
                </Stack>
              </Box>
              <Box>
                <Stack spacing={1}>
                  <Typography variant="body2" color="text.turquoise.dark">
                    <strong>Imię i nazwisko</strong>
                  </Typography>

                  <Typography variant="body2" color="text.turquoise.dark">
                    {user.name}
                  </Typography>
                </Stack>
              </Box>
              <Box>
                <Stack spacing={1}>
                  <Typography variant="body2" color="text.turquoise.dark">
                    <strong>Adres e-mail</strong>
                  </Typography>

                  <Typography variant="body2" color="text.turquoise.dark">
                    {user.email}
                  </Typography>
                </Stack>
              </Box>

              <Box>
                <Stack spacing={1}>
                  <Typography variant="body2" color="text.turquoise.dark">
                    <strong>Numer telefonu</strong>
                  </Typography>

                  <Typography variant="body2" color="text.turquoise.dark">
                    {user.phoneNumber}
                  </Typography>
                </Stack>
              </Box>
            </Stack>
          </Panel>
        </Grid>
      </>
    );
  };

  if ("status" in error && error.status === 404) {
    return <NoMatch />;
  }

  const box = (item: any) => {
    if (item?.productable === undefined) {
      return (
        <Box
          display="flex"
          sx={{ justifyContent: "space-between" }}
          alignItems="center"
        >
          <Box display="flex">
            <Stack spacing={2} direction="row" alignItems="center">
              <img src={coffee} alt="coffee" className={classes.boxLogo} />

              <Typography variant="body2" color="text.turquoise.dark">
                {item?.name}
              </Typography>
            </Stack>
          </Box>

          <Box>
            <Typography variant="body2" color="text.turquoise.dark">
              {formattedCurrencyPrice(
                parseFloat(item.grossPrice),
                item.currencyIsoCode
              )}
            </Typography>
          </Box>
        </Box>
      );
    }

    return (
      <Box
        display="flex"
        sx={{ justifyContent: "space-between" }}
        alignItems="center"
      >
        <Box display="flex">
          <Stack spacing={2} direction="row" alignItems="center">
            <img src={share} alt="share" className={classes.boxLogo} />

            <Typography variant="body2" color="text.turquoise.dark">
              {item?.name}
            </Typography>
          </Stack>
        </Box>

        <Box>
          <Typography variant="body2" color="text.turquoise.dark">
            {formattedCurrencyPrice(
              parseFloat(item.grossPrice),
              item.currencyIsoCode
            )}
          </Typography>
        </Box>
      </Box>
    );
  };

  const displayData = (dataCreate: any) => {
    const data = convertDate(dataCreate);

    return data;
  };

  if (
    !purchaseOrder ||
    (purchaseOrder.status === "completed" && (!transportOrder || !user))
  )
    return <LinearDeterminate />;

  return (
    <UserPanelTemplate>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Panel>
            <Stack spacing={1}>
              <Stack spacing={2}>
                <Box display="flex" sx={{ justifyContent: "space-between" }}>
                  <Box>
                    <Typography variant="body2" color="text.turquoise.dark">
                      <strong>Produkty</strong>
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2" color="text.turquoise.dark">
                      <strong>Cena</strong>
                    </Typography>
                  </Box>
                </Box>
                <Divider sx={{ borderColor: "#f1f1f1" }} />
                {purchaseOrder?.purchaseOrderLineItems?.map((row: any) => (
                  <div key={row.id}>
                    <Stack spacing={2}>{box(row)}</Stack>
                  </div>
                ))}
                <Divider sx={{ borderColor: "#f1f1f1" }} />
                <Box display="flex" sx={{ justifyContent: "space-between" }}>
                  <Box display="flex" />
                  <Box display="flex">
                    <Stack spacing={3} direction="row">
                      <Typography variant="body2" color="text.turquoise.dark">
                        <strong>Suma</strong>
                      </Typography>
                      <Typography variant="body2" color="text.turquoise.dark">
                        {formattedCurrencyPrice(
                          parseFloat(purchaseOrder.totalGrossPrice),
                          purchaseOrder.currencyIsoCode
                        )}
                      </Typography>
                    </Stack>
                  </Box>
                </Box>
              </Stack>
            </Stack>
          </Panel>
        </Grid>
        <Grid item xs={12} md={4}>
          <Panel>
            <Stack spacing={1}>
              <Box display="flex" sx={{ justifyContent: "space-between" }}>
                <Box sx={{ width: "60%" }}>
                  <Typography variant="body2" color="text.turquoise.dark">
                    <strong>Data publikacji</strong>
                  </Typography>
                </Box>
                <Box sx={{ width: "40%" }}>
                  <Typography variant="body2" color="text.turquoise.dark">
                    {displayData(purchaseOrder.createdAt)}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex">
                <Box sx={{ width: "60%" }}>
                  <Typography variant="body2" color="text.turquoise.dark">
                    <strong>Status zamówienia</strong>
                  </Typography>
                </Box>
                <Box sx={{ width: "40%" }}>
                  <Typography variant="body2" color="text.turquoise.dark">
                    <Trans
                      i18nKey={`statuses.${purchaseOrder.status}`}
                      defaults={purchaseOrder.status}
                    />
                  </Typography>
                </Box>
              </Box>
            </Stack>
          </Panel>
        </Grid>
        {displayUserData()}
      </Grid>
    </UserPanelTemplate>
  );
};

export default PurchaseOrder;
