import React from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@shared/ui/atoms/TextField";
import Checkbox from "@shared/ui/atoms/Checkbox";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { useFormik } from "formik";
import Alert from "@shared/ui/atoms/Alert";
import LoadingButton from "@shared/ui/atoms/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import BaseDialog from "@shared/ui/atoms/Dialog";
import { Stack } from "@mui/material";
import Link from "@shared/ui/atoms/Link";
import PasswordTextField from "@shared/ui/atoms/PasswordTextField";
import { useGenerateTokenMutation } from "@api/TranstubeCore/authApi";
import { setCredentials } from "@src/store/authSlice";
import {
  hideLoginDialog,
  selectLogin,
  showPasswordReminderDialog,
  showRegisterDialog
} from "@store/dialogSlice";
import Typography from "@shared/ui/atoms/Typography";
import handleFormError from "@shared/utils/handleFormError";
import useStyles from "./SignInDialog.styles";
import validationSchema from "./SignInDialog.validationSchema";

const SignIn: React.FC<any> = () => {
  const { classes } = useStyles();
  const [generateToken] = useGenerateTokenMutation();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const handleSubmit = async (values: any, actions: any) => {
    const genetateTokenParams = {
      body: {
        data: {
          attributes: {
            email: values.email,
            password: values.password
          }
        }
      }
    };

    await generateToken(genetateTokenParams)
      .unwrap()
      .then((payload) => {
        actions.resetForm();

        dispatch(
          setCredentials({
            user: payload.data,
            accessToken: payload.meta.access_token,
            refreshToken: payload.meta.refresh_token
          })
        );

        dispatch(hideLoginDialog());
      })
      .catch((ex: any) => {
        const pointers = {
          "/data": {
            field: "base",
            i18n: "base"
          },
          "/data/attributes/email": {
            field: "email",
            i18n: "user.email"
          },
          "/data/attributes/password": {
            field: "password",
            i18n: "user.password"
          }
        };

        ex.data?.errors?.forEach((error: any) => {
          handleFormError(pointers, error, i18n, actions);
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      base: null,
      email: "",
      password: ""
    },
    validationSchema: validationSchema(),
    onSubmit: handleSubmit
  });

  const handleOpen = useSelector(selectLogin);

  const closeDialogLogin = () => {
    formik.resetForm();
    dispatch(hideLoginDialog());
  };

  const openDialogPasswordReminder = () => {
    closeDialogLogin();
    dispatch(showPasswordReminderDialog());
  };

  const openDialogRegister = () => {
    closeDialogLogin();
    dispatch(showRegisterDialog());
  };

  const handleClose = () => {
    formik.resetForm();
    closeDialogLogin();
  };

  return (
    <BaseDialog handleOpen={handleOpen} handleClose={handleClose}>
      <DialogTitle sx={{ textAlign: "center" }}>
        <Trans i18nKey="ui.menu.login" />
      </DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={2}>
            {formik.errors.base && (
              <Alert severity="error">{formik.errors.base}</Alert>
            )}
            <Stack spacing={1}>
              <Typography variant="body2" color="text.turquoise.dark">
                <strong>Adres e-mail</strong>
              </Typography>
              <TextField
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                placeholder="Wpisz adres e-mail"
                InputProps={{
                  startAdornment: (
                    <MailOutlineIcon className={classes.inputIcon} />
                  )
                }}
                autoComplete="on"
              />
            </Stack>
            <Stack spacing={1}>
              <Typography variant="body2" color="text.turquoise.dark">
                <strong>Hasło</strong>
              </Typography>
              <PasswordTextField
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                placeholder="Wpisz hasło"
              />
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Checkbox label={<Trans i18nKey="ui.login.remember_me" />} />

              <Link variant="body2" onClick={openDialogPasswordReminder}>
                <Trans i18nKey="ui.password_reminder.title" />
              </Link>
            </Stack>

            <LoadingButton loading={Boolean(formik.isSubmitting)}>
              <Trans i18nKey="buttons.login" />
            </LoadingButton>
          </Stack>
        </form>
      </DialogContent>

      <DialogContent>
        <Stack spacing={1} direction="row" justifyContent="center">
          <Typography variant="body2" color="text.grey.main">
            <Trans i18nKey="ui.login.information_text" />
          </Typography>

          <Link variant="body2" onClick={openDialogRegister}>
            <Trans i18nKey="ui.menu.register" />
          </Link>
        </Stack>
      </DialogContent>
    </BaseDialog>
  );
};

export default SignIn;
