import * as yup from "yup";
import validationSchemaSchedule from "../schedule/Schedule.validationSchema";
import validationSchemaCategory from "../category/Category.validationSchema";

const validationSchema = (i18n: any) => {
  return yup.object({
    extraInformation: yup.string().max(1000),
    currency: yup.string().required().nullable(),
    preferences: yup
      .object()
      .shape({
        privatePerson: yup.boolean().required(),
        company: yup.boolean().when("privatePerson", {
          is: false,
          then: (schema) => schema.isTrue()
        }),
        invoice: yup.boolean()
      })
      .required(),
    ...validationSchemaSchedule(i18n).fields,
    ...validationSchemaCategory(i18n).fields
  });
};

export default validationSchema;
