import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import UserPanelTemplate from "@shared/ui/templates/UserPanelTemplate";
import { useParams } from "react-router-dom";
import { formattedCurrencyPrice } from "@shared/utils/currencyPrice";
import { useGetOfferQuery } from "@api/TranstubeCore/offersApi";
import Stack from "@mui/material/Stack";
import { Box } from "@mui/material";
import Typography from "@shared/ui/atoms/Typography";
import UserAvatar from "@shared/ui/molecules/UserAvatar";
import { useLazyGetUserQuery } from "@api/TranstubeCore/usersApi";
import { useLazyGetTransportOrderQuery } from "@api/TranstubeCore/transportOrdersApi";
import formattedAddressWithComma from "@shared/utils/formattedAddressWithComma";
import convertToLowerCase from "@shared/utils/convertToLowerCase";
import OriginMarkerIcon from "@shared/ui/atoms/icons/OriginMarkerIcon";
import DestinationMarkerIcon from "@shared/ui/atoms/icons/DestinationMarkerIcon";
import convertDate from "@shared/utils/convertDate";
import Panel from "@shared/ui/molecules/Panel/Panel";
import routes from "@routes/routes";
import LinearDeterminate from "@shared/ui/molecules/LinearDeterminate/LinearDeterminate";
import NoMatch from "@pages/noMatch/NoMatch";
import { Trans } from "react-i18next";
import PanelHeader from "@shared/ui/atoms/PanelHeader";
import useStyles from "./Offer.styles";

const Offer: React.FC = () => {
  const { classes } = useStyles();
  const { id } = useParams<any>();

  const { data: offerResult = { data: undefined }, error = {} } =
    useGetOfferQuery({ id });

  const { data: offer } = offerResult;

  const [getUser, { data: userResult = { data: undefined } }] =
    useLazyGetUserQuery();

  const { data: user } = userResult;

  const [
    getTransportOrder,
    { data: transportOrderResult = { data: undefined } }
  ] = useLazyGetTransportOrderQuery();

  const { data: transportOrder } = transportOrderResult;

  useEffect(() => {
    if (transportOrder || !offer || offer.status !== "completed") return;

    getTransportOrder({ id: offer.auction.transportOrder.id });
  }, [offer]);

  useEffect(() => {
    if (user || !transportOrder) return;

    getUser({ id: transportOrder.auction.user.id });
  }, [transportOrder]);

  if ("status" in error && error.status === 404) {
    return <NoMatch />;
  }

  if (!offer || (offer.status === "completed" && (!transportOrder || !user)))
    return <LinearDeterminate />;

  const flagFromLocation = () => {
    if (!transportOrder) return <></>;

    return (
      <Box pl={2}>
        <sup>
          <img
            loading="lazy"
            src={`https://flagcdn.com/w20/${convertToLowerCase(
              transportOrder.fromLocation.countryIsoCode
            )}.png`}
            srcSet={`https://flagcdn.com/w40/${convertToLowerCase(
              transportOrder.fromLocation.countryIsoCode
            )}.png 2x`}
            alt=""
            className={classes.flag}
          />
        </sup>
      </Box>
    );
  };

  const flagToLocation = () => {
    if (!transportOrder) return <></>;

    return (
      <Box pl={2}>
        <sup>
          <img
            loading="lazy"
            src={`https://flagcdn.com/w20/${convertToLowerCase(
              transportOrder.toLocation.countryIsoCode
            )}.png`}
            srcSet={`https://flagcdn.com/w40/${convertToLowerCase(
              transportOrder.toLocation.countryIsoCode
            )}.png 2x`}
            alt=""
            className={classes.flag}
          />
        </sup>
      </Box>
    );
  };

  const offerCompleted = () => {
    if (!transportOrder || !user) return <></>;

    return (
      <>
        <Grid item xs={12} md={6} mt={1}>
          <Panel>
            <PanelHeader>Dane kontaktowe zleceniodawcy</PanelHeader>

            <Stack spacing={2}>
              <Box display="flex">
                <Stack spacing={1} direction="row" alignItems="center">
                  <UserAvatar
                    size={48}
                    color={user?.avatarColor}
                    to={routes.root.users.user({ id: user.id })}
                    sx={{ cursor: "pointer" }}
                  >
                    {user.displayName}
                  </UserAvatar>
                  <Typography
                    variant="body2"
                    color="text.turquoise.dark"
                    sx={{ wordBreak: "break-word" }}
                  >
                    <strong>{user.displayName}</strong>
                  </Typography>
                </Stack>
              </Box>
              <Box>
                <Stack spacing={1}>
                  <Typography variant="body2" color="text.turquoise.dark">
                    <strong>Imię i nazwisko</strong>
                  </Typography>

                  <Typography
                    variant="body2"
                    color="text.turquoise.dark"
                    sx={{ wordBreak: "break-word" }}
                  >
                    {user.name}
                  </Typography>
                </Stack>
              </Box>
              <Box>
                <Stack spacing={1}>
                  <Typography variant="body2" color="text.turquoise.dark">
                    <strong>Adres e-mail</strong>
                  </Typography>

                  <Typography variant="body2" color="text.turquoise.dark">
                    {user.email}
                  </Typography>
                </Stack>
              </Box>

              <Box>
                <Stack spacing={1}>
                  <Typography variant="body2" color="text.turquoise.dark">
                    <strong>Numer telefonu</strong>
                  </Typography>

                  <Typography variant="body2" color="text.turquoise.dark">
                    {user.phoneNumber}
                  </Typography>
                </Stack>
              </Box>
            </Stack>
          </Panel>
        </Grid>
        <Grid item xs={12} md={6} mt={1}>
          <Panel>
            <PanelHeader>Szczegóły podróży</PanelHeader>

            <Stack spacing={2}>
              <Box>
                <Stack spacing={1}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <OriginMarkerIcon />
                    <Typography variant="body2" color="text.turquoise.dark">
                      <strong>
                        <Trans i18nKey="labels.from_location" />
                      </strong>
                    </Typography>
                  </Stack>
                  <Box display="flex" pl={1}>
                    <Typography variant="body2" color="text.turquoise.dark">
                      {formattedAddressWithComma(transportOrder.fromLocation)}
                    </Typography>
                    {flagFromLocation()}
                  </Box>
                </Stack>
              </Box>

              <Box>
                <Stack spacing={1}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <DestinationMarkerIcon />

                    <Typography variant="body2" color="text.turquoise.dark">
                      <strong>
                        <Trans i18nKey="labels.to_location" />
                      </strong>
                    </Typography>
                  </Stack>
                  <Box display="flex" pl={1}>
                    <Typography variant="body2" color="text.turquoise.dark">
                      {formattedAddressWithComma(transportOrder.toLocation)}
                    </Typography>
                    {flagToLocation()}
                  </Box>
                </Stack>
              </Box>
            </Stack>
          </Panel>
        </Grid>
      </>
    );
  };

  return (
    <UserPanelTemplate
      seoProps={{
        title: [
          `Szczegóły oferty ${offer.user.displayName}`,
          "Moje oferty"
        ].join(" - ")
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Stack spacing={1}>
            <Panel>
              <Stack spacing={1}>
                <Typography variant="body2" color="text.turquoise.dark">
                  <strong>Data publikacji</strong>
                </Typography>
                <Typography variant="body2" color="text.turquoise.dark">
                  {convertDate(offer.createdAt)}
                </Typography>
              </Stack>
            </Panel>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4}>
          <Panel>
            <Stack spacing={1}>
              <Typography variant="body2" color="text.turquoise.dark">
                <strong>Cena</strong>
              </Typography>

              <Typography variant="body2" color="text.turquoise.dark">
                {formattedCurrencyPrice(
                  parseFloat(offer.price),
                  offer.currencyIsoCode
                )}
              </Typography>
            </Stack>
          </Panel>
        </Grid>
        <Grid item xs={12} md={4}>
          <Panel>
            <Stack spacing={1}>
              <Typography variant="body2" color="text.turquoise.dark">
                <strong>Status zamówienia</strong>
              </Typography>

              <Typography variant="body2" color="text.turquoise.dark">
                <Trans
                  i18nKey={`statuses.${offer.status}`}
                  defaults={offer.status}
                />
              </Typography>
            </Stack>
          </Panel>
        </Grid>
        {offerCompleted()}
      </Grid>
    </UserPanelTemplate>
  );
};

export default Offer;
