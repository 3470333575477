import React from "react";
import SideMenuOrder from "@shared/ui/organisms/SideMenuOrder";
import ContainerTemplate from "@shared/ui/templates/ContainerTemplate";
import { Box, Stack } from "@mui/material";
import { TOrderTemplate } from "./OrderTemplate.types";

const OrderTemplate: React.FC<TOrderTemplate> = (props) => {
  const { children, seoProps = {} } = props;

  return (
    <ContainerTemplate seoProps={seoProps}>
      <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
        <Box>
          <SideMenuOrder />
        </Box>

        <Box sx={{ width: "100%" }}>{children}</Box>
      </Stack>
    </ContainerTemplate>
  );
};

export default OrderTemplate;
