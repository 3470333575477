import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    "&.MuiFormControlLabel-root": {
      margin: "0px",
      width: "fit-content"
    },
    ".MuiTypography-root": {
      fontSize: "0.875rem",
      marginLeft: "8px"
    },
    ".MuiCheckbox-root": {
      padding: "0px"
    },
    ".Mui-checked": {
      ".MuiBox-root": {
        padding: "2px",
        color: theme.palette.text.common.white,
        backgroundColor: theme.palette.turquoise.light,
        borderColor: theme.palette.border.turquoise.light
      }
    }
  },
  checkboxIcon: {
    border: `1px solid ${theme.palette.border.grey.main}`,
    height: "20px",
    width: "20px",
    ".MuiSvgIcon-root": {
      fontSize: "inherit !important"
    }
  }
}));

export default useStyles;
