import React from "react";
import MUITypography from "@mui/material/Typography";

const Typography: React.FC<any> = (props) => {
  const { children, ...rest } = props;

  return <MUITypography {...rest}>{children}</MUITypography>;
};

export default Typography;
