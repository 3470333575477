import React, { useState } from "react";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";

const CustomRating: React.FC<any> = (props) => {
  const { error, helperText, ...ratingProps } = props;
  const { value } = ratingProps;
  const [hover, setHover] = useState(-1);

  const labels: { [index: string]: string } = {
    1: "1.0",
    2: "2.0",
    3: "3.0",
    4: "4.0",
    5: "5.0"
  };

  return (
    <FormControl fullWidth error={error}>
      <Box
        sx={{
          width: 200,
          display: "flex",
          alignItems: "center"
        }}
      >
        <Rating
          {...ratingProps}
          precision={1}
          onChangeActive={(event, newHover) => {
            setHover(newHover);
          }}
          emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
        />

        <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>
      </Box>

      <FormHelperText id="my-helper-text">{helperText}</FormHelperText>
    </FormControl>
  );
};

export default CustomRating;
