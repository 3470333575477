import * as React from "react";
import Button from "@shared/ui/atoms/Button";
import { useDispatch } from "react-redux";
import { showRegisterDialog } from "@store/dialogSlice";
import { Trans } from "react-i18next";

const ButtonSignUp: React.FC = () => {
  const dispatch = useDispatch();

  const openDialog = () => {
    dispatch(showRegisterDialog());
  };

  return (
    <Button variant="contained" onClick={() => openDialog()}>
      <Trans i18nKey="ui.menu.register" />
    </Button>
  );
};

export default ButtonSignUp;
