import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

const useLoginStyles = makeStyles()((theme: Theme) => ({
  inputIcon: {
    "&.MuiSvgIcon-root": {
      marginRight: theme.spacing(2),
      fontSize: "1rem"
    }
  },
  linkToLogin: {
    textAlign: "center"
  }
}));

export default useLoginStyles;
