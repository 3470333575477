import React from "react";
import { Box, FormControlLabel } from "@mui/material";
import MUICheckbox from "@mui/material/Checkbox";
import useStyles from "./Checkbox.styles";
import CheckMarkIcon from "../icons/CheckMarkIcon";

const Checkbox: React.FC<any> = (props) => {
  const { label, onChange, name, className, checked } = props;
  const { classes, cx } = useStyles();

  return (
    <Box>
      <FormControlLabel
        sx={{ alignItems: "flex-start" }}
        control={
          <MUICheckbox
            className={className}
            icon={<Box className={classes.checkboxIcon} />}
            checkedIcon={
              <Box
                className={classes.checkboxIcon}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <CheckMarkIcon />
              </Box>
            }
            onChange={onChange}
            name={name}
            checked={checked}
          />
        }
        label={label}
        className={cx(classes.root, className)}
      />
    </Box>
  );
};

export default Checkbox;
