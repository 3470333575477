import React, { useEffect, useState } from "react";
import { Box, Container } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentUser, selectAccessToken } from "@store/authSlice";
import { useLazyGetAccountQuery } from "@api/TranstubeCore/accountApi";
import LinearDeterminate from "@shared/ui/molecules/LinearDeterminate/LinearDeterminate";
import AlertManager from "../organisms/AlertManager/AlertManager";
import DefaultTemplate from "./DefaultTemplate";
import { TContainerTemplate } from "./ContainerTemplate.types";

const ContainerTemplate: React.FC<TContainerTemplate> = (props) => {
  const { children, seoProps = {} } = props;
  const [getAccount] = useLazyGetAccountQuery();
  const token = useSelector(selectAccessToken);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (token === null) return;

    setLoading(true);

    (async () => {
      await getAccount({})
        .unwrap()
        .then((payload) => {
          dispatch(setCurrentUser(payload.data));
        })
        .finally(() => {
          setLoading(false);
        });
    })();
  }, []);

  if (loading) return <LinearDeterminate />;

  return (
    <DefaultTemplate seoProps={seoProps}>
      <Box pt={8} pb={8}>
        <Container maxWidth="lg">
          <AlertManager />
          {children}
        </Container>
      </Box>
    </DefaultTemplate>
  );
};

export default ContainerTemplate;
