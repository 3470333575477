import { createSlice } from "@reduxjs/toolkit";

const snackbarSlice = createSlice({
  name: "snackbar",
  initialState: {
    open: true
  },
  reducers: {
    closeSnackbar: (state) => {
      return {
        ...state,
        open: false
      };
    }
  }
});

export const { closeSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;

export const selectSnackbar = (state: any) => state.snackbar.open;
