import * as React from "react";
import {
  DatePicker as MUIXDatePicker,
  DatePickerToolbar
} from "@mui/x-date-pickers/DatePicker";
import TextField from "@shared/ui/atoms/TextField";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import dayjs from "dayjs";
import useStyles from "./DatePicker.styles";

const WrappedTextField = React.forwardRef(
  (
    wrappedTextFieldProps: any,
    wrappedTextFieldRef: React.Ref<HTMLDivElement>
  ) => {
    const { sx, ...restWrappedTextFieldProps } = wrappedTextFieldProps;

    return (
      <TextField
        {...restWrappedTextFieldProps}
        inputRef={wrappedTextFieldRef}
      />
    );
  }
);

WrappedTextField.displayName = "WrappedTextField";

const DatePicker = React.forwardRef(
  (props: any, ref: React.Ref<HTMLDivElement>) => {
    const { error, helperText, ...restProps } = props;
    const { classes } = useStyles();

    return (
      <MUIXDatePicker
        slots={{
          textField: WrappedTextField,
          openPickerIcon: () => (
            <EditCalendarIcon className={classes.openPickerIcon} />
          ),
          toolbar: DatePickerToolbar
        }}
        slotProps={{
          textField: {
            error,
            helperText
          },
          toolbar: {
            className: classes.toolbar
          },
          popper: {
            className: classes.popper
          },
          mobilePaper: {
            className: classes.mobilePaper
          }
        }}
        minDate={dayjs("2000-01-01")}
        {...restProps}
        ref={ref}
      />
    );
  }
);

DatePicker.displayName = "DatePicker";

export default DatePicker;
