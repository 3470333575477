import React from "react";
import Alert from "@shared/ui/atoms/Alert/Alert";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "@store/authSlice";

const AlertManager: React.FC = () => {
  const currentUser = useSelector(selectCurrentUser);

  const displayAlertManager = () => {
    if (currentUser === null || currentUser.meta.is_confirmed === true) {
      return <></>;
    }

    return (
      <Box mb={2}>
        <Alert severity="warning">
          Zweryfikuj swój adres e-mail. Bez tego nie ma możliwości by inni
          użytkownicy widzieli Twoje wystawione oferty na stronie. Ta funkcja ma
          służyć po to, aby na stronę nie trafiały niechciane oferty.
        </Alert>
      </Box>
    );
  };

  return <>{displayAlertManager()}</>;
};

export default AlertManager;
