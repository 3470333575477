import api from "./api";
import { TGetPublicDiscussions } from "./discussionsApi.types";
import { TDiscussion, TResource } from "./types";

export const discussionsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPublicDiscussions: builder.query<TGetPublicDiscussions, any>({
      query: ({ params }) => ({
        url: "/public/discussions",
        params: {
          include: ["user", "messages", "messages.user"].join(","),
          sort: ["created_at", "id"].join(","),
          ...params
        }
      }),
      providesTags: ["Discussion"]
    }),
    createDiscussion: builder.mutation<TResource<TDiscussion>, any>({
      query: ({ body }) => ({
        url: "/discussions",
        method: "POST",
        body: { ...body }
      }),
      invalidatesTags: ["Discussion"]
    })
  })
});

export const { useGetPublicDiscussionsQuery, useCreateDiscussionMutation } =
  discussionsApi;
