import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setLocale } from "yup";

const Localize = (props: any) => {
  const { children } = props;
  const { i18n } = useTranslation();

  setLocale({
    mixed: {
      default: i18n.t("yup.validations.mixed.default"),
      required: i18n.t("yup.validations.mixed.required"),
      oneOf: i18n.t("yup.validations.mixed.oneOf"),
      notOneOf: i18n.t("yup.validations.mixed.notOneOf"),
      defined: i18n.t("yup.validations.mixed.defined"),
      notType: i18n.t("yup.validations.mixed.notType")
    },
    string: {
      length: i18n.t("yup.validations.string.length"),
      min: i18n.t("yup.validations.string.min"),
      max: i18n.t("yup.validations.string.max"),
      matches: i18n.t("yup.validations.string.matches"),
      email: i18n.t("yup.validations.string.email"),
      url: i18n.t("yup.validations.string.url"),
      uuid: i18n.t("yup.validations.string.uuid"),
      trim: i18n.t("yup.validations.string.trim"),
      lowercase: i18n.t("yup.validations.string.lowercase"),
      uppercase: i18n.t("yup.validations.string.uppercase")
    },
    number: {
      min: i18n.t("yup.validations.number.min"),
      max: i18n.t("yup.validations.number.max"),
      lessThan: i18n.t("yup.validations.number.lessThan"),
      moreThan: i18n.t("yup.validations.number.moreThan"),
      positive: i18n.t("yup.validations.number.positive"),
      negative: i18n.t("yup.validations.number.negative"),
      integer: i18n.t("yup.validations.number.integer")
    },
    date: {
      min: i18n.t("yup.validations.date.min"),
      max: i18n.t("yup.validations.date.max")
    },
    boolean: {
      isValue: i18n.t("yup.validations.boolean.isValue")
    },
    object: {
      noUnknown: i18n.t("yup.validations.object.noUnknown")
    },
    array: {
      min: i18n.t("yup.validations.array.min"),
      max: i18n.t("yup.validations.array.max"),
      length: i18n.t("yup.validations.array.length")
    }
  });

  return children;
};

export default withRouter(Localize);
