import React from "react";
import Coffee from "@assets/images/icons/coffee.svg";
import SVG from "react-inlinesvg";
import useColorFromPalette from "@shared/hooks/useColorFromPalette";

const CoffeeIcon: React.FC<any> = (props) => {
  const { color = "turquoise.main", ...iconProps } = props;
  const colorValue = useColorFromPalette(color);

  return <SVG src={Coffee} color={colorValue} {...iconProps} />;
};

export default CoffeeIcon;
