import React, { Fragment } from "react";
import Autocomplete from "@shared/ui/atoms/Autocomplete";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import { useLazyGetSuggestionsQuery } from "@api/TranstubeCore/mapApi";
import Typography from "@shared/ui/atoms/Typography";
import { LocationAutocompleteProps } from "./LocationAutocomplete.types";

const LocationAutocomplete: React.FC<LocationAutocompleteProps> = (props) => {
  const {
    placeholder: propPlaceholder,
    onChange: propOnChange = () => {},
    onInputChange: propOnInputChange = () => {},
    value: propValue = "",
    inputProps: propInputProps,
    error: propError,
    helperText: propHelperText
  } = props;

  const [getSuggestions] = useLazyGetSuggestionsQuery();

  const fetchOptions = async (value: any) => {
    const getSuggestionsParams = {
      params: {
        query: value
      }
    };

    const result = await getSuggestions(getSuggestionsParams)
      .unwrap()
      .then((payload) => {
        return payload.data;
      })
      .catch(() => {
        return [];
      });

    return result;
  };

  const hasDivider = (renderOptionProps: any) => {
    return renderOptionProps["data-option-index"] > 0;
  };

  return (
    <>
      <Autocomplete
        value={propValue}
        error={propError}
        helperText={propHelperText}
        placeholder={propPlaceholder}
        fetchOptions={fetchOptions}
        getOptionLabel={(option: any) => option?.address || ""}
        onChange={propOnChange}
        onInputChange={propOnInputChange}
        inputProps={propInputProps}
        renderOption={(renderOptionProps: any, option: any) => {
          return (
            <Fragment key={renderOptionProps["data-option-index"]}>
              {hasDivider(props) && <Divider component="li" />}

              <Box component="li" {...renderOptionProps}>
                <div>
                  <Typography variant="body2" color="text.turquoise.dark">
                    <strong>{option.mainText}</strong>
                  </Typography>

                  <Typography variant="body2" color="text.grey.main">
                    {option.secondaryText}
                  </Typography>
                </div>
              </Box>
            </Fragment>
          );
        }}
      />
    </>
  );
};

export default LocationAutocomplete;
