import * as yup from "yup";

const validationSchema = () => {
  return yup.object({
    coffeeOption: yup.string().required(),
    coffeePrice: yup.number().when("coffeeOption", {
      is: "addMoreCoffee",
      then: (schema) =>
        schema.positive().integer().required().moreThan(0).lessThan(10000000)
    })
  });
};

export default validationSchema;
