import { createTheme } from "@mui/material/styles";
import "./base.types";
import palette from "./palette";
import typography from "./typography";

const theme = createTheme({
  typography,
  palette
});

export default createTheme(theme, {
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: "display1" },
          style: {
            color: theme.palette.text.turquoise.dark
          }
        },
        {
          props: { variant: "display2" },
          style: {
            color: theme.palette.text.turquoise.dark
          }
        },
        {
          props: { variant: "h1" },
          style: {
            color: theme.palette.text.turquoise.dark
          }
        },
        {
          props: { variant: "h2" },
          style: {
            color: theme.palette.text.turquoise.dark
          }
        },
        {
          props: { variant: "h3" },
          style: {
            color: theme.palette.text.turquoise.dark
          }
        },
        {
          props: { variant: "h4" },
          style: {
            color: theme.palette.text.turquoise.dark
          }
        },
        {
          props: { variant: "h5" },
          style: {
            color: theme.palette.text.turquoise.dark
          }
        },
        {
          props: { variant: "h6" },
          style: {
            color: theme.palette.text.turquoise.dark
          }
        },
        {
          props: { variant: "subtitle1" },
          style: {
            color: theme.palette.text.turquoise.dark
          }
        },
        {
          props: { variant: "subtitle2" },
          style: {
            color: theme.palette.text.turquoise.dark
          }
        },
        {
          props: { variant: "body1" },
          style: {
            color: theme.palette.text.grey.main
          }
        },
        {
          props: { variant: "body2" },
          style: {
            color: theme.palette.text.grey.main
          }
        },
        {
          props: { variant: "body3" },
          style: {
            color: theme.palette.text.grey.main
          }
        },
        {
          props: { variant: "button" },
          style: {
            color: theme.palette.text.grey.main
          }
        },
        {
          props: { variant: "caption" },
          style: {
            color: theme.palette.text.grey.main
          }
        },
        {
          props: { variant: "overline" },
          style: {
            color: theme.palette.text.grey.main
          }
        },
        {
          props: { component: "h1" },
          style: {
            color: theme.palette.text.common.black
          }
        }
      ],
      defaultProps: {
        variantMapping: {
          display1: "h1",
          display2: "h2",
          subtitle1: "p",
          subtitle2: "p",
          body3: "p"
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          "& .MuiTableCell-root": {
            color: theme.palette.text.turquoise.dark
          }
        }
      }
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          "& .MuiTableCell-root": {
            color: theme.palette.text.grey.main
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          "&::placeholder": {
            fontSize: "0.875rem",
            color: theme.palette.text.grey.main,
            opacity: 0.7
          }
        },
        root: {
          fontSize: "0.875rem",
          borderRadius: "0",
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.border.grey.main
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.border.grey.main
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.border.turquoise.main
          },
          "&.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.border.red.main
          }
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: "0.875rem"
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "0.875rem"
        }
      }
    },
    MuiAppBar: {
      defaultProps: {
        style: {
          boxShadow: "none",
          borderBottomStyle: "solid",
          borderBottomWidth: "0px",
          borderColor: "#ddd",
          backgroundColor: theme.palette.background.appBar
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "0",
          boxShadow: "none"
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "0",
          boxShadow: "none",
          fontSize: "0.875rem",
          ":hover": {
            boxShadow: "none"
          },
          ":active": {
            boxShadow: "none"
          }
        }
      },
      variants: [
        {
          props: { variant: "contained", color: "primary" },
          style: {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            ":hover": {
              backgroundColor: theme.palette.primary.main
            }
          }
        },
        {
          props: { variant: "contained", color: "secondary" },
          style: {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.secondary.main,
            ":hover": {
              backgroundColor: theme.palette.secondary.main
            }
          }
        },
        {
          props: { variant: "outlined", color: "secondary" },
          style: {
            border: `1px solid ${theme.palette.secondary.main}`
          }
        }
      ]
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            ".MuiSvgIcon-root": {
              color: "#dadada"
            }
          }
        }
      }
    },
    MuiSvgIcon: {
      defaultProps: {
        color: "turquoise.light"
      },
      variants: [
        {
          props: { color: "turquoise.light" },
          style: {
            color: theme.palette.turquoise.light
          }
        }
      ]
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          ".MuiSvgIcon-root": {
            color: "inherit"
          }
        }
      }
    },
    MuiRating: {
      styleOverrides: {
        root: {
          ".MuiSvgIcon-root": {
            color: "inherit"
          }
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        light: {
          borderColor: theme.palette.border.grey.light
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        icon: {
          ".MuiSvgIcon-root": {
            color: "inherit"
          }
        }
      }
    }
  }
});
