import React from "react";
import MUILink from "@mui/material/Link";
import useStyles from "./Link.styles";

const Link: React.FC<any> = (props) => {
  const { children, className, hoverColor, ...linkProps } = props;
  const { classes, cx } = useStyles(props);

  return (
    <MUILink
      className={cx(classes.root, className)}
      component="span"
      {...linkProps}
    >
      {children}
    </MUILink>
  );
};

export default Link;
