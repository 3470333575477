import React from "react";
import { Box } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import useStyles from "./RadioColorPicker.styles";

const RadioColorPicker: React.FC<any> = (props) => {
  const { bgColor, checked, onClick } = props;
  const { classes } = useStyles();

  if (checked) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={classes.root}
        sx={{
          bgcolor: bgColor,
          width: 30,
          height: 30,
          borderRadius: "2px"
        }}
      >
        <CheckIcon sx={{ width: "15px", color: "#ffffff" }} />
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        bgcolor: bgColor,
        width: 30,
        height: 30,
        cursor: "pointer",
        borderRadius: "2px"
      }}
      onClick={onClick}
    />
  );
};

export default RadioColorPicker;
