import React from "react";
import CheckMark from "@assets/images/icons/check_mark.svg";
import SVG from "react-inlinesvg";
import useColorFromPalette from "@shared/hooks/useColorFromPalette";

const CheckMarkIcon: React.FC<any> = (props) => {
  const { color, height, width, ...iconProps } = props;
  const colorValue = useColorFromPalette(color);

  return (
    <SVG
      src={CheckMark}
      color={colorValue}
      {...iconProps}
      height={height}
      width={width}
    />
  );
};

export default CheckMarkIcon;
