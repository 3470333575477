import React from "react";
import Box from "@mui/material/Box";
import UserPanelTemplate from "@shared/ui/templates/UserPanelTemplate";
import Tab from "@mui/material/Tab";
import { useGetTransportOrdersQuery } from "@api/TranstubeCore/transportOrdersApi";
import { useHistory } from "react-router-dom";
import routes from "@routes/routes";
import TabContext from "@mui/lab/TabContext";
import { TabList, TabPanel } from "@mui/lab";
import TransportOrderList from "@shared/ui/organisms/TransportOrderList/TransportOrderList";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "@store/authSlice";
import LinearDeterminate from "@shared/ui/molecules/LinearDeterminate/LinearDeterminate";

const TransportOrders: React.FC<any> = (props) => {
  const { status } = props;
  const currentUser = useSelector(selectCurrentUser);

  const { data: transportOrdersResult = { data: undefined } } =
    useGetTransportOrdersQuery({
      params: {
        filter: {
          auction_status_eq: status === "active" ? "opened" : "closed",
          auction_user_id_eq: currentUser.id
        }
      }
    });

  const history = useHistory();
  const { data: transportOrders } = transportOrdersResult;

  if (!transportOrders) return <LinearDeterminate />;

  const inactiveOrders = () => {
    return history.push(routes.root.account.transportOrders.inactive());
  };

  const activeOrders = () => {
    return history.push(routes.root.account.transportOrders());
  };

  return (
    <UserPanelTemplate
      seoProps={{
        title: "Moje zlecenia"
      }}
    >
      <Box sx={{ width: "100%" }}>
        <TabContext value={status}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList aria-label="lab API tabs example">
              <Tab
                label="Aktywne"
                value="active"
                onClick={() => activeOrders()}
              />
              <Tab
                label="Nieaktywne"
                value="inactive"
                onClick={() => inactiveOrders()}
              />
            </TabList>
          </Box>
          <TabPanel value={status}>
            <TransportOrderList transportOrders={transportOrders} />
          </TabPanel>
        </TabContext>
      </Box>
    </UserPanelTemplate>
  );
};

export default TransportOrders;
