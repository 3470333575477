const getFilterParams = (values: any = {}) => {
  const params: { [index: string]: any } = {
    filter: {
      auction_status_eq: "opened",
      auction_offer_preferences_is_user_company_eq: "",
      auction_offer_preferences_is_user_private_person_eq: ""
    },
    search: {}
  };

  if (
    values.preferences.privatePerson === true &&
    values.preferences.company === false
  ) {
    params.filter.auction_offer_preferences_is_user_private_person_eq =
      values.preferences.privatePerson;
  }

  if (
    values.preferences.company === true &&
    values.preferences.privatePerson === false
  ) {
    params.filter.auction_offer_preferences_is_user_company_eq =
      values.preferences.company;
  }

  if (
    values.fromLocation?.latitude &&
    values.fromLocation?.longitude &&
    values.distance
  ) {
    params.search.from_location_coordinates = {};

    params.search.from_location_coordinates.latitude =
      values.fromLocation.latitude;
    params.search.from_location_coordinates.longitude =
      values.fromLocation.longitude;
    params.search.from_location_coordinates.distance = values.distance;
  }

  if (
    values.toLocation?.latitude &&
    values.toLocation?.longitude &&
    values.distance
  ) {
    params.search.to_location_coordinates = {};

    params.search.to_location_coordinates.latitude = values.toLocation.latitude;
    params.search.to_location_coordinates.longitude =
      values.toLocation.longitude;
    params.search.to_location_coordinates.distance = values.distance;
  }

  if (values.startOn !== undefined && values.startOn !== null) {
    params.search.start_on = values.startOn.format("L");
  }

  if (values.endOn !== undefined && values.endOn !== null) {
    params.search.end_on = values.endOn.format("L");
  }

  return params;
};

export default getFilterParams;
