import api from "./api";
import { TGetPublicMessages } from "./messagesApi.types";
import { TMessage, TResource } from "./types";

export const messagesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPublicMessages: builder.query<TGetPublicMessages, any>({
      query: ({ params }) => ({
        url: "/public/messages",
        params: {
          include: "user",
          sort: ["created_at", "id"].join(","),
          ...params
        }
      }),
      providesTags: ["Message"]
    }),
    createMessage: builder.mutation<TResource<TMessage>, any>({
      query: ({ body }) => ({
        url: "/messages",
        method: "POST",
        body: { ...body }
      }),
      invalidatesTags: ["Message"]
    })
  })
});

export const { useGetPublicMessagesQuery, useCreateMessageMutation } =
  messagesApi;
