import api from "./api";
import {
  TGetPurchaseOrder,
  TGetPurchaseOrders
} from "./purchaseOrdersApi.types";

export const purchaseOrdersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPurchaseOrders: builder.query<TGetPurchaseOrders, any>({
      query: ({ params }) => ({
        url: "/purchase_orders",
        params: {
          include: [
            "purchase_order_line_items",
            "purchase_order_line_items.productable"
          ].join(","),
          sort: ["-created_at", "id"].join(","),
          ...params
        }
      }),
      providesTags: ["PurchaseOrder"]
    }),
    getPurchaseOrder: builder.query<TGetPurchaseOrder, any>({
      query: ({ id }) => ({
        url: `/purchase_orders/${id}`,
        params: {
          include: [
            "user",
            "purchase_order_line_items",
            "purchase_order_line_items.productable",
            "purchase_order_line_items.productable.offer",
            "purchase_order_line_items.productable.offer.user"
          ].join(",")
        }
      }),
      providesTags: ["PurchaseOrder"]
    })
  })
});

export const { useGetPurchaseOrdersQuery, useGetPurchaseOrderQuery } =
  purchaseOrdersApi;
