import React from "react";
import SideMenuUser from "@shared/ui/organisms/SideMenuUser";
import ContainerTemplate from "@shared/ui/templates/ContainerTemplate";
import { Box, Stack } from "@mui/material";
import { TUserPanelTemplate } from "./UserPanelTemplate.types";

const UserPanelTemplate: React.FC<TUserPanelTemplate> = (props) => {
  const { children, seoProps = {} } = props;

  return (
    <ContainerTemplate seoProps={{ ...seoProps, robots: "follow, noindex" }}>
      <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
        <Box>
          <SideMenuUser />
        </Box>

        <Box sx={{ width: "100%" }}>{children}</Box>
      </Stack>
    </ContainerTemplate>
  );
};

export default UserPanelTemplate;
