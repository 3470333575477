import React from "react";
import Coins from "@assets/images/icons/coins.svg";
import SVG from "react-inlinesvg";
import useColorFromPalette from "@shared/hooks/useColorFromPalette";

const PaymentIcon: React.FC<any> = (props) => {
  const { color = "turquoise.main", ...iconProps } = props;
  const colorValue = useColorFromPalette(color);

  return (
    <SVG
      src={Coins}
      width="50px"
      height="50px"
      color={colorValue}
      {...iconProps}
    />
  );
};

export default PaymentIcon;
