import { Theme } from "@mui/material/styles";
import { get } from "lodash";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<any>()((theme: Theme, { color, hoverColor }) => ({
  root: {
    cursor: "pointer",
    textDecorationColor: `inherit !important`,
    "&:hover": {
      color: get(theme.palette, hoverColor || color || "text.turquoise.light"),
      textDecorationColor: `inherit !important`
    }
  }
}));

export default useStyles;
