import React from "react";
import MUIButton from "@mui/material/Button";

const Button: React.FC<any> = (props) => {
  const { children, ...buttonProps } = props;

  return (
    <MUIButton variant="contained" size="medium" {...buttonProps}>
      {children}
    </MUIButton>
  );
};

export default Button;
