import currency from "currency.js";

const calculatePrice = (selectedValue: any, offer: any) => {
  if (selectedValue === "addTwoCoffee") {
    const commission = currency(offer.commission.grossPrice);
    const result = commission.multiply(0.1);

    return result.value;
  }

  if (selectedValue === "addThreeCoffee") {
    const commission = currency(offer.commission.grossPrice);
    const result = commission.multiply(0.25);

    return result.value;
  }

  if (selectedValue === "addNoCoffee") {
    return 0.0;
  }

  return 0.0;
};

export default calculatePrice;
