import api from "./api";
import { TGetPublicReviews } from "./reviewsApi.types";
import { TResource, TReview } from "./types";

export const reviewsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPublicReviews: builder.query<TGetPublicReviews, any>({
      query: ({ params }) => ({
        url: "/public/reviews",
        params: {
          include: ["transport_order", "user", "reviewed_user"].join(","),
          sort: ["-created_at", "id"].join(","),
          ...params
        }
      }),
      providesTags: ["PublicReview"]
    }),
    createReview: builder.mutation<TResource<TReview>, any>({
      query: ({ body }) => ({
        url: "/reviews",
        method: "POST",
        body: { ...body }
      }),
      invalidatesTags: ["Review"]
    })
  })
});

export const { useGetPublicReviewsQuery, useCreateReviewMutation } = reviewsApi;
