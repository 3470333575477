import React from "react";
import Monitor from "@assets/images/icons/monitor.svg";
import SVG from "react-inlinesvg";
import useColorFromPalette from "@shared/hooks/useColorFromPalette";

const MonitorIcon: React.FC<any> = (props) => {
  const { color = "turquoise.main", ...iconProps } = props;
  const colorValue = useColorFromPalette(color);

  return (
    <SVG
      src={Monitor}
      width="25px"
      height="25px"
      color={colorValue}
      {...iconProps}
    />
  );
};

export default MonitorIcon;
