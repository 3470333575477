import React from "react";
import Stars from "@assets/images/icons/stars.svg";
import SVG from "react-inlinesvg";
import useColorFromPalette from "@shared/hooks/useColorFromPalette";

const StarsIcon: React.FC<any> = (props) => {
  const { color = "turquoise.main", ...iconProps } = props;
  const colorValue = useColorFromPalette(color);

  return (
    <SVG
      src={Stars}
      width="50px"
      height="50px"
      {...iconProps}
      color={colorValue}
    />
  );
};

export default StarsIcon;
