import * as yup from "yup";

const validationSchema = () => {
  return yup.object({
    fromLocation: yup.object({
      id: yup.string(),
      name: yup.string(),
      latitude: yup.number(),
      longitude: yup.number()
    }),
    toLocation: yup.object({
      id: yup.string(),
      name: yup.string(),
      latitude: yup.number(),
      longitude: yup.number()
    }),
    distance: yup.number().positive(),
    startOn: yup.date().nullable(),
    endOn: yup.date().nullable(),
    preferences: yup.object({
      privatePerson: yup.boolean(),
      company: yup.boolean()
    })
  });
};

export default validationSchema;
