import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import getFilterParams from "@services/orders/getFilterParams";
import Filter from "@shared/ui/organisms/Filter";
import { useHistory } from "react-router-dom";
import ContainerTemplate from "@shared/ui/templates/ContainerTemplate";
import { useLazyGetPublicTransportOrdersQuery } from "@api/TranstubeCore/transportOrdersApi";
import TransportOrderList from "@shared/ui/organisms/TransportOrderList/TransportOrderList";
import LinearDeterminate from "@shared/ui/molecules/LinearDeterminate/LinearDeterminate";
import useQueryParams from "@shared/hooks/useQueryParams";
import { Base64 } from "js-base64";
import routes from "@routes/routes";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const List: React.FC = () => {
  const history = useHistory();
  const { filter } = useQueryParams<any>();
  const { i18n } = useTranslation();

  const initialValues = () => {
    const result: { [index: string]: any } = {
      fromLocation: {
        id: "",
        name: "",
        latitude: "",
        longitude: ""
      },
      toLocation: {
        id: "",
        name: "",
        latitude: "",
        longitude: ""
      },
      distance: "5",
      startOn: null,
      endOn: null,
      preferences: {
        privatePerson: false,
        company: false
      }
    };

    if (!filter) return result;

    try {
      const decodedFilter = JSON.parse(Base64.decode(filter));

      if (!decodedFilter) return result;

      const startOn = dayjs(decodedFilter.startOn);

      if (startOn.isValid()) {
        result.startOn = startOn;
      }

      const endOn = dayjs(decodedFilter.endOn);

      if (endOn.isValid()) {
        result.endOn = endOn;
      }

      result.preferences.privatePerson =
        decodedFilter.preferences.privatePerson === true;

      result.preferences.company = decodedFilter.preferences.company === true;
      result.distance = Number(decodedFilter.distance).toString();

      result.fromLocation = {
        id: decodedFilter.fromLocation.id.toString(),
        name: decodedFilter.fromLocation.name.toString(),
        latitude: decodedFilter.fromLocation.latitude.toString(),
        longitude: decodedFilter.fromLocation.longitude.toString()
      };

      result.toLocation = {
        id: decodedFilter.toLocation.id.toString(),
        name: decodedFilter.toLocation.name.toString(),
        latitude: decodedFilter.toLocation.latitude.toString(),
        longitude: decodedFilter.toLocation.longitude.toString()
      };
    } catch (_) {
      return result;
    }

    return result;
  };

  const [
    getPublicTransportOrders,
    { data: transportOrdersResult = { data: undefined } }
  ] = useLazyGetPublicTransportOrdersQuery();

  const { data: transportOrders } = transportOrdersResult;

  const handleSubmit = async (values: any) => {
    const encodedFilter = Base64.encodeURI(JSON.stringify(values));

    history.push(routes.root.transportOrders({}, { filter: encodedFilter }));

    await getPublicTransportOrders({ params: getFilterParams(values) });
  };

  useEffect(() => {
    if (transportOrders) return;

    getPublicTransportOrders({ params: getFilterParams(initialValues()) });
  }, []);

  if (!transportOrders) return <LinearDeterminate />;

  return (
    <ContainerTemplate
      seoProps={{
        title: i18n.t("pages.transportOrders.head.title"),
        description: i18n.t("pages.transportOrders.head.description"),
        robots: "follow, noindex"
      }}
    >
      <Filter initialValues={initialValues()} handleSubmit={handleSubmit} />

      <Grid item xs={12} pt={4} pb={4}>
        <TransportOrderList transportOrders={transportOrders} />
      </Grid>
    </ContainerTemplate>
  );
};

export default List;
