import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@shared/ui/atoms/Typography";
import handshake from "@assets/images/section/handshake.svg";
import immigration from "@assets/images/section/immigration.svg";
import no_fee from "@assets/images/section/no_fee.svg";
import piggy_bank from "@assets/images/section/piggy_bank.svg";
import reduce_cost from "@assets/images/section/reduce_cost.svg";
import { Trans } from "react-i18next";
import useStyles from "./Section.styles";

const Section: React.FC<any> = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.bacgroundSection} py={8}>
      <Container>
        <Box textAlign="center" mb={8}>
          <Typography variant="h4" component="h2">
            <Trans i18nKey="pages.home.section.title" />
          </Typography>

          <hr className={classes.hrDecoration} />
        </Box>

        <Grid container spacing={8}>
          <Grid item xs={12} md={6}>
            <Box className={classes.item} textAlign="center">
              <img src={handshake} alt="handshake" />

              <Typography variant="body1" color="text.turquoise.dark" mt={1}>
                <strong>
                  <Trans i18nKey="pages.home.section.friendly_business_partner.title" />
                </strong>
              </Typography>

              <Typography variant="body1" mt={1}>
                <Trans i18nKey="pages.home.section.friendly_business_partner.description" />
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className={classes.item} textAlign="center">
              <img src={no_fee} alt="no_fee" />

              <Typography variant="body1" color="text.turquoise.dark" mt={1}>
                <strong>
                  <Trans i18nKey="pages.home.section.no_shipping_charges.title" />
                </strong>
              </Typography>

              <Typography variant="body1" mt={1}>
                <Trans i18nKey="pages.home.section.no_shipping_charges.description" />
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box className={classes.item} textAlign="center">
              <img src={immigration} alt="immigration" />

              <Typography variant="body1" color="text.turquoise.dark" mt={1}>
                <strong>
                  <Trans i18nKey="pages.home.section.bilateral_verification.title" />
                </strong>
              </Typography>

              <Typography variant="body1" mt={1}>
                <Trans i18nKey="pages.home.section.bilateral_verification.description" />
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className={classes.item} textAlign="center">
              <img src={piggy_bank} alt="piggy_bank" />
              <Typography variant="body1" color="text.turquoise.dark" mt={1}>
                <strong>
                  <Trans i18nKey="pages.home.section.extra_earnings_option.title" />
                </strong>
              </Typography>
              <Typography variant="body1" mt={1}>
                <Trans i18nKey="pages.home.section.extra_earnings_option.description" />
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box className={classes.item} textAlign="center">
              <img src={reduce_cost} alt="reduce_cost" />

              <Typography variant="body1" color="text.turquoise.dark" mt={1}>
                <strong>
                  <Trans i18nKey="pages.home.section.we_are_cutting_travel_costs.title" />
                </strong>
              </Typography>

              <Typography variant="body1" mt={1}>
                <Trans i18nKey="pages.home.section.we_are_cutting_travel_costs.description" />
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Section;
