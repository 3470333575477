import React from "react";
import Box from "@mui/material/Box";
import Typography from "@shared/ui/atoms/Typography";
import { Stack } from "@mui/material";
import MultiplyIcon from "@shared/ui/atoms/icons/MultiplyIcon/MultiplyIcon";
import OrdersIcon from "@shared/ui/atoms/icons/OrdersIcon";
import useStyles from "./OthersTransportOrderLineItem.styles";
import Panel from "../Panel";

const OthersTransportOrderLineItem: React.FC<any> = (props) => {
  const { transportOrderLineItem, sx } = props;
  const { classes } = useStyles();

  return (
    <>
      <Panel sx={{ ...sx, display: { xs: "none", sm: "none", md: "block" } }}>
        <Stack spacing={2} direction="row" alignItems="center">
          <Box
            className={classes.box}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <OrdersIcon className="icon" />
          </Box>
          <Box display="flex">
            <Box display="inline">
              <Stack spacing={1}>
                <Box>
                  <Typography variant="body2" color="text.turquoise.dark">
                    <strong>Wymiary</strong>
                  </Typography>
                </Box>
                <Box display="flex">
                  <Box>
                    <Stack spacing={2} direction="row" alignItems="center">
                      <Stack spacing={0.5} direction="row">
                        <Typography variant="body2" color="text.grey.main">
                          {transportOrderLineItem?.dimensionX}
                        </Typography>
                        <Typography variant="body2" color="text.grey.main">
                          cm
                        </Typography>
                      </Stack>

                      <MultiplyIcon />
                      <Stack spacing={0.5} direction="row">
                        <Typography variant="body2" color="text.grey.main">
                          {transportOrderLineItem?.dimensionY}
                        </Typography>
                        <Typography variant="body2" color="text.grey.main">
                          cm
                        </Typography>
                      </Stack>
                      <MultiplyIcon />
                      <Stack spacing={0.5} direction="row">
                        <Typography variant="body2" color="text.grey.main">
                          {transportOrderLineItem?.dimensionZ}
                        </Typography>
                        <Typography variant="body2" color="text.grey.main">
                          cm
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>
                </Box>
              </Stack>
            </Box>

            <Box display="inline" ml={5}>
              <Stack spacing={1} direction="column">
                <Typography variant="body2" color="text.turquoise.dark">
                  <strong>Waga (1 szt.)</strong>
                </Typography>

                <Stack spacing={0.5} direction="row">
                  <Typography variant="body2" color="text.grey.main">
                    {transportOrderLineItem?.weight}
                  </Typography>
                  <Typography variant="body2" color="text.grey.main">
                    kg
                  </Typography>
                </Stack>
              </Stack>
            </Box>
            <Box display="inline" ml={5}>
              <Stack spacing={1} direction="column">
                <Typography variant="body2" color="text.turquoise.dark">
                  <strong>Ilość</strong>
                </Typography>

                <Stack spacing={0.5} direction="row">
                  <Typography variant="body2" color="text.grey.main">
                    {transportOrderLineItem?.quantity}
                  </Typography>
                  <Typography variant="body2" color="text.grey.main">
                    szt.
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Box>
        </Stack>
      </Panel>

      <Panel sx={{ ...sx, display: { xs: "block", sm: "block", md: "none" } }}>
        <Stack spacing={2}>
          <Box
            className={classes.box}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <OrdersIcon className="icon" />
          </Box>

          <Box display="inline">
            <Stack spacing={1}>
              <Box>
                <Typography variant="body2" color="text.turquoise.dark">
                  <strong>Wymiary</strong>
                </Typography>
              </Box>
              <Box display="flex">
                <Box>
                  <Stack spacing={2} direction="row" alignItems="center">
                    <Stack spacing={0.5} direction="row">
                      <Typography variant="body2" color="text.grey.main">
                        {transportOrderLineItem?.dimensionX}
                      </Typography>
                      <Typography variant="body2" color="text.grey.main">
                        cm
                      </Typography>
                    </Stack>

                    <MultiplyIcon />
                    <Stack spacing={0.5} direction="row">
                      <Typography variant="body2" color="text.grey.main">
                        {transportOrderLineItem?.dimensionY}
                      </Typography>
                      <Typography variant="body2" color="text.grey.main">
                        cm
                      </Typography>
                    </Stack>
                    <MultiplyIcon />
                    <Stack spacing={0.5} direction="row">
                      <Typography variant="body2" color="text.grey.main">
                        {transportOrderLineItem?.dimensionZ}
                      </Typography>
                      <Typography variant="body2" color="text.grey.main">
                        cm
                      </Typography>
                    </Stack>
                  </Stack>
                </Box>
              </Box>
            </Stack>
          </Box>

          <Box display="inline">
            <Stack spacing={1} direction="column">
              <Typography variant="body2" color="text.turquoise.dark">
                <strong>Waga (1 szt.)</strong>
              </Typography>

              <Stack spacing={0.5} direction="row">
                <Typography variant="body2" color="text.grey.main">
                  {transportOrderLineItem?.weight}
                </Typography>
                <Typography variant="body2" color="text.grey.main">
                  kg
                </Typography>
              </Stack>
            </Stack>
          </Box>

          <Box display="inline">
            <Stack spacing={1} direction="column">
              <Typography variant="body2" color="text.turquoise.dark">
                <strong>Ilość</strong>
              </Typography>

              <Stack spacing={0.5} direction="row">
                <Typography variant="body2" color="text.grey.main">
                  {transportOrderLineItem?.quantity}
                </Typography>
                <Typography variant="body2" color="text.grey.main">
                  szt.
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Panel>
    </>
  );
};

export default OthersTransportOrderLineItem;
