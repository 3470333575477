import api from "./api";
import { TCompany, TResource } from "./types";

export const companiesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    syncCompany: builder.mutation<TResource<TCompany>, any>({
      query: ({ body }) => ({
        url: "/companies/sync",
        method: "POST",
        body: { ...body }
      }),
      invalidatesTags: ["Company"]
    })
  })
});

export const { useSyncCompanyMutation } = companiesApi;
