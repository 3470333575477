import { Box, Grid, Stack } from "@mui/material";
import Typography from "@shared/ui/atoms/Typography";
import Panel from "@shared/ui/molecules/Panel";
import ContainerTemplate from "@shared/ui/templates/ContainerTemplate";
import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTranslation } from "react-i18next";

const ServiceRates: React.FC<any> = () => {
  const { i18n } = useTranslation();

  return (
    <ContainerTemplate
      seoProps={{
        title: i18n.t("pages.serviceRates.head.title"),
        description: i18n.t("pages.serviceRates.head.description")
      }}
    >
      <Grid xs={12} sm={12} md={12}>
        <Panel>
          <Typography variant="h5" component="h1">
            Cennik
          </Typography>

          <Stack spacing={0.5}>
            <Box pt={2}>
              <Typography variant="body2">
                Korzystanie z usług przez Przewoźnika jest całkowicie bezpłatne.
                Koszt prowizji według poniższej tabeli opłaca Zleceniodawca.
              </Typography>
            </Box>
            <Box pt={2}>
              <TableContainer>
                <Table aria-label="caption table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Wartość oferty</TableCell>
                      <TableCell align="right">Prowizja</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        do 400 zł
                      </TableCell>
                      <TableCell align="right">8% + 5 zł netto</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        do 800 zł
                      </TableCell>
                      <TableCell align="right">6% + 14 zł netto</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        do 1600 zł
                      </TableCell>
                      <TableCell align="right">4% + 31 zł netto</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row">
                        powyżej 1600 zł
                      </TableCell>
                      <TableCell align="right">3% + 48 zł netto</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Stack>
        </Panel>
      </Grid>
    </ContainerTemplate>
  );
};

export default ServiceRates;
