const palette = {
  primary: {
    main: "#1e99c0",
    contrastText: "#fff"
  },
  secondary: {
    main: "#663d8f",
    contrastText: "#000"
  },
  turquoise: {
    lighter: "#bde8f5",
    light: "#4fc1e3",
    main: "#1e99c0"
  },
  checked: {
    main: "#209A00"
  },
  text: {
    primary: "#000",
    secondary: "#555",
    disabled: "#555",
    turquoise: {
      light: "#22aed8",
      main: "#1e99c0",
      dark: "#0e4758"
    },
    grey: {
      main: "#62787f"
    },
    common: {
      black: "#000",
      white: "#fff"
    }
  },
  background: {
    default: "#f6fafd",
    paper: "#fff",
    appBar: "#fff"
  },
  info: {
    main: "#663d8f",
    contrastText: "#fff"
  },
  action: {
    active: "#1e99c0",
    disabled: "#0000003b",
    disabledBackground: "#dadada"
  },
  border: {
    grey: {
      light: "#dae4e7",
      main: "#a3bbc2"
    },
    red: {
      main: "#d32f2f"
    },
    turquoise: {
      lighter: "#bde8f5",
      light: "#4fc1e3",
      main: "#1e99c0"
    }
  },
  divider: "#a3bbc2"
};

export default palette;
