import React from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import HelpTemplate from "@shared/ui/templates/HelpTemplate";
import Typography from "@shared/ui/atoms/Typography";
import Panel from "@shared/ui/molecules/Panel/Panel";
import { useTranslation } from "react-i18next";

const HowToGiveAnOpinionToTheCarrier = () => {
  const { i18n } = useTranslation();

  return (
    <HelpTemplate
      seoProps={{
        title: i18n.t("pages.howToGiveAnOpinionToTheCarrier.head.title")
      }}
    >
      <Grid item xs={12} sm={12} md={8} mt={3}>
        <Panel>
          <Stack spacing={2}>
            <Typography variant="h5" component="h1">
              Jak wystawić opinię przewoźnikowi?
            </Typography>

            <Box pt={1}>
              <Stack spacing={2}>
                <Typography>
                  Opinię możesz wystawić w momencie, kiedy przejazd zostanie
                  zaakceptowany, opłacony i zrealizowany. Wtedy otrzymasz link
                  do wystawienia opinii na swój e-mail .
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </Panel>
      </Grid>
    </HelpTemplate>
  );
};

export default HowToGiveAnOpinionToTheCarrier;
