import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@shared/ui/atoms/Menu";

const SplitButton = (props: any) => {
  const { children, ...restProps } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button size="small" onClick={handleClick} {...restProps}>
        ...
      </Button>

      <Menu anchorEl={anchorEl} onClose={handleClose}>
        {children}
      </Menu>
    </>
  );
};

export default SplitButton;
