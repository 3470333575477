import * as React from "react";
import { Box, Grid } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import ContainerTemplate from "@shared/ui/templates/ContainerTemplate";
import routes from "@routes/routes";
import Stack from "@mui/material/Stack";
import Link from "@shared/ui/atoms/Link";
import { showLoginDialog } from "@store/dialogSlice";
import Typography from "@shared/ui/atoms/Typography";
import ConfettiIcon from "@shared/ui/atoms/icons/ConfettiIcon";
import { Trans } from "react-i18next";
import useStyles from "./SuccessPage.styles";

const SuccessPage: React.FC<any> = () => {
  const { classes } = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const mainPage = () => {
    return history.replace(routes.root());
  };

  const openDialogLogin = () => {
    mainPage();
    dispatch(showLoginDialog());
  };

  return (
    <ContainerTemplate
      seoProps={{
        title: "Potwierdzenie zmiany hasła",
        robots: "follow, noindex"
      }}
    >
      <div className={classes.root}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={12} md={5} p={2} alignItems="center">
            <Box sx={{ backgroundColor: "white" }} textAlign="center" p={4}>
              <Box pt={2}>
                <ConfettiIcon />
              </Box>
              <Box pt={2} pb={2}>
                <Typography variant="h5" color="text.turquoise.dark">
                  <Trans i18nKey="order_page.success_page.congratulations" />
                </Typography>
              </Box>
              <Box pb={4}>
                <Typography
                  variant="body2"
                  color="text.grey.main"
                  textAlign="left"
                >
                  <Trans i18nKey="order_page.success_page.description" />
                </Typography>
              </Box>
              <Box>
                <Stack
                  spacing={1}
                  direction="row"
                  justifyContent="center"
                  pb={2}
                >
                  <Typography variant="body2" color="text.grey.main">
                    Chcesz się zalogować?
                  </Typography>

                  <Link variant="body2" onClick={openDialogLogin}>
                    Zaloguj się
                  </Link>
                </Stack>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
    </ContainerTemplate>
  );
};

export default SuccessPage;
