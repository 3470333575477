import React from "react";
import Button from "@shared/ui/atoms/Button";
import { useDispatch } from "react-redux";
import { showLoginDialog } from "@store/dialogSlice";
import { Trans } from "react-i18next";

const ButtonSignIn: React.FC = () => {
  const dispatch = useDispatch();

  const openDialog = () => {
    dispatch(showLoginDialog());
  };

  return (
    <Button variant="outlined" onClick={() => openDialog()}>
      <Trans i18nKey="ui.menu.login" />
    </Button>
  );
};

export default ButtonSignIn;
