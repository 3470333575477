import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@shared/ui/atoms/Button";
import LoadingButton from "@shared/ui/atoms/LoadingButton";
import { Trans } from "react-i18next";

const ButtonForm: React.FC<any> = ({ comeBack, buttonName, formik }) => {
  return (
    <Grid container>
      <Grid item xs={6} md={6}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start"
          }}
          pt={2}
        >
          <Button variant="outlined" onClick={comeBack}>
            <Trans i18nKey="buttons.return" />
          </Button>
        </Box>
      </Grid>
      <Grid item xs={6} md={6}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end"
          }}
          pt={2}
        >
          <LoadingButton type="submit" loading={Boolean(formik.isSubmitting)}>
            {buttonName}
          </LoadingButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ButtonForm;
