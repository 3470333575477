import React from "react";
import Link from "@shared/ui/atoms/Link";

const IODMailLink: React.FC<any> = (props) => {
  const { ...rest } = props;

  return (
    <Link
      component="a"
      underline="none"
      {...rest}
      href={`mailto:${process.env.REACT_APP_IOD_EMAIL}`}
    >
      <strong>{process.env.REACT_APP_IOD_EMAIL}</strong>
    </Link>
  );
};

export default IODMailLink;
