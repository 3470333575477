import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  root: {
    "&.MuiFormControl-root": {
      "& input::placeholder": {
        fontSize: "14px"
      }
    }
  }
}));

export default useStyles;
