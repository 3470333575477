import React from "react";
import MUIAlert from "@mui/material/Alert";
import useStyles from "./Alert.styles";

const Alert: React.FC<any> = (props) => {
  const { children, severity } = props;
  const { classes } = useStyles();

  return (
    <MUIAlert
      severity={severity}
      icon={false}
      sx={{ p: 2 }}
      className={classes.root}
    >
      {children}
    </MUIAlert>
  );
};

export default Alert;
