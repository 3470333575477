import * as yup from "yup";

const validationSchema = (i18n: any) => {
  return yup.object({
    contentReview: yup.string().required(),
    rating: yup
      .number()
      .typeError(i18n.t("yup.validations.mixed.required"))
      .required()
  });
};

export default validationSchema;
