import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  item: {
    img: {
      height: "72px"
    }
  },
  bacgroundSection: {
    backgroundColor: theme.palette.common.white
  },
  hrDecoration: {
    width: 50,
    borderColor: "#1e99c0",
    borderRadius: 10
  }
}));

export default useStyles;
