import React from "react";
import Grid from "@mui/material/Grid";
import { useParams, useHistory } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Fab from "@mui/material/Fab";
import { useGetPublicTransportOrderQuery } from "@api/TranstubeCore/transportOrdersApi";
import { useFormik } from "formik";
import { selectCurrentUser } from "@store/authSlice";
import { useSelector } from "react-redux";
import OrderTemplate from "@shared/ui/templates/OrderTemplate";
import Box from "@mui/material/Box";
import Alert from "@shared/ui/atoms/Alert";
import {
  useCancelOfferMutation,
  useAcceptOfferMutation,
  useGetPublicOffersQuery,
  useCreateOfferMutation
} from "@api/TranstubeCore/offersApi";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import LoadingButton from "@shared/ui/atoms/LoadingButton/LoadingButton";
import Stack from "@mui/material/Stack";
import routes from "@routes/routes";
import Button from "@shared/ui/atoms/Button";
import UserNameBadge from "@shared/ui/molecules/UserNameBadge/UserNameBadge";
import Typography from "@shared/ui/atoms/Typography";
import Panel from "@shared/ui/molecules/Panel/Panel";
import LinearDeterminate from "@shared/ui/molecules/LinearDeterminate/LinearDeterminate";
import NoMatch from "@pages/noMatch/NoMatch";
import { Trans } from "react-i18next";
import { TGetPublicOffers } from "@api/TranstubeCore/offersApi.types";
import { formattedCurrencyPrice } from "@shared/utils/currencyPrice";
import useStyles from "./Offers.styles";
import validationSchema from "./Offers.validationSchema";
import { TTransportOrderOffers } from "./Offers.types";

const TransportOrderOffers: React.FC<TTransportOrderOffers> = (props) => {
  const { publicTransportOrder, publicOffers, refetchPublicTransportOrder } =
    props;
  const currentUser = useSelector(selectCurrentUser);
  const { classes } = useStyles();
  const history = useHistory();
  const [acceptOffer] = useAcceptOfferMutation();
  const [createOffer] = useCreateOfferMutation();
  const [cancelOffer] = useCancelOfferMutation();
  const ALPHA_NUMERIC_DASH_REGEX = /^[0-9]+$/;

  const handleSubmit = async (values: any) => {
    const createOfferParams = {
      body: {
        data: {
          attributes: {
            auction_id: publicTransportOrder.auction.id,
            price: values.price
          }
        }
      }
    };

    await createOffer(createOfferParams).unwrap();

    await refetchPublicTransportOrder();
  };

  const handleClickDeleteOffer = async (
    offer: TGetPublicOffers["data"][number]
  ) => {
    await cancelOffer({ id: offer.id });
    await refetchPublicTransportOrder();
  };

  const formik = useFormik({
    initialValues: {
      price: Number(publicTransportOrder?.auction?.bestOffer?.price) || "10"
    },
    validationSchema: validationSchema(),
    onSubmit: handleSubmit
  });

  const displayCurency = (currencyIsoCode: any) => {
    if (publicTransportOrder) {
      if (currencyIsoCode === "EUR") {
        return "€";
      }

      if (currencyIsoCode === "PLN") {
        return "zł";
      }

      if (currencyIsoCode === "GBP") {
        return "£";
      }
    }

    return <></>;
  };

  const incNum = () => {
    const price = Number(formik.values.price);
    const add = price + 10;
    formik.setFieldValue("price", add.toString());
  };

  const decNum = () => {
    if (formik.values.price === "10") {
      formik.setFieldValue("price", "1");
    } else {
      const price = Number(formik.values.price);

      if (price - 10 > 0) {
        const subtract = price - 10;
        formik.setFieldValue("price", subtract.toString());
      } else if (price - 10 <= 0) {
        formik.setFieldValue("price", "1");
      }
    }
  };

  const handleClickAcceptOffer = async (
    offer: TGetPublicOffers["data"][number]
  ) => {
    await acceptOffer({ offerId: offer.id });
    await refetchPublicTransportOrder();
  };

  const pay = (offer: TGetPublicOffers["data"][number]) => {
    history.push(routes.root.offers.offer({ id: offer?.id }));
  };

  const offerUserIdFilter = publicOffers.find((e: any) => {
    return e?.user?.id === currentUser?.id;
  });

  const deleteIcon = (offer: TGetPublicOffers["data"][number]) => {
    if (currentUser?.id === offer?.user?.id && offer?.status === "created") {
      return (
        <Button variant="text" onClick={() => handleClickDeleteOffer(offer)}>
          Usuń
        </Button>
      );
    }

    if (
      currentUser?.id === publicTransportOrder?.auction?.user?.id &&
      publicTransportOrder?.auction?.status === "opened"
    ) {
      return (
        <Button variant="text" onClick={() => handleClickAcceptOffer(offer)}>
          Akceptuj
        </Button>
      );
    }

    if (
      currentUser?.id === publicTransportOrder?.auction?.user?.id &&
      publicTransportOrder?.auction?.status === "canceled"
    ) {
      return (
        <Button variant="text" disabled>
          <Trans i18nKey={`statuses.${offer.status}`} defaults={offer.status} />
        </Button>
      );
    }

    if (
      currentUser?.id === publicTransportOrder?.auction.user.id &&
      offer?.status === "accepted"
    ) {
      if (offer.id === publicTransportOrder?.auction?.winningOffer?.id) {
        return (
          <Button variant="text" onClick={() => pay(offer)}>
            Zapłać
          </Button>
        );
      }
    }

    if (
      currentUser?.id === publicTransportOrder?.auction.user.id &&
      offer?.status === "canceled"
    ) {
      return (
        <Button variant="text" disabled>
          <Trans i18nKey={`statuses.${offer.status}`} defaults={offer.status} />
        </Button>
      );
    }

    if (offer?.status === "completed") {
      return (
        <Button variant="text" disabled>
          <Trans i18nKey={`statuses.${offer.status}`} defaults={offer.status} />
        </Button>
      );
    }

    if (offer?.status !== "created") {
      return (
        <Button variant="text" disabled>
          <Trans i18nKey={`statuses.${offer.status}`} defaults={offer.status} />
        </Button>
      );
    }

    return <></>;
  };

  const emptyList = () => {
    if (publicOffers.length === 0) {
      return (
        <Box className={classes.boxEmptyOffers}>
          <Typography variant="body2" color="text.grey.main">
            Brak ofert
          </Typography>
        </Box>
      );
    }

    return <></>;
  };

  const listOffers = (offer: TGetPublicOffers["data"][number]) => {
    if (
      (currentUser !== null && currentUser?.id === offer?.user?.id) ||
      (currentUser?.id === publicTransportOrder.auction.user.id &&
        publicTransportOrder.auction.status === "opened")
    ) {
      return (
        <Panel
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ padding: 1 }}
        >
          <Box sx={{ width: "45%" }}>
            <UserNameBadge
              to={routes.root.users.user({ id: offer?.user?.id })}
              color={offer?.user?.avatarColor}
              displayName={offer?.user?.displayName}
              numberRatings={offer?.user?.meta?.review_count}
              averageRating={offer?.user?.meta?.average_rating}
            />
          </Box>
          <Box sx={{ width: "35%" }}>
            <Typography variant="body2" color="text.grey.main">
              {formattedCurrencyPrice(
                parseFloat(offer?.price),
                offer?.auction?.currencyIsoCode
              )}
            </Typography>
          </Box>
          <Box sx={{ width: "20%" }}>{deleteIcon(offer)}</Box>
        </Panel>
      );
    }

    if (
      currentUser?.id === publicTransportOrder.auction.user.id &&
      publicTransportOrder.auction.status === "closed"
    ) {
      if (offer.id === publicTransportOrder.auction.winningOffer?.id) {
        return (
          <Panel
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ padding: 1 }}
          >
            <Box sx={{ width: "45%" }}>
              <UserNameBadge
                to={routes.root.users.user({ id: offer?.user?.id })}
                color={offer?.user?.avatarColor}
                displayName={offer?.user?.displayName}
                numberRatings={offer?.user?.meta?.review_count}
                averageRating={offer?.user?.meta?.average_rating}
              />
            </Box>
            <Box sx={{ width: "35%" }}>
              <Typography variant="body2" color="text.grey.main">
                {formattedCurrencyPrice(
                  parseFloat(offer?.price),
                  offer?.auction?.currencyIsoCode
                )}
              </Typography>
            </Box>
            <Box sx={{ width: "20%" }}>{deleteIcon(offer)}</Box>
          </Panel>
        );
      }

      return (
        <Panel
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ padding: 1 }}
        >
          <Box sx={{ width: "45%" }}>
            <UserNameBadge
              to={routes.root.users.user({ id: offer?.user?.id })}
              color={offer?.user?.avatarColor}
              displayName={offer?.user?.displayName}
              numberRatings={offer?.user?.meta?.review_count}
              averageRating={offer?.user?.meta?.average_rating}
            />
          </Box>
          <Box sx={{ width: "35%" }}>
            <Typography variant="body2" color="text.grey.main">
              {formattedCurrencyPrice(
                parseFloat(offer?.price),
                offer?.auction?.currencyIsoCode
              )}
            </Typography>
          </Box>
          <Box sx={{ width: "20%" }}>{deleteIcon(offer)}</Box>
        </Panel>
      );
    }

    return (
      <Panel
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ padding: 1 }}
      >
        <Box sx={{ width: "45%" }}>
          <UserNameBadge
            to={routes.root.users.user({ id: offer?.user?.id })}
            color={offer?.user?.avatarColor}
            displayName={offer?.user?.displayName}
            numberRatings={offer?.user?.meta?.review_count}
            averageRating={offer?.user?.meta?.average_rating}
          />
        </Box>
        <Box sx={{ width: "35%" }}>
          <Typography variant="body2" color="text.grey.main">
            {formattedCurrencyPrice(
              parseFloat(offer?.price),
              offer?.auction?.currencyIsoCode
            )}
          </Typography>
        </Box>
        <Box sx={{ width: "20%" }}>{deleteIcon(offer)}</Box>
      </Panel>
    );
  };

  const textField = () => {
    if (
      currentUser?.id !== publicTransportOrder.auction.user.id &&
      currentUser !== null &&
      publicTransportOrder.auction.status === "opened"
    ) {
      if (offerUserIdFilter === undefined) {
        return (
          <>
            <Panel>
              <Alert severity="info">Tutaj możesz złożyć swoją ofertę.</Alert>
              <Box pl={2} pr={2} display="flex">
                <Box sx={{ flexGrow: 0.1 }} alignSelf="center">
                  <Fab
                    size="small"
                    color="secondary"
                    aria-label="add"
                    onClick={() => incNum()}
                    className={classes.fabIcon}
                  >
                    <AddIcon className={classes.iconSvgWeight} />
                  </Fab>
                </Box>

                <Box sx={{ flexGrow: 0.2 }} alignSelf="center">
                  <TextField
                    variant="standard"
                    value={formik.values.price}
                    name="price"
                    autoComplete="off"
                    onChange={(event: any) => {
                      const { value } = event.target;

                      if (
                        value !== "" &&
                        !ALPHA_NUMERIC_DASH_REGEX.test(value)
                      ) {
                        return;
                      }

                      formik.setFieldValue("price", value);
                    }}
                    error={formik.touched.price && Boolean(formik.errors.price)}
                    className={classes.textField}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "center" }
                      },
                      disableUnderline: true,
                      style: { fontSize: 25, fontWeight: 900 },
                      inputMode: "numeric"
                    }}
                    margin="normal"
                  />
                </Box>

                <Box sx={{ flexGrow: 0.1 }} pt={1} ml={2} alignSelf="center">
                  <Typography variant="h5" color="text.grey.main">
                    {displayCurency(
                      publicTransportOrder.auction.currencyIsoCode
                    )}
                  </Typography>
                </Box>
                <Box sx={{ flexGrow: 0.1 }} alignSelf="center">
                  <Fab
                    size="small"
                    color="secondary"
                    aria-label="add"
                    onClick={() => decNum()}
                    className={classes.fab}
                  >
                    <RemoveIcon />
                  </Fab>
                </Box>
              </Box>

              <LoadingButton
                variant="contained"
                type="submit"
                fullWidth
                loading={Boolean(formik.isSubmitting)}
              >
                <Trans i18nKey="order_page.passenger_transport.make_an_offer" />
              </LoadingButton>
            </Panel>
          </>
        );
      }
    }

    return <></>;
  };

  return (
    <OrderTemplate
      seoProps={{
        title: "Oferty zlecenia przewozu",
        robots: "follow, noindex"
      }}
    >
      <Stack
        direction={{ xs: "column-reverse", md: "row" }}
        spacing={2}
        alignItems="flex-start"
      >
        <Box sx={{ width: "100%" }}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={7}>
                <Stack spacing={1}>
                  {publicOffers.map((item) => (
                    <div key={item.id}>{listOffers(item)}</div>
                  ))}

                  {emptyList()}
                </Stack>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={5}
                alignItems="center"
                justifyContent="center"
              >
                <Box className={classes.bestOffer}>
                  <Box className={classes.itemBackgroundColor}>
                    <Typography variant="h4" color="text.common.white">
                      {publicTransportOrder.auction.bestOffer?.price &&
                        formattedCurrencyPrice(
                          parseFloat(
                            publicTransportOrder.auction.bestOffer.price
                          ),
                          publicTransportOrder.auction.currencyIsoCode
                        )}
                    </Typography>
                  </Box>
                  <Box className={classes.itemBackgroundColor}>
                    <Typography
                      variant="h6"
                      color="text.common.white"
                      sx={{ wordBreak: "break-word" }}
                    >
                      {
                        publicTransportOrder.auction.bestOffer?.user
                          ?.displayName
                      }
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={7} />
              <Grid item xs={12} sm={12} md={5}>
                {textField()}
              </Grid>
            </Grid>
          </form>
        </Box>
      </Stack>
    </OrderTemplate>
  );
};

const Offers: React.FC = () => {
  const { id: transportOrderId } = useParams<any>();

  const {
    data: publicTransportOrderResult = { data: undefined },
    error = {},
    refetch: refetchPublicTransportOrder
  } = useGetPublicTransportOrderQuery({ id: transportOrderId });

  const { data: publicTransportOrder } = publicTransportOrderResult;

  const { data: publicOffersResult = { data: undefined } } =
    useGetPublicOffersQuery({
      params: {
        filter: {
          auction_transport_order_id_eq: transportOrderId
        }
      }
    });

  const { data: publicOffers } = publicOffersResult;

  if (!publicTransportOrder || !publicOffers) return <LinearDeterminate />;

  if ("status" in error && error.status === 404) {
    return <NoMatch />;
  }

  return (
    <TransportOrderOffers
      publicTransportOrder={publicTransportOrder}
      publicOffers={publicOffers}
      refetchPublicTransportOrder={refetchPublicTransportOrder}
    />
  );
};

export default Offers;
