import React from "react";
import Logout from "@assets/images/icons/logout.svg";
import SVG from "react-inlinesvg";
import useColorFromPalette from "@shared/hooks/useColorFromPalette";

const LogoutIcon: React.FC<any> = (props) => {
  const { color = "turquoise.main", ...iconProps } = props;
  const colorValue = useColorFromPalette(color);

  return (
    <SVG
      src={Logout}
      width="25px"
      height="25px"
      color={colorValue}
      {...iconProps}
    />
  );
};

export default LogoutIcon;
