import { makeStyles } from "tss-react/mui";

const useSectionStyles = makeStyles()(() => ({
  box: {
    "&.MuiBox-root": {
      backgroundColor: "#ffffff",
      height: "300px"
    }
  }
}));

export default useSectionStyles;
