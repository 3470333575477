import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  root: {
    width: 30,
    height: 30,
    cursor: "pointer"
  }
}));

export default useStyles;
