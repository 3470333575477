import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import plTranslations from "@locales/pl.json";

i18next.use(initReactI18next).init({
  lng: "pl",
  debug: false,
  resources: {
    pl: {
      translations: plTranslations
    }
  },
  ns: ["translations"],
  defaultNS: "translations",
  interpolation: {
    escapeValue: false
  },
  fallbackLng: "pl"
});

export default i18next;
