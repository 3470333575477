import React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useStyles from "./Dialog.styles";

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children?: React.ReactElement<any>;
    },
    ref: React.Ref<any>
  ) => {
    return <Slide direction="down" ref={ref} {...props} />;
  }
);

Transition.displayName = "Transition";

const BaseDialog: React.FC<any> = (props) => {
  const { handleOpen, handleClose, children } = props;
  const { classes } = useStyles();

  return (
    <Dialog
      className={classes.root}
      maxWidth="xs"
      fullWidth
      TransitionComponent={Transition}
      keepMounted
      scroll="body"
      open={handleOpen}
      onClose={handleClose}
    >
      <Box sx={{ textAlign: "right" }}>
        <CloseIcon className={classes.colseIcon} onClick={handleClose} />
      </Box>

      {children}
    </Dialog>
  );
};

export default BaseDialog;
