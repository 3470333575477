import React from "react";
import Multiply from "@assets/images/icons/multiply.svg";
import SVG from "react-inlinesvg";
import useColorFromPalette from "@shared/hooks/useColorFromPalette";

const MultiplyIcon: React.FC<any> = (props) => {
  const { color = "common.black", ...iconProps } = props;
  const colorValue = useColorFromPalette(color);

  return (
    <SVG
      src={Multiply}
      height="10"
      width="10"
      color={colorValue}
      {...iconProps}
    />
  );
};

export default MultiplyIcon;
