import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  root: {
    borderRadius: "0px !important",
    border: "1px solid transparent !important",
    "&.MuiAlert-standardSuccess": {
      color: "#0a3622",
      backgroundColor: "#f2f8f5",
      borderColor: "#a3cfbb !important"
    },
    "&.MuiAlert-standardError": {
      color: "#58151c",
      backgroundColor: "#fceeef",
      borderColor: "#f1aeb5 !important"
    },
    "&.MuiAlert-standardWarning": {
      color: "#664d03",
      backgroundColor: "#fffaeb",
      borderColor: "#ffe69c !important"
    },
    "&.MuiAlert-standardInfo": {
      color: "#055160",
      backgroundColor: "#ecfafe",
      borderColor: "#9eeaf9 !important"
    },
    ".MuiAlert-message": {
      padding: 0
    }
  }
}));

export default useStyles;
