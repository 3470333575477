import React from "react";
import MUIMenu from "@mui/material/Menu";

const AvatarMenu: React.FC<any> = (props) => {
  const { children, anchorEl, handleClose } = props;

  const open = Boolean(anchorEl);

  return (
    <MUIMenu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      sx={{ display: { xs: "none", md: "block" } }}
      PaperProps={{
        elevation: 0,
        sx: {
          minWidth: "230px",
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0
          }
        }
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      {children}
    </MUIMenu>
  );
};

export default AvatarMenu;
