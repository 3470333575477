import React from "react";
import ScrollToTop from "react-scroll-to-top";
import ArrowUp from "@assets/images/icons/arrow_up_short.svg";
import SVG from "react-inlinesvg";
import useStyles from "./CustomScrollToTop.styles";

const CustomScrollToTop: React.FC<any> = (props) => {
  const { top = 100 } = props;
  const { classes } = useStyles();

  return (
    <ScrollToTop
      smooth
      component={<SVG src={ArrowUp} />}
      className={classes.root}
      top={top}
    />
  );
};

export default CustomScrollToTop;
