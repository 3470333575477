import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

const useSectionStyles = makeStyles()((theme: Theme) => ({
  box: {
    "&.MuiBox-root": {
      border: `1px solid ${theme.palette.border.grey.main}`,
      padding: theme.spacing(0.5),
      width: "5.8rem",
      height: "4.8rem"
    }
  },
  boxChecked: {
    "&.MuiBox-root": {
      border: `2px solid ${theme.palette.border.turquoise.main}`,
      padding: theme.spacing(0.5),
      width: "5.8rem",
      height: "4.8rem",
      cursor: "pointer"
    }
  }
}));

export default useSectionStyles;
