import { createRouting, segment, uuid, query } from "ts-routes";

const routes = createRouting({
  root: {
    ...segment`/pl/`,
    children: {
      transportOrders: {
        ...segment`transport-orders/`,
        children: {
          new: {
            ...segment`new/`,
            children: {
              category: segment`category/`,
              schedule: segment`schedule/`,
              details: segment`details/`,
              summary: segment`summary/`
            }
          },
          transportOrder: {
            ...segment`${uuid("id")}/`,
            children: {
              messages: segment`messages/`,
              offers: segment`offers/`
            }
          }
        }
      },
      offers: {
        ...segment`offers/`,
        children: {
          offer: segment`${uuid("id")}/`
        }
      },
      reviews: {
        ...segment`reviews/`,
        children: {
          new: {
            ...segment`new/`,
            query: {
              token: query("required")
            }
          }
        }
      },
      contact: segment`contact/`,
      termsOfService: segment`terms-of-service/`,
      privacyPolicy: segment`privacy-policy/`,
      cookiePolicy: segment`cookie-policy/`,
      serviceRates: segment`service-rates/`,
      users: {
        ...segment`users/`,
        children: {
          user: segment`${uuid("id")}/`
        }
      },
      account: {
        ...segment`account/`,
        children: {
          sessions: segment`sessions/`,
          invoices: segment`invoices/`,
          resetPassword: {
            ...segment`reset-password/`,
            query: {
              token: query("required")
            }
          },
          transportOrders: {
            ...segment`transport-orders/`,
            children: {
              inactive: segment`inactive/`
            }
          },
          confirmEmail: {
            ...segment`confirm-email/`,
            query: {
              token: query("required")
            }
          },
          offers: {
            ...segment`offers/`,
            children: {
              offer: segment`${uuid("id")}/`
            }
          },
          purchaseOrders: {
            ...segment`purchase-orders/`,
            children: {
              purchaseOrder: segment`${uuid("id")}/`
            }
          }
        }
      },
      help: {
        ...segment`help/`,
        children: {
          whatAreTheBenefitsOfTransking: segment`what-are-the-benefits-of-transking/`,
          howToCreateAccount: segment`how-to-create-account/`,
          forgotMyPassword: segment`forgot-my-password/`,
          howToDeleteAnAccount: segment`how-to-delete-an-account/`,
          howToCreateAnOrder: segment`how-to-create-an-order/`,
          howToSearchForOrders: segment`how-to-search-for-orders/`,
          howToObserveAnOrder: segment`how-to-observe-an-order/`,
          howTranskingWorks: segment`how-transking-works/`,
          howCanIPayForTheOffer: segment`how-can-i-pay-for-the-offer/`,
          whereCanISeeOffersFromCarriers: segment`where-can-i-see-offers-from-carriers/`,
          howCanIMakeAnOfferAsACarriers: segment`how-can-i-make-an-offer-as-a-carriers/`,
          howCanIContactYouRegardingTheOffer: segment`how-can-i-contact-you-regarding-the-offer/`,
          canIExchangeMyContactDetails: segment`can-i-exchange-my-contact-details/`,
          doIHaveToChooseTheBestOffer: segment`do-i-have-to-choose-the-best-offer/`,
          whatHappensAfterTheOfferIsPaidFor: segment`what-happens-after-the-offer-is-paid-for/`,
          howToGiveAnOpinionToTheCarrier: segment`how-to-give-an-opinion-to-the-carrier/`,
          whereCanIViewAllMyIssuedOrders: segment`where-can-i-view-all-my-issued-orders/`
        }
      },
      emailConfirmed: segment`email-confirmed/`,
      resetPasswordCompleted: segment`reset-password-completed/`,
      transportOrderCreated: {
        ...segment`transport-order-created/`,
        query: {
          transport_order_id: query("required")
        }
      },
      reviewCreated: {
        ...segment`review-created/`
      },
      checkoutCompleted: {
        ...segment`checkout-completed/`,
        query: {
          purchase_order_id: query("required")
        }
      }
    }
  }
});

export default routes;
