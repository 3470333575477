import React from "react";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@shared/ui/atoms/Typography";
import HomeIcon from "@shared/ui/atoms/icons/HomeIcon/HomeIcon";
import OrderIcon from "@shared/ui/atoms/icons/OrderIcon/OrderIcon";
import SearchOrderIcon from "@shared/ui/atoms/icons/SearchOrderIcon/SearchOrderIcon";
import LogInIcon from "@shared/ui/atoms/icons/LogInIcon/LogInIcon";
import AddUserIcon from "@shared/ui/atoms/icons/AddUserIcon/AddUserIcon";
import routes from "@routes/routes";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showLoginDialog, showRegisterDialog } from "@store/dialogSlice";
import CustomerIcon from "@shared/ui/atoms/icons/CustomerIcon/CustomerIcon";
import MonitorIcon from "@shared/ui/atoms/icons/MonitorIcon/MonitorIcon";
import OrdersIcon from "@shared/ui/atoms/icons/OrdersIcon/OrdersIcon";
import { selectCurrentUser, removeCredentials } from "@store/authSlice";
import UserAvatar from "@shared/ui/molecules/UserAvatar/UserAvatar";
import LogoutIcon from "@shared/ui/atoms/icons/LogoutIcon/LogoutIcon";
import { useDeleteSessionsMutation } from "@api/TranstubeCore/sessionsApi";
import { Stack } from "@mui/material";
import TransactionIcon from "@shared/ui/atoms/icons/TransactionIcon/TransactionIcon";
import OfferIcon from "@shared/ui/atoms/icons/OfferIcon";
import InvoiceIcon from "@shared/ui/atoms/icons/InvoiceIcon";
import { Trans } from "react-i18next";
import useStyles from "./ButtonAppBarCollapse.styles";

type Anchor = "right";

const ButtonAppBarCollapse: React.FC = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);

  const openDialogLogin = () => {
    dispatch(showLoginDialog());
  };

  const [deleteSession] = useDeleteSessionsMutation();

  const openDialogRegister = () => {
    dispatch(showRegisterDialog());
  };

  const handleClickDestroySession = async () => {
    if (currentUser !== null) {
      await deleteSession({
        id: currentUser.meta.current_session_id
      })
        .unwrap()
        .then(() => {
          dispatch(removeCredentials());
        });
    }
  };

  const [state, setState] = React.useState({
    right: false
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const loggedUser = () => {
    if (currentUser !== null) {
      return (
        <>
          <Box p={3}>
            <Stack spacing={1}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <UserAvatar
                  size={64}
                  color={currentUser.avatarColor}
                  to={routes.root.users.user({ id: currentUser.id })}
                  sx={{ cursor: "pointer" }}
                >
                  {currentUser.displayName}
                </UserAvatar>
              </Box>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography
                  variant="body2"
                  color="text.turquoise.dark"
                  sx={{ wordBreak: "break-word" }}
                >
                  <strong>{currentUser.name}</strong>
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography variant="body2" color="text.grey.main">
                  {currentUser.email}
                </Typography>
              </Box>
            </Stack>
          </Box>
          <Divider
            sx={{ borderStyle: "dashed", borderColor: "#dadada", mb: 1 }}
          />
          <ListItem
            onClick={() => {
              handleClickDestroySession();
            }}
            sx={{ cursor: "pointer" }}
          >
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            Wyloguj się
          </ListItem>
        </>
      );
    }

    return (
      <>
        <Divider
          sx={{ borderStyle: "dashed", borderColor: "#dadada", mb: 1 }}
        />
        <List>
          <ListItem onClick={openDialogLogin} sx={{ cursor: "pointer" }}>
            <ListItemIcon>
              <LogInIcon />
            </ListItemIcon>
            <Typography
              variant="body2"
              color="text.turquoise.dark"
              sx={{
                textDecorationLine: "none"
              }}
            >
              <Trans i18nKey="ui.menu.login" />
            </Typography>
          </ListItem>
          <ListItem onClick={openDialogRegister} sx={{ cursor: "pointer" }}>
            <ListItemIcon>
              <AddUserIcon />
            </ListItemIcon>
            <Typography
              variant="body2"
              color="text.turquoise.dark"
              sx={{
                textDecorationLine: "none"
              }}
            >
              <Trans i18nKey="ui.menu.register" />
            </Typography>
          </ListItem>
        </List>
      </>
    );
  };

  const list = (anchor: Anchor) => (
    <Box
      className={classes.boxList}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Stack spacing={1}>
        <List>
          <NavLink to={routes.root()} className={classes.navLink} exact>
            <ListItem>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <Typography
                variant="body2"
                color="text.turquoise.dark"
                sx={{
                  textDecorationLine: "none"
                }}
              >
                <Trans i18nKey="ui.menu.home" />
              </Typography>
            </ListItem>
          </NavLink>
          <NavLink
            to={routes.root.transportOrders.new.category.pattern}
            className={classes.navLink}
            exact
          >
            <ListItem>
              <ListItemIcon>
                <OrderIcon />
              </ListItemIcon>
              <Typography
                variant="body2"
                color="text.turquoise.dark"
                sx={{
                  textDecorationLine: "none"
                }}
              >
                <Trans i18nKey="ui.menu.new_order" />
              </Typography>
            </ListItem>
          </NavLink>
          <NavLink
            to={routes.root.transportOrders.pattern}
            className={classes.navLink}
            exact
          >
            <ListItem>
              <ListItemIcon>
                <SearchOrderIcon />
              </ListItemIcon>
              <Typography
                variant="body2"
                color="text.turquoise.dark"
                sx={{
                  textDecorationLine: "none"
                }}
              >
                <Trans i18nKey="ui.menu.orders" />
              </Typography>
            </ListItem>
          </NavLink>
        </List>

        {currentUser !== null && (
          <>
            <Divider sx={{ borderStyle: "dashed", borderColor: "#dadada" }} />
            <Typography
              variant="body2"
              color="text.grey.main"
              sx={{
                textDecorationLine: "none",
                pl: 2
              }}
            >
              PANEL UŻYTKOWNIKA
            </Typography>
          </>
        )}
        <List>
          {currentUser !== null && (
            <>
              <NavLink
                to={routes.root.account()}
                className={classes.navLink}
                exact
              >
                <ListItem>
                  <ListItemIcon>
                    <CustomerIcon />
                  </ListItemIcon>
                  <Typography
                    variant="body2"
                    color="text.turquoise.dark"
                    sx={{
                      textDecorationLine: "none"
                    }}
                  >
                    Moje konto
                  </Typography>
                </ListItem>
              </NavLink>
              <NavLink
                to={routes.root.account.sessions()}
                className={classes.navLink}
                exact
              >
                <ListItem>
                  <ListItemIcon>
                    <MonitorIcon />
                  </ListItemIcon>
                  <Typography
                    variant="body2"
                    color="text.turquoise.dark"
                    sx={{
                      textDecorationLine: "none"
                    }}
                  >
                    Sesje
                  </Typography>
                </ListItem>
              </NavLink>
              <NavLink
                to={routes.root.account.transportOrders()}
                className={classes.navLink}
                exact
              >
                <ListItem>
                  <ListItemIcon>
                    <OrdersIcon />
                  </ListItemIcon>
                  <Typography
                    variant="body2"
                    color="text.turquoise.dark"
                    sx={{
                      textDecorationLine: "none"
                    }}
                  >
                    Moje zlecenia
                  </Typography>
                </ListItem>
              </NavLink>
              <NavLink
                to={routes.root.account.purchaseOrders()}
                className={classes.navLink}
                exact
              >
                <ListItem>
                  <ListItemIcon>
                    <TransactionIcon />
                  </ListItemIcon>
                  <Typography
                    variant="body2"
                    color="text.turquoise.dark"
                    sx={{
                      textDecorationLine: "none"
                    }}
                  >
                    Moje zakupy
                  </Typography>
                </ListItem>
              </NavLink>
              <NavLink
                to={routes.root.account.offers()}
                className={classes.navLink}
                exact
              >
                <ListItem>
                  <ListItemIcon>
                    <OfferIcon />
                  </ListItemIcon>
                  <Typography
                    variant="body2"
                    color="text.turquoise.dark"
                    sx={{
                      textDecorationLine: "none"
                    }}
                  >
                    Moje oferty
                  </Typography>
                </ListItem>
              </NavLink>
              <NavLink
                to={routes.root.account.invoices()}
                className={classes.navLink}
                exact
              >
                <ListItem>
                  <ListItemIcon>
                    <InvoiceIcon />
                  </ListItemIcon>
                  <Typography
                    variant="body2"
                    color="text.turquoise.dark"
                    sx={{
                      textDecorationLine: "none"
                    }}
                  >
                    Moje faktury
                  </Typography>
                </ListItem>
              </NavLink>
              <Divider
                sx={{ borderStyle: "dashed", borderColor: "#dadada", mt: 1 }}
              />
            </>
          )}

          {loggedUser()}
        </List>
      </Stack>
    </Box>
  );

  return (
    <Box sx={{ display: { xs: "block", md: "none" } }}>
      {(["right"] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton onClick={toggleDrawer(anchor, true)} color="secondary">
            <MenuIcon className={classes.menuIcon} />
          </IconButton>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </Box>
  );
};

export default ButtonAppBarCollapse;
