import React from "react";
import UserPanelTemplate from "@shared/ui/templates/UserPanelTemplate";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import LoadingButton from "@shared/ui/atoms/LoadingButton";

import {
  useGetInvoicesQuery,
  useDownloadInvoiceMutation
} from "@api/TranstubeCore/invoicesApi";
import convertDate from "@shared/utils/convertDate";
import { formattedCurrencyPrice } from "@shared/utils/currencyPrice";
import LinearDeterminate from "@shared/ui/molecules/LinearDeterminate";
import { Formik } from "formik";
import { ButtonGroup, MenuItem } from "@mui/material";
import Typography from "@shared/ui/atoms/Typography";
import ButtonMenu from "@shared/ui/molecules/ButtonMenu";
import { useHistory } from "react-router-dom";
import routes from "@routes/routes";
import { TInvoice } from "@api/TranstubeCore/types";

const Invoices: React.FC = () => {
  const history = useHistory();
  const { data: invoicesResult = { data: undefined } } = useGetInvoicesQuery(
    {}
  );
  const { data: invoices } = invoicesResult;

  const [downloadInvoice] = useDownloadInvoiceMutation();

  const handleSubmit = async (invoice: TInvoice) => {
    await downloadInvoice({ id: invoice.id });
  };

  if (!invoices) return <LinearDeterminate />;

  return (
    <UserPanelTemplate
      seoProps={{
        title: "Moje faktury - TransKing.eu"
      }}
    >
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Data publikacji</TableCell>
              <TableCell align="left">Numer faktury</TableCell>
              <TableCell align="left">Kwota</TableCell>
              <TableCell align="center" />
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices.map((invoice) => (
              <TableRow
                key={invoice.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row" align="left">
                  {convertDate(invoice.issuedAt)}
                </TableCell>
                <TableCell align="left">{invoice.number}</TableCell>
                <TableCell align="left">
                  {formattedCurrencyPrice(
                    parseFloat(invoice.totalGrossPrice),
                    invoice.currencyIsoCode
                  )}
                </TableCell>
                <TableCell align="left">
                  <Formik
                    onSubmit={() => handleSubmit(invoice)}
                    initialValues={{}}
                  >
                    {({ handleSubmit: handleFormikSubmit, isSubmitting }) => (
                      <form onSubmit={handleFormikSubmit}>
                        <ButtonGroup>
                          <LoadingButton
                            size="small"
                            type="submit"
                            loading={isSubmitting}
                          >
                            Pobierz
                          </LoadingButton>

                          <ButtonMenu size="small" variant="outlined">
                            <MenuItem
                              onClick={() =>
                                history.push(
                                  routes.root.account.purchaseOrders.purchaseOrder(
                                    { id: invoice.meta.purchase_order_id }
                                  )
                                )
                              }
                            >
                              <Typography
                                variant="body2"
                                color="text.turquoise.dark"
                              >
                                Szczegóły zakupu
                              </Typography>
                            </MenuItem>

                            <MenuItem
                              onClick={() =>
                                history.push(
                                  routes.root.transportOrders.transportOrder({
                                    id: invoice.meta.transport_order_id
                                  })
                                )
                              }
                            >
                              <Typography
                                variant="body2"
                                color="text.turquoise.dark"
                              >
                                Szczegóły zlecenia
                              </Typography>
                            </MenuItem>
                          </ButtonMenu>
                        </ButtonGroup>
                      </form>
                    )}
                  </Formik>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </UserPanelTemplate>
  );
};

export default Invoices;
