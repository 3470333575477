import api from "./api";
import { TPublicUser, TResource, TUser } from "./types";

export const usersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    confirmEmail: builder.mutation({
      query: ({ body }) => ({
        url: `/users/email/confirm`,
        method: "POST",
        body: {
          ...body
        }
      })
    }),
    getPublicUser: builder.query<TResource<TPublicUser>, any>({
      query: ({ id }) => ({
        url: `/public/users/${id}`,
        params: {}
      }),
      providesTags: ["PublicUser"]
    }),
    createUser: builder.mutation<TResource<TUser>, any>({
      query: ({ body }) => ({
        url: "/users",
        method: "POST",
        body: { ...body }
      })
    }),
    getUser: builder.query<TResource<TUser>, any>({
      query: ({ id }) => ({
        url: `/users/${id}`,
        params: {}
      }),
      providesTags: ["User"]
    })
  })
});

export const {
  useCreateUserMutation,
  useGetUserQuery,
  useGetPublicUserQuery,
  useLazyGetUserQuery,
  useConfirmEmailMutation
} = usersApi;
