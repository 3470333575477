import React from "react";
import Stepper from "@shared/ui/molecules/HorizontalStepper/HorizontalLabelPositionBelowStepper";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import Typography from "../atoms/Typography";
import ContainerTemplate from "./ContainerTemplate";
import { TTransportOrderWizardTemplate } from "./TransportOrderWizardTemplate.types";

const TransportOrderWizardTemplate: React.FC<TTransportOrderWizardTemplate> = (
  props
) => {
  const { children, step, seoProps = {} } = props;
  const { i18n } = useTranslation();

  return (
    <ContainerTemplate
      seoProps={{
        title: i18n.t("pages.newTransportOrder.head.title"),
        description:
          seoProps.description ||
          i18n.t("pages.newTransportOrder.head.description"),
        robots: seoProps.robots
      }}
    >
      <Typography
        variant="h3"
        component="h1"
        mb={4}
        sx={{ textAlign: "center" }}
      >
        Zleć transport
      </Typography>

      <Box mb={8}>
        <Stepper step={step} />
      </Box>

      {children}
    </ContainerTemplate>
  );
};

export default TransportOrderWizardTemplate;
