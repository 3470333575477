import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    "&.MuiFormControl-root": {
      "& input::placeholder": {
        fontSize: "14px"
      }
    },
    ".MuiTypography-root": {
      borderColor: theme.palette.border.grey.main
    }
  }
}));

export default useStyles;
