import * as React from "react";
import Button from "@mui/material/Button";
import MUISnackbar from "@mui/material/Snackbar";
import { closeSnackbar, selectSnackbar } from "@store/snackbarSlice";
import { useDispatch, useSelector } from "react-redux";
import Typography from "../Typography";

const Snackbar: React.FC<any> = () => {
  const dispatch = useDispatch();
  const open = useSelector(selectSnackbar);

  const handleClose = () => {
    dispatch(closeSnackbar());
  };

  const action = (
    <Button color="primary" variant="outlined" onClick={handleClose}>
      Rozumiem
    </Button>
  );

  return (
    <MUISnackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={open}
      message={
        <>
          <Typography variant="body2" color="text.secondary">
            Strona korzysta z plików cookie. Korzystając ze strony,
          </Typography>{" "}
          <Typography variant="body2" color="text.secondary">
            wyrażasz zgodę na ich przechowywanie.
          </Typography>
        </>
      }
      action={action}
      ContentProps={{
        sx: {
          background: "#ffffff",
          borderRadius: "0px",
          border: "1px solid #dae4e7",
          color: "#555"
        }
      }}
    />
  );
};

export default Snackbar;
