import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Container } from "@mui/material";
import AppBarCollapse from "./AppBarCollapse";
import useStyles from "./Menu.styles";

const Menu: React.FC<any> = () => {
  const { classes } = useStyles();

  return (
    <AppBar position="fixed">
      <Container maxWidth="lg">
        <Toolbar className={classes.toolbar} disableGutters>
          <AppBarCollapse />
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Menu;
