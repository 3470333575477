import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

const useSectionStyles = makeStyles()((theme: Theme) => ({
  root: {
    background: "#ffffff",
    paddingBottom: theme.spacing(1)
  },
  boxLogo: {
    "&.MuiBox-root": {
      width: "160px"
    }
  },
  centerBox: {
    "&.MuiBox-root": {
      [theme.breakpoints.between("xs", "md")]: {
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      },
      [theme.breakpoints.between("md", "lg")]: {
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }
    }
  },
  typographyTitle: {
    "&.MuiTypography-root": {
      cursor: "pointer",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline solid #1E99C0 2px",
        textUnderlinePosition: "under"
      }
    }
  },
  text: {
    "&.MuiTypography-root": {
      color: "#5E5E5E",
      fontSize: "0.875rem",
      textDecoration: "none"
    }
  },
  copyright: {
    "&.MuiTypography-root": {
      fontFamily: "Ubuntu, sans-serif",
      color: "#5E5E5E",
      fontSize: "0.75rem",
      textDecoration: "none"
    }
  },
  logoLink: {
    width: "10px"
  },
  box: {
    "&.MuiBox-root": {
      border: `1px solid ${theme.palette.border.grey.main}`,
      padding: theme.spacing(0.5),
      width: "2rem",
      height: "2rem",
      "&:hover": {
        borderColor: `${theme.palette.border.turquoise.main} !important`,
        cursor: "pointer"
      },
      "&:hover .icon": {
        borderColor: `${theme.palette.border.turquoise.main} !important`,
        cursor: "pointer"
      },
      "&:not(:hover) .icon": {
        color: "#cccccc"
      }
    }
  }
}));

export default useSectionStyles;
