import React from "react";
import { selectCurrentUser } from "@store/authSlice";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import routes from "./routes";

const PrivateRoute = (props: any) => {
  const { component: Component, render: renderProp, ...rest } = props;
  const currentUser = useSelector(selectCurrentUser);

  const renderValue = (renderProps: any) => {
    if (!currentUser) return <Redirect to={{ pathname: routes.root() }} />;
    if (renderProp) return renderProp(renderProps);
    if (Component) return <Component {...renderProps} />;

    return <></>;
  };

  return (
    <Route {...rest} render={(renderProps: any) => renderValue(renderProps)} />
  );
};

export default PrivateRoute;
