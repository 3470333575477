import * as yup from "yup";
import { AsYouType } from "mui-tel-input";

const validationSchema = (i18n: any) => {
  return yup.object({
    accountType: yup.string().required(),
    company: yup.object().when("accountType", {
      is: "company",
      then: (schema) =>
        schema
          .shape({
            id: yup.string().required(),
            nip: yup
              .string()
              .required()
              .test(
                "is-valid",
                i18n.t("yup.validations.mixed.required"),
                (_, context) => context.parent.id != null
              )
          })
          .required()
    }),
    email: yup.string().required(),
    password: yup.string().min(8).required(),
    passwordConfirmation: yup
      .string()
      .required()
      .when("password", {
        is: (val: string) => !!(val && val.length > 0),
        then: yup
          .string()
          .oneOf(
            [yup.ref("password")],
            i18n.t(
              "yup.validations.user.passwordConfirmation.passwords_entered_must_be_same"
            )
          )
      }),
    name: yup.string().required(),
    phoneNumber: yup
      .string()
      .transform((value) => {
        const asYouType = new AsYouType();

        asYouType.input(value);

        return asYouType.getNationalNumber();
      })
      .required(),
    acceptTerms: yup
      .bool()
      .required()
      .oneOf([true], i18n.t("yup.validations.mixed.required"))
  });
};

export default validationSchema;
