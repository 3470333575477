import React from "react";
import Menu from "@shared/ui/organisms/Menu/Menu";
import SignInDialog from "@shared/ui/organisms/SignInDialog";
import SignUpDialog from "@shared/ui/organisms/SignUpDialog";
import PasswordReminderDialog from "@shared/ui/organisms/PasswordReminderDialog";
import Footer from "@shared/ui/organisms/Footer";
import CustomScrollToTop from "@shared/ui/atoms/CustomScrollToTop";
import { Box, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import Snackbar from "../atoms/Snackbar/Snackbar";
import SEO from "../atoms/SEO";
import { TDefaultTemplate } from "./DefaultTemplate.types";

const DefaultTemplate: React.FC<TDefaultTemplate> = (props) => {
  const { children, seoProps = {} } = props;
  const { i18n } = useTranslation();

  return (
    <>
      <SEO
        title={
          seoProps.title
            ? [seoProps.title, "TransKing.eu"].join(" - ")
            : [i18n.t("pages.home.head.title"), "TransKing.eu"].join(" - ")
        }
        description={
          seoProps.description || i18n.t("pages.home.head.description")
        }
        robots={seoProps.robots}
        script={[
          {
            type: "application/ld+json",
            innerHTML: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "WebSite",
              url: "https://www.transking.eu/pl/",
              name: "TransKing.eu",
              inLanguage: "pl",
              translationOfWork: {
                "@id": "https://www.transking.eu/#website"
              },
              sameAs: [
                "https://www.facebook.com/TransKingEU",
                "https://www.instagram.com/TransKingEU",
                "https://x.com/TransKingEU",
                "https://www.youtube.com/@TransKingEU"
              ]
            })
          }
        ]}
        link={seoProps.link || []}
      />

      <Stack direction="column" sx={{ height: "100%", alignItems: "stretch" }}>
        <Snackbar />
        <CustomScrollToTop />
        <SignInDialog />
        <SignUpDialog />
        <PasswordReminderDialog />
        <Menu />

        <Box component="main" sx={{ flexGrow: 1, paddingTop: "90px" }}>
          {children}
        </Box>

        <Footer />
      </Stack>
    </>
  );
};

export default DefaultTemplate;
