import React from "react";
import Invoice from "@assets/images/icons/invoice.svg";
import SVG from "react-inlinesvg";
import useColorFromPalette from "@shared/hooks/useColorFromPalette";

const InvoiceIcon: React.FC<any> = (props) => {
  const { color = "turquoise.main", ...iconProps } = props;
  const colorValue = useColorFromPalette(color);

  return (
    <SVG
      src={Invoice}
      width="25px"
      height="25px"
      color={colorValue}
      {...iconProps}
    />
  );
};

export default InvoiceIcon;
