import React from "react";
import TextField from "@shared/ui/atoms/TextField";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import HttpsIcon from "@mui/icons-material/Https";
import useStyles from "./PasswordTextField.styles";

const PasswordTextField: React.FC<any> = (props) => {
  const { className } = props;
  const { classes, cx } = useStyles();
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShow = () => {
    setShowPassword(true);

    if (showPassword === true) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  };

  const handleMouseDown = (event: any) => {
    event.preventDefault();
  };

  return (
    <TextField
      name="password"
      type={showPassword ? "text" : "password"}
      InputProps={{
        startAdornment: (
          <HttpsIcon color="turquoise.light" className={classes.inputIcon} />
        ),
        endAdornment: (
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShow}
            onMouseDown={handleMouseDown}
            edge="end"
          >
            {showPassword ? (
              <VisibilityOff className={classes.inputEndIcon} />
            ) : (
              <Visibility className={classes.inputEndIcon} />
            )}
          </IconButton>
        )
      }}
      {...props}
      className={cx(className)}
    />
  );
};

export default PasswordTextField;
