import { makeStyles } from "tss-react/mui";

const useSectionStyles = makeStyles()(() => ({
  root: {
    background: "#F6FAFD"
  },
  title: {
    "&.MuiTypography-root": {
      color: "#054752"
    }
  }
}));

export default useSectionStyles;
