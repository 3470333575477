import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

const useSectionStyles = makeStyles()((theme: Theme) => ({
  flag: {
    border: `1px solid ${theme.palette.border.grey.light}`,
    width: 20
  }
}));

export default useSectionStyles;
