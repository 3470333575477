import React from "react";
import UserPanelTemplate from "@shared/ui/templates/UserPanelTemplate";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@shared/ui/atoms/Button";
import ButtonMenu from "@shared/ui/molecules/ButtonMenu";
import { useGetOffersQuery } from "@api/TranstubeCore/offersApi";
import { useHistory } from "react-router-dom";
import routes from "@routes/routes";
import { selectCurrentUser } from "@store/authSlice";
import { useSelector } from "react-redux";
import LinearDeterminate from "@shared/ui/molecules/LinearDeterminate/LinearDeterminate";
import { ButtonGroup, MenuItem } from "@mui/material";
import Typography from "@shared/ui/atoms/Typography";
import convertDate from "@shared/utils/convertDate";
import { Trans } from "react-i18next";
import { formattedCurrencyPrice } from "@shared/utils/currencyPrice";

const Offers: React.FC = () => {
  const currentUser = useSelector(selectCurrentUser);

  const { data: offersResult = { data: undefined } } = useGetOffersQuery({
    params: {
      filter: {
        user_id_eq: currentUser.id
      }
    }
  });

  const history = useHistory();
  const { data: offers } = offersResult;

  const open = (item: any) => {
    history.push(routes.root.account.offers.offer({ id: item.id }));
  };

  const displayData = (dataCreate: any) => {
    const data = convertDate(dataCreate);

    return data;
  };

  if (!offers) return <LinearDeterminate />;

  return (
    <UserPanelTemplate
      seoProps={{
        title: "Moje oferty"
      }}
    >
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Numer</TableCell>
              <TableCell align="left">Data publikacji</TableCell>
              <TableCell align="left">Kwota</TableCell>
              <TableCell align="left">Status</TableCell>
              <TableCell align="center" />
            </TableRow>
          </TableHead>
          <TableBody>
            {offers.map((offer) => (
              <TableRow
                key={offer.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row" align="left">
                  {offer.number}
                </TableCell>
                <TableCell component="th" scope="row" align="left">
                  {displayData(offer.createdAt)}
                </TableCell>
                <TableCell align="left">
                  {formattedCurrencyPrice(
                    parseFloat(offer.price),
                    offer.currencyIsoCode
                  )}
                </TableCell>
                <TableCell align="left">
                  <Trans
                    i18nKey={`statuses.${offer.status}`}
                    defaults={offer.status}
                  />
                </TableCell>
                <TableCell align="left">
                  <ButtonGroup>
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => open(offer)}
                    >
                      Zobacz
                    </Button>

                    <ButtonMenu size="small" variant="outlined">
                      <MenuItem
                        onClick={() =>
                          history.push(
                            routes.root.transportOrders.transportOrder({
                              id: offer.auction.transportOrder.id
                            })
                          )
                        }
                      >
                        <Typography variant="body2" color="text.turquoise.dark">
                          Szczegóły zlecenia
                        </Typography>
                      </MenuItem>

                      <MenuItem
                        disabled={offer.status !== "completed"}
                        onClick={() => open(offer)}
                      >
                        <Typography variant="body2" color="text.turquoise.dark">
                          Dane kontaktowe
                        </Typography>
                      </MenuItem>
                    </ButtonMenu>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </UserPanelTemplate>
  );
};

export default Offers;
