import React from "react";
import CustomAccordion from "@shared/ui/atoms/CustomAccordion/CustomAccordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Stack from "@mui/material/Stack";
import Box, { BoxProps } from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { useCreateMessageMutation } from "@api/TranstubeCore/messagesApi";
import { useFormik } from "formik";
import { selectCurrentUser } from "@store/authSlice";
import { useSelector } from "react-redux";
import TextField from "@shared/ui/atoms/TextField";
import Fab from "@mui/material/Fab";
import NavigationIcon from "@mui/icons-material/Navigation";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import shortenQuestion from "@shared/utils/shortenQuestion";
import UserNameBadge from "@shared/ui/molecules/UserNameBadge/UserNameBadge";
import Typography from "@shared/ui/atoms/Typography";
import routes from "@routes/routes";
import dayjs from "dayjs";
import handleFormError from "@shared/utils/handleFormError";
import { useTranslation } from "react-i18next";
import useStyles from "./Discussion.styles";
import validationSchema from "./Discussion.validationSchema";

function Item(props: BoxProps) {
  const { sx, ...other } = props;

  return (
    <Box
      sx={{
        p: 1,
        m: 1,
        fontSize: "0.875rem",
        fontWeight: "700",
        ...sx
      }}
      {...other}
    />
  );
}

const Discussion: React.FC<any> = (props) => {
  const { discussion, refetchDiscussions, transportOrder } = props;
  const { classes } = useStyles();
  const [createMessage] = useCreateMessageMutation();
  const currentUser = useSelector(selectCurrentUser);
  const { i18n } = useTranslation();

  const handleSubmit = async (values: any, event: any) => {
    if (discussion.id) {
      const createMessageParams = {
        body: {
          data: {
            attributes: {
              discussion_id: discussion?.id,
              user_id: currentUser?.id,
              content: values.messageContent
            }
          }
        }
      };

      await createMessage(createMessageParams)
        .unwrap()
        .then(async () => {
          event.resetForm();
          await refetchDiscussions();
        })
        .catch((ex: any) => {
          const pointers = {
            "/data/attributes/content": {
              field: "messageContent",
              i18n: "messageContent"
            }
          };

          ex.data?.errors?.forEach((e: any) => {
            handleFormError(pointers, e, i18n, event);
          });
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      messageContent: ""
    },
    validationSchema: validationSchema(),
    onSubmit: handleSubmit
  });

  const onKeyDown = (e: any) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      formik.handleSubmit();
    }
  };

  const displayData = (dataCreate: any) => {
    const data = dayjs(dataCreate).format("LLL");

    return data;
  };

  const displayTextField = () => {
    if (
      (discussion.messages[0]?.user?.id === currentUser?.id ||
        transportOrder?.auction?.user?.id === currentUser?.id) &&
      transportOrder?.auction?.status === "opened"
    ) {
      return (
        <form
          onSubmit={formik.handleSubmit}
          onKeyDown={onKeyDown}
          role="presentation"
        >
          <TextField
            className={classes.input}
            InputProps={{
              inputProps: {
                spellCheck: "true",
                autoCorrect: "on"
              },
              disableUnderline: true,
              className: classes.textFieldInput,
              sx: { fontSize: "0.875rem" },
              endAdornment: (
                <Fab
                  color="secondary"
                  aria-label="add"
                  className={classes.fabButton}
                  type="submit"
                >
                  <NavigationIcon className={classes.svgNavigation} />
                </Fab>
              )
            }}
            value={formik.values.messageContent}
            onChange={formik.handleChange}
            error={
              formik.touched.messageContent &&
              Boolean(formik.errors.messageContent)
            }
            helperText={
              formik.touched.messageContent && formik.errors.messageContent
            }
            name="messageContent"
            placeholder="Napisz wiadomość..."
            fullWidth
            margin="normal"
            variant="standard"
          />
        </form>
      );
    }

    return <></>;
  };

  const dialog = () => {
    return (
      <div style={{ width: "100%" }}>
        {discussion?.messages?.map((e: any) => (
          <Stack spacing={2} key={e.id}>
            <Box sx={{ display: "flex" }}>
              <Item sx={{ width: "30%" }}>
                <UserNameBadge
                  to={routes.root.users.user({ id: e.user.id })}
                  color={e?.user?.avatarColor}
                  displayName={e?.user?.displayName}
                  numberRatings={e?.user?.meta?.review_count}
                  averageRating={e?.user?.meta?.average_rating}
                />
              </Item>
              <Divider orientation="vertical" variant="middle" flexItem />

              <Item sx={{ width: "70%" }}>
                <Typography
                  variant="body2"
                  color="text.grey.main"
                  sx={{ wordBreak: "break-word" }}
                >
                  {e?.content}
                </Typography>
                <Typography
                  variant="caption"
                  color="text.grey.main"
                  align="left"
                >
                  Data utworzenia: {displayData(e?.createdAt)}
                </Typography>
              </Item>
            </Box>
          </Stack>
        ))}

        <Box sx={{ p: 2 }}>{displayTextField()}</Box>
      </div>
    );
  };

  return (
    <CustomAccordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon color="primary" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Box sx={{ p: 2 }}>
          <Typography
            variant="body2"
            color="text.turquoise.dark"
            sx={{ wordBreak: "break-word" }}
          >
            <strong>{shortenQuestion(discussion?.messages[0]?.content)}</strong>
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>{dialog()}</AccordionDetails>
    </CustomAccordion>
  );
};

export default Discussion;
