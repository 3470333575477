import api from "./api";
import {
  TMapDirections,
  TMapLocation,
  TMapSuggestion,
  TResource,
  TResources
} from "./types";

export const mapApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSuggestions: builder.query<TResources<TMapSuggestion>, any>({
      query: ({ params }) => ({
        url: `map/suggestions`,
        params: { ...params }
      })
    }),
    getLocation: builder.query<TResource<TMapLocation>, any>({
      query: ({ id }) => ({
        url: `map/locations/${id}`
      })
    }),
    getDirections: builder.query<TResource<TMapDirections>, any>({
      query: ({ params }) => ({
        url: `map/directions`,
        params: { ...params }
      })
    })
  })
});

export const {
  useLazyGetSuggestionsQuery,
  useLazyGetLocationQuery,
  useLazyGetDirectionsQuery
} = mapApi;
