import React from "react";
import ContainerTemplate from "@shared/ui/templates/ContainerTemplate";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@shared/ui/atoms/Button";
import Box from "@mui/material/Box";
import Typography from "@shared/ui/atoms/Typography";
import { Link as RouterLink } from "react-router-dom";
import useStyles from "./BoxInformation.styles";

const BoxInformation: React.FC<any> = ({
  seoProps,
  actionLeftButton,
  actionRightButton,
  image,
  title,
  description,
  nameButtonLeft,
  nameButtonRight
}) => {
  const { classes } = useStyles();

  const buttons = () => {
    if (actionLeftButton === null && nameButtonLeft === null) {
      return (
        <Box
          sx={{
            textAlign: "center"
          }}
          mt={5}
        >
          <Button
            component={RouterLink}
            variant="outlined"
            to={actionRightButton}
          >
            {nameButtonRight}
          </Button>
        </Box>
      );
    }

    if (actionRightButton === null && nameButtonRight === null) {
      return (
        <Box
          sx={{
            textAlign: "center"
          }}
          mt={5}
        >
          <Button
            component={RouterLink}
            variant="outlined"
            to={actionLeftButton}
          >
            {nameButtonLeft}
          </Button>
        </Box>
      );
    }

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between"
        }}
        mt={5}
      >
        <Button component={RouterLink} variant="outlined" to={actionLeftButton}>
          {nameButtonLeft}
        </Button>
        <Button
          component={RouterLink}
          variant="contained"
          to={actionRightButton}
        >
          {nameButtonRight}
        </Button>
      </Box>
    );
  };

  return (
    <ContainerTemplate seoProps={seoProps}>
      <Grid container mt={3} mb={3} alignItems="center" justifyContent="center">
        <Grid item xs={12} md={5}>
          <Paper className={classes.paperAutocomplete}>
            <Stack spacing={3}>
              <div style={{ textAlign: "center" }}>{image}</div>
              <Typography variant="h6" component="h2" align="center">
                {title}
              </Typography>
              <Typography>{description}</Typography>
            </Stack>

            {buttons()}
          </Paper>
        </Grid>
      </Grid>
    </ContainerTemplate>
  );
};

export default BoxInformation;
