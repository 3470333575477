import routes from "@routes/routes";
import React from "react";
import BoxInformation from "@shared/ui/molecules/BoxInformation/BoxInformation";
import VerifiedEmailIcon from "@shared/ui/atoms/icons/VerifiedEmailIcon";
import { useTranslation } from "react-i18next";

const EmailConfirmed: React.FC<any> = () => {
  const { i18n } = useTranslation();

  const mainPage = () => {
    return routes.root();
  };

  return (
    <BoxInformation
      seoProps={{
        title: "Potwierdzenie weryfikacji adresu e-mail",
        robots: "follow, noindex"
      }}
      actionLeftButton={null}
      actionRightButton={mainPage}
      image={<VerifiedEmailIcon />}
      title="Zweryfikowano adres e-mail"
      description="Weryfikacja przebiegła pomyślnie! Twoje konto zostało w pełni aktywowane. Przejdź do strony głównej za pomocą przycisku, który znajduje się poniżej."
      nameButtonLeft={null}
      nameButtonRight={i18n.t("buttons.home")}
    />
  );
};

export default EmailConfirmed;
