import React from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import HelpTemplate from "@shared/ui/templates/HelpTemplate";
import Typography from "@shared/ui/atoms/Typography";
import Panel from "@shared/ui/molecules/Panel/Panel";
import { useTranslation } from "react-i18next";

const HowCanIMakeAnOfferAsACarrier = () => {
  const { i18n } = useTranslation();

  return (
    <HelpTemplate
      seoProps={{
        title: i18n.t("pages.howCanIMakeAnOfferAsACarrier.head.title")
      }}
    >
      <Grid item xs={12} sm={12} md={8} mt={3}>
        <Panel>
          <Stack spacing={2}>
            <Typography variant="h5" component="h1">
              W jaki sposób jako przewoźnik mogę wystawić ofertę zleceniodawcy?
            </Typography>

            <Box pt={1}>
              <Stack spacing={2}>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    <Typography>
                      Jeśli chcesz wystawić ofertę zleceniodawcy musisz
                      zalogować się do swojego konta na{" "}
                      <Box display="inline-block">
                        <Typography color="text.turquoise.main">
                          <strong>TransKing.eu</strong>
                        </Typography>
                      </Box>
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      Następnie kliknij na zlecenie, które Cię interesuje
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      Z bocznego menu wybierz zakładkę{" "}
                      <Box display="inline-block">
                        <Typography color="text.turquoise.main">
                          <strong>Oferty</strong>
                        </Typography>
                      </Box>
                      , po przejściu, na stronie powinien znajdować się
                      formularz, w którym należy wpisać cenę, którą proponujesz
                      za zlecenie tego przewozu
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      Kliknij przycisk potwierdzający i gotowe, powinieneś
                      zobaczyć swoją ofertę na{" "}
                      <Box display="inline-block">
                        <Typography color="text.turquoise.main">
                          <strong>Liście ofert</strong>
                        </Typography>
                      </Box>
                    </Typography>
                  </li>
                </ul>
              </Stack>
            </Box>
          </Stack>
        </Panel>
      </Grid>
    </HelpTemplate>
  );
};

export default HowCanIMakeAnOfferAsACarrier;
