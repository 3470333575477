import React, { useEffect, useRef, useState } from "react";
import theme from "@shared/themes/base";
import maplibregl, { LngLatBoundsLike, LngLatLike, Marker } from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import polyline from "@mapbox/polyline";
import OriginMarkerSvg from "@assets/images/icons/origin_marker.svg";
import DestinationMarkerSvg from "@assets/images/icons/destination_marker.svg";
import useStyles from "./TranstubeMap.styles";
import { TranstubeMapProps } from "./TranstubeMap.types";

const TranstubeMap: React.FC<TranstubeMapProps> = (props) => {
  const { originPosition, destinationPosition, polyline: propPolyline } = props;
  const { classes } = useStyles();
  const map = useRef<any | null>(null);
  const mapContainer = useRef<any | null>(null);
  const warsawPosition: LngLatLike = [21.017532, 52.237049];
  const [loading, setLoading] = useState<boolean>(true);
  const [originMarker, setOriginMarker] = useState<Marker | null>(null);

  const [destinationMarker, setDestinationMarker] = useState<Marker | null>(
    null
  );

  const [routeId, setRouteId] = useState<string | null>(null);

  const mapCenter = () => {
    if (originPosition === null && destinationPosition !== null) {
      return destinationPosition;
    }

    if (originPosition !== null && destinationPosition === null) {
      return originPosition;
    }

    return warsawPosition;
  };

  const mapBounds = (): LngLatBoundsLike | undefined => {
    if (originPosition === null || destinationPosition === null) {
      return undefined;
    }

    return [originPosition, destinationPosition];
  };

  const createOriginMarker = () => {
    if (originPosition === null) return;

    const el = document.createElement("img");
    el.src = OriginMarkerSvg;
    el.height = 22;

    const marker = new maplibregl.Marker({ element: el })
      .setLngLat(originPosition)
      .addTo(map.current);

    setOriginMarker(marker);
  };

  const removeOriginMarker = () => {
    if (!originMarker) return;

    originMarker.remove();
    setOriginMarker(null);
  };

  const createDestinationMarker = () => {
    if (destinationPosition === null) return;

    const el = document.createElement("img");
    el.src = DestinationMarkerSvg;
    el.height = 40;

    const marker = new maplibregl.Marker({ element: el })
      .setLngLat(destinationPosition)
      .setOffset([0, -20])
      .addTo(map.current);

    setDestinationMarker(marker);
  };

  const removeDestinationMarker = () => {
    if (!destinationMarker) return;

    destinationMarker.remove();
    setDestinationMarker(null);
  };

  const createRoute = () => {
    const polylineData = propPolyline;

    if (polylineData === null || polylineData === undefined) return;

    map.current.addSource("route", {
      type: "geojson",
      data: {
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: polyline.decode(polylineData).map((e) => e.reverse())
        }
      }
    });

    map.current.addLayer({
      id: "route",
      type: "line",
      source: "route",
      layout: {
        "line-join": "round",
        "line-cap": "round"
      },
      paint: {
        "line-color": theme.palette.primary.main,
        "line-width": 8
      }
    });

    setRouteId("route");
  };

  const removeRoute = () => {
    if (!routeId) return;

    map.current.removeLayer(routeId);
    map.current.removeSource(routeId);
    setRouteId(null);
  };

  const fitBounds = () => {
    if (originPosition === null) return;
    if (destinationPosition === null) return;

    map.current.fitBounds([originPosition, destinationPosition], {
      padding: 64
    });
  };

  useEffect(() => {
    let isMounted = true;

    if (map.current)
      return () => {
        isMounted = false;
      };

    map.current = new maplibregl.Map({
      container: mapContainer.current,
      style: process.env.REACT_APP_MAPTILER_API_URL || "",
      center: mapCenter(),
      zoom: 10,
      dragRotate: false,
      touchZoomRotate: false,
      pitch: 45,
      bearing: 0,
      bounds: mapBounds(),
      attributionControl: false
    });

    map.current.addControl(
      new maplibregl.NavigationControl({ showCompass: false }),
      "top-left"
    );

    map.current.addControl(
      new maplibregl.AttributionControl({ compact: false }),
      "bottom-right"
    );

    map.current.on("load", () => {
      if (isMounted) setLoading(false);
    });

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (!map.current) return;

    removeOriginMarker();
    createOriginMarker();
  }, [originPosition]);

  useEffect(() => {
    if (!map.current) return;

    removeDestinationMarker();
    createDestinationMarker();
  }, [destinationPosition]);

  useEffect(() => {
    if (!map.current) return;
    if (loading) return;

    removeRoute();
    createRoute();
  }, [propPolyline, loading]);

  useEffect(() => {
    if (!map.current) return;
    if (loading) return;

    fitBounds();
  }, [loading, originPosition, destinationPosition]);

  return (
    <div className={classes.root}>
      <div ref={mapContainer} />
    </div>
  );
};

export default TranstubeMap;
