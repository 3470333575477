import React from "react";
import SecuredLetter from "@assets/images/icons/secured_letter.svg";
import SVG from "react-inlinesvg";
import useColorFromPalette from "@shared/hooks/useColorFromPalette";

const SecuredLetterIcon: React.FC<any> = (props) => {
  const { color = "turquoise.main", ...iconProps } = props;
  const colorValue = useColorFromPalette(color);

  return (
    <SVG
      src={SecuredLetter}
      width="30px"
      height="30px"
      color={colorValue}
      {...iconProps}
    />
  );
};

export default SecuredLetterIcon;
