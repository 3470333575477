import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TranstubeMap from "@shared/ui/atoms/TranstubeMap";
import OriginMarkerIcon from "@shared/ui/atoms/icons/OriginMarkerIcon";
import DestinationMarkerIcon from "@shared/ui/atoms/icons/DestinationMarkerIcon";
import convertToLowerCase from "@shared/utils/convertToLowerCase";
import UserNameBadge from "@shared/ui/molecules/UserNameBadge/UserNameBadge";
import Typography from "@shared/ui/atoms/Typography";
import addressFormatter from "@fragaria/address-formatter";
import routes from "@routes/routes";
import convertDate from "@shared/utils/convertDate";
import Panel from "@shared/ui/molecules/Panel/Panel";
import PeopleTransportOrderLIneItem from "@shared/ui/molecules/PeopleTransportOrderLIneItem";
import OthersTransportOrderLineItem from "@shared/ui/molecules/OthersTransportOrderLineItem";
import { ITransportOrderLineItem } from "@pages/transport-orders/new/category/category.types";
import { Trans } from "react-i18next";
import useStyles from "./TransportOrder.styles";

const TransportOrder: React.FC<any> = (props) => {
  const { transportOrder } = props;
  const { classes } = useStyles();

  const addressFromLocation = () => {
    const formattedAddress = addressFormatter
      .format(
        {
          postcode: transportOrder.fromLocation.postCode,
          city: transportOrder.fromLocation.city,
          state: transportOrder.fromLocation.state,
          country: transportOrder.fromLocation.countryName,
          countryCode: transportOrder.fromLocation.countryIsoCode
        },
        {
          output: "array"
        }
      )
      .join(", ");

    if (!transportOrder) return <></>;

    return (
      <Typography variant="body2" color="text.grey.main">
        {formattedAddress}
      </Typography>
    );
  };

  const addressToLocation = () => {
    const formattedAddress = addressFormatter
      .format(
        {
          postcode: transportOrder.toLocation.postCode,
          city: transportOrder.toLocation.city,
          state: transportOrder.toLocation.state,
          country: transportOrder.toLocation.countryName,
          countryCode: transportOrder.toLocation.countryIsoCode
        },
        {
          output: "array"
        }
      )
      .join(", ");

    return (
      <Typography variant="body2" color="text.grey.main">
        {formattedAddress}
      </Typography>
    );
  };

  const flagFromLocation = () => {
    if (transportOrder?.fromLocation?.countryIsoCode === null) return <></>;

    return (
      <Box>
        <sup>
          <img
            loading="lazy"
            src={`https://flagcdn.com/w20/${convertToLowerCase(
              transportOrder?.fromLocation?.countryIsoCode
            )}.png`}
            srcSet={`https://flagcdn.com/w40/${convertToLowerCase(
              transportOrder?.fromLocation?.countryIsoCode
            )}.png 2x`}
            alt=""
            className={classes.flag}
          />
        </sup>
      </Box>
    );
  };

  const flagToLocation = () => {
    if (transportOrder?.toLocation?.countryIsoCode === null) return <></>;

    return (
      <Box>
        <sup>
          <img
            loading="lazy"
            src={`https://flagcdn.com/w20/${convertToLowerCase(
              transportOrder?.toLocation?.countryIsoCode
            )}.png`}
            srcSet={`https://flagcdn.com/w40/${convertToLowerCase(
              transportOrder?.toLocation?.countryIsoCode
            )}.png 2x`}
            alt=""
            className={classes.flag}
          />
        </sup>
      </Box>
    );
  };

  const displayMap = () => {
    if (transportOrder === null) return null;

    return (
      <TranstubeMap
        originPosition={[
          transportOrder?.fromLocation?.longitude,
          transportOrder?.fromLocation?.latitude
        ]}
        destinationPosition={[
          transportOrder?.toLocation?.longitude,
          transportOrder?.toLocation?.latitude
        ]}
        polyline={transportOrder?.polyline}
      />
    );
  };

  const isCompany = () => {
    if (transportOrder?.auction?.offerPreferences?.is_user_company === false) {
      return (
        <Typography variant="body2" color="text.grey.main">
          Nie
        </Typography>
      );
    }

    return (
      <Typography variant="body2" color="text.grey.main">
        Tak
      </Typography>
    );
  };

  const isPrivatePerson = () => {
    if (
      transportOrder?.auction?.offerPreferences?.is_user_private_person ===
      false
    ) {
      return (
        <Typography variant="body2" color="text.grey.main">
          Nie
        </Typography>
      );
    }

    return (
      <Typography variant="body2" color="text.grey.main">
        Tak
      </Typography>
    );
  };

  const invoice = () => {
    if (transportOrder?.auction?.offerPreferences?.is_invoice === false) {
      return (
        <Typography variant="body2" color="text.grey.main">
          Nie
        </Typography>
      );
    }

    return (
      <Typography variant="body2" color="text.grey.main">
        Tak
      </Typography>
    );
  };

  const displayCategories = () => {
    return (
      <>
        {transportOrder.transportOrderLineItems.map(
          (transportOrderLineItem: ITransportOrderLineItem) =>
            transportOrderLineItem.category === "people" ? (
              <React.Fragment
                key={`transportOrderLineItem-${transportOrderLineItem.id}`}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <PeopleTransportOrderLIneItem
                    transportOrderLineItem={transportOrderLineItem}
                  />
                </Grid>
              </React.Fragment>
            ) : (
              <React.Fragment
                key={`transportOrderLineItem-${transportOrderLineItem.id}`}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <OthersTransportOrderLineItem
                    transportOrderLineItem={transportOrderLineItem}
                  />
                </Grid>
              </React.Fragment>
            )
        )}
      </>
    );
  };

  const displayInformation = () => {
    if (transportOrder?.extraInformation !== "") {
      return (
        <Box>
          <Stack spacing={1}>
            <Box>
              <Typography variant="body2" color="text.turquoise.dark">
                <strong>Informacje dodatkowe</strong>
              </Typography>
            </Box>

            <Box>
              <Typography
                variant="body2"
                color="text.grey.main"
                sx={{ wordBreak: "break-word" }}
              >
                {transportOrder?.extraInformation}
              </Typography>
            </Box>
          </Stack>
        </Box>
      );
    }

    return <></>;
  };

  const displayData = (order: any) => {
    if (order?.startOn === null || order?.endOn === null)
      return (
        <Typography variant="body2" color="text.grey.main">
          Termin elastyczny
        </Typography>
      );

    return (
      <Box>
        <Stack spacing={1}>
          <Typography variant="body2" color="text.grey.main">
            Od: {convertDate(order?.startOn)}
          </Typography>
          <Typography variant="body2" color="text.grey.main">
            Do: {convertDate(order?.endOn)}
          </Typography>
        </Stack>
      </Box>
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={4}>
        <Panel sx={{ minHeight: 130 }}>
          <Stack spacing={1}>
            <Typography variant="body2" color="text.turquoise.dark">
              <strong>Zleceniodawca</strong>
            </Typography>

            <UserNameBadge
              to={routes.root.users.user({
                id: transportOrder.auction.user.id
              })}
              color={transportOrder?.auction?.user?.avatarColor}
              displayName={transportOrder?.auction?.user?.displayName}
              numberRatings={transportOrder?.auction?.user?.meta?.review_count}
              averageRating={
                transportOrder?.auction?.user?.meta?.average_rating
              }
            />
          </Stack>
        </Panel>
      </Grid>
      <Grid item xs={12} sm={12} md={5}>
        <Panel sx={{ height: 130 }}>
          <Stack spacing={1}>
            <Typography variant="body2" color="text.turquoise.dark">
              <strong>Numer</strong>
            </Typography>
            <Typography variant="body2" color="text.grey.main">
              {transportOrder?.number}
            </Typography>
          </Stack>
        </Panel>
      </Grid>
      <Grid item xs={12} sm={12} md={3}>
        <Panel sx={{ height: 130 }}>
          <Stack spacing={1}>
            <Typography variant="body2" color="text.turquoise.dark">
              <strong>Termin realizacji</strong>
            </Typography>
            {displayData(transportOrder)}
          </Stack>
        </Panel>
      </Grid>

      <Grid item xs={12} sm={12} md={6}>
        <Panel>
          <Stack spacing={2}>
            <Stack spacing={1}>
              <Stack direction="row" spacing={1} alignItems="center">
                <OriginMarkerIcon />

                <Typography variant="body2" color="text.turquoise.dark">
                  <strong>
                    <Trans i18nKey="labels.from_location" />
                  </strong>
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <Box sx={{ width: "19px" }} />
                {addressFromLocation()}
                {flagFromLocation()}
              </Stack>
            </Stack>
            <Stack spacing={1}>
              <Stack direction="row" spacing={1}>
                <DestinationMarkerIcon />

                <Typography variant="body2" color="text.turquoise.dark">
                  <strong>
                    <Trans i18nKey="labels.to_location" />
                  </strong>
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <Box sx={{ width: "19px" }} />
                {addressToLocation()}
                {flagToLocation()}
              </Stack>
            </Stack>
          </Stack>
        </Panel>

        <Panel sx={{ mt: 2 }}>
          <Stack spacing={2}>
            <Box>
              <Stack spacing={1}>
                <Box>
                  <Typography variant="body2" color="text.turquoise.dark">
                    <strong>Osoba prywatna</strong>
                  </Typography>
                </Box>

                <Box>{isPrivatePerson()}</Box>
              </Stack>
            </Box>

            <Box>
              <Stack spacing={1}>
                <Box>
                  <Typography variant="body2" color="text.turquoise.dark">
                    <strong>Firma</strong>
                  </Typography>
                </Box>

                <Box>{isCompany()}</Box>
              </Stack>
            </Box>

            <Box>
              <Stack spacing={1}>
                <Box>
                  <Typography variant="body2" color="text.turquoise.dark">
                    <strong>Faktura VAT</strong>
                  </Typography>
                </Box>

                <Box>{invoice()}</Box>
              </Stack>
            </Box>
            {displayInformation()}
          </Stack>
        </Panel>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Panel sx={{ padding: 1, height: 340 }}>{displayMap()}</Panel>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Stack spacing={2}>{displayCategories()}</Stack>
      </Grid>
    </Grid>
  );
};

export default TransportOrder;
