import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme: Theme) => ({
  menuIcon: {
    "&.MuiSvgIcon-root": {
      fontSize: 40,
      color: theme.palette.common.black
    }
  },
  boxList: {
    "&.MuiBox-root": {
      width: "280px"
    }
  },
  navLink: {
    textDecoration: "none"
  }
}));

export default styles;
