import * as yup from "yup";
import validationSchemaNew from "../schedule/Schedule.validationSchema";
import validationSchemaDetails from "../details/Details.validationSchema";
import validationSchemaCategory from "../category/Category.validationSchema";

const validationSchema = (i18n: any) => {
  return yup.object({
    ...validationSchemaNew(i18n).fields,
    ...validationSchemaDetails(i18n).fields,
    ...validationSchemaCategory(i18n).fields
  });
};

export default validationSchema;
