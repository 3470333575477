import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    "& .MuiPaper-root": {
      padding: theme.spacing(2)
    }
  },
  colseIcon: {
    "&.MuiSvgIcon-root": {
      color: "#000000",
      cursor: "pointer"
    }
  }
}));

export default useStyles;
