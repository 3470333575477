import React from "react";
import Email from "@assets/images/icons/email.svg";
import SVG from "react-inlinesvg";
import useColorFromPalette from "@shared/hooks/useColorFromPalette";

const EmailIcon: React.FC<any> = (props) => {
  const { color = "turquoise.light", iconProps } = props;
  const colorValue = useColorFromPalette(color);

  return (
    <SVG
      src={Email}
      width="17px"
      height="17px"
      color={colorValue}
      {...iconProps}
    />
  );
};

export default EmailIcon;
