import React from "react";
import BoxInformation from "@shared/ui/molecules/BoxInformation/BoxInformation";
import BoxIcon from "@shared/ui/atoms/icons/BoxIcon/BoxIcon";
import routes from "@routes/routes";
import useQueryParams from "@shared/hooks/useQueryParams";
import { useTranslation } from "react-i18next";

const CompleteOrder: React.FC<any> = () => {
  const { i18n } = useTranslation();
  const { transport_order_id: transportOrderId } = useQueryParams<any>();

  const detailsOrder = () => {
    return routes.root.transportOrders.transportOrder({ id: transportOrderId });
  };

  const mainPage = () => {
    return routes.root();
  };

  return (
    <BoxInformation
      seoProps={{
        title: "Potwierdzenie wystawienia zlecenia",
        robots: "follow, noindex"
      }}
      actionLeftButton={detailsOrder}
      actionRightButton={mainPage}
      image={<BoxIcon />}
      title={i18n.t("ui.complete_order.title")}
      description={i18n.t("ui.complete_order.description")}
      nameButtonLeft={i18n.t("buttons.order")}
      nameButtonRight={i18n.t("buttons.understand")}
    />
  );
};

export default CompleteOrder;
