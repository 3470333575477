import React from "react";
import MUITextField from "@mui/material/TextField";
import useStyles from "./TextField.styles";

const TextField: React.FC<any> = (props) => {
  const {
    className,
    InputProps = {},
    inputProps: propsInputProps = {},
    ...restProps
  } = props;
  const { inputProps = {}, ...restInputProps } = InputProps;
  const { classes, cx } = useStyles();

  return (
    <MUITextField
      autoComplete="off"
      {...restProps}
      label=""
      variant="outlined"
      size="small"
      fullWidth
      className={cx(classes.root, className)}
      InputProps={{
        inputProps: {
          spellCheck: "false",
          autoCorrect: "off",
          autoCapitalize: "none",
          ...propsInputProps,
          ...inputProps
        },
        ...restInputProps
      }}
    />
  );
};

export default TextField;
