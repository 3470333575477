import React from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import TextField from "@shared/ui/atoms/TextField";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LoadingButton from "@shared/ui/atoms/LoadingButton";
import Stack from "@mui/material/Stack";
import Alert from "@shared/ui/atoms/Alert/Alert";
import BaseDialog from "@shared/ui/atoms/Dialog";
import Link from "@shared/ui/atoms/Link";
import {
  hidePasswordReminderDialog,
  selectPasswordReminder,
  showLoginDialog
} from "@store/dialogSlice";
import { useResetMutation } from "@api/TranstubeCore/usersPasswordApi";
import Typography from "@shared/ui/atoms/Typography";
import handleFormError from "@shared/utils/handleFormError";
import { Trans, useTranslation } from "react-i18next";
import validationSchema from "./PasswordReminderDialog.validationSchema";
import useStyles from "./PasswordReminderDialog.styles";

const PasswordReminder: React.FC<any> = () => {
  const { classes } = useStyles();
  const [resetPassword] = useResetMutation();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const handleSubmit = async (values: any, actions: any) => {
    const resetPasswordParams = {
      body: {
        data: {
          attributes: {
            email: values.email
          }
        }
      }
    };

    await resetPassword(resetPasswordParams)
      .unwrap()
      .then(() => {
        actions.resetForm();
        dispatch(hidePasswordReminderDialog());
      })
      .catch((ex: any) => {
        const pointers = {
          "/data": {
            field: "base",
            i18n: "base"
          },
          "/data/attributes/email": {
            field: "email",
            i18n: "user.email"
          }
        };

        ex.data?.errors?.forEach((error: any) => {
          handleFormError(pointers, error, i18n, actions);
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      base: null,
      email: ""
    },
    validationSchema: validationSchema(),
    onSubmit: handleSubmit
  });

  const closeDialog = () => {
    formik.resetForm();
    dispatch(hidePasswordReminderDialog());
  };

  const openPasswordReminder = useSelector(selectPasswordReminder);

  const openDialogLogin = () => {
    closeDialog();
    dispatch(showLoginDialog());
  };

  return (
    <BaseDialog handleOpen={openPasswordReminder} handleClose={closeDialog}>
      <DialogTitle sx={{ textAlign: "center" }}>
        <Trans i18nKey="ui.password_reminder.title" />
      </DialogTitle>

      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={2}>
            {formik.errors.base && (
              <Alert severity="error">{formik.errors.base}</Alert>
            )}
            <Stack spacing={1}>
              <Typography variant="body2" color="text.turquoise.dark">
                <strong>Adres e-mail</strong>
              </Typography>
              <TextField
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                placeholder="Wpisz adres e-mail"
                InputProps={{
                  startAdornment: (
                    <MailOutlineIcon className={classes.inputIcon} />
                  )
                }}
                autoComplete="on"
              />
            </Stack>
            <LoadingButton loading={Boolean(formik.isSubmitting)}>
              <Trans i18nKey="buttons.send" />
            </LoadingButton>

            <Link
              variant="body2"
              onClick={openDialogLogin}
              className={classes.linkToLogin}
            >
              <Trans i18nKey="ui.password_reminder.back_to_login" />
            </Link>
          </Stack>
        </form>
      </DialogContent>
    </BaseDialog>
  );
};

export default PasswordReminder;
