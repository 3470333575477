import { Box, Grid, Stack } from "@mui/material";
import Link from "@shared/ui/atoms/Link";
import Typography from "@shared/ui/atoms/Typography";
import Panel from "@shared/ui/molecules/Panel";
import ContainerTemplate from "@shared/ui/templates/ContainerTemplate";
import React from "react";
import ContactMailLink from "@shared/ui/molecules/ContactMailLink";
import { useTranslation } from "react-i18next";

const TermsOfService: React.FC<any> = () => {
  const { i18n } = useTranslation();

  return (
    <ContainerTemplate
      seoProps={{
        title: i18n.t("pages.termsOfService.head.title"),
        description: i18n.t("pages.termsOfService.head.description"),
        robots: "follow, noindex"
      }}
    >
      <Grid xs={12} sm={12} md={12}>
        <Panel>
          <Box>
            <Typography variant="h5" component="h1">
              Regulamin serwisu
            </Typography>
          </Box>
          <Stack spacing={1}>
            <Typography variant="body3" color="text.grey.main">
              Ostatnia aktualizacja: 24.10.2023 r.
            </Typography>
          </Stack>
          <Stack spacing={1}>
            <Box pt={2}>
              <Typography variant="h6" color="text.turquoise.dark">
                <strong>Spis treści</strong>
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2">
                <strong>1.</strong> Definicje
              </Typography>
              <Typography variant="body2">
                <strong>2.</strong> Postanowienia ogólne
              </Typography>
              <Typography variant="body2">
                <strong>3.</strong> Zasady korzystania z Serwisu
              </Typography>
              <Typography variant="body2">
                <strong>4.</strong> Reklamacje
              </Typography>
              <Typography variant="body2">
                <strong>5.</strong> Prawa autorskie
              </Typography>
              <Typography variant="body2">
                <strong>6.</strong> Zmiany w Regulaminie
              </Typography>
              <Typography variant="body2">
                <strong>7.</strong> Postanowienia końcowe
              </Typography>
            </Box>
          </Stack>
          <Stack spacing={0.5}>
            <Box pt={2}>
              <Typography variant="body2">
                <strong>1. Definicje</strong>
              </Typography>
            </Box>
            <Typography variant="body2">
              <strong>1.1. Regulamin </strong> - niniejszy regulamin.
            </Typography>
            <Typography variant="body2">
              <strong>1.2. Serwis</strong> - serwis internetowy dostępny pod
              adresem{" "}
              <Link target="_blank" href="https://www.transking.eu">
                https://www.transking.eu
              </Link>{" "}
              wraz z subdomenami, którego właścicielem jest Usługodawca.
            </Typography>
            <Typography variant="body2">
              <strong>1.3. Usługodawca</strong> - Dominika Pienczyn -
              TransKing.eu, ul. 8 Marca 7A, 84-120 Władysławowo, NIP:
              5871694793.
            </Typography>

            <Typography variant="body2">
              <strong>1.4. Usługa</strong> - usługa świadczona drogą
              elektroniczną przez Usługodawcę na rzecz Użytkownika w rozumieniu
              przepisów ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą
              elektroniczną.
            </Typography>

            <Typography variant="body2">
              <strong>1.5. Użytkownik</strong> - osoba fizyczna posiadająca
              pełną zdolność do czynności prawnych, a w wypadkach przewidzianych
              przez przepisy powszechnie obowiązujące także osoba fizyczna
              posiadająca ograniczoną zdolność do czynności prawnych, osoba
              prawna albo jednostka organizacyjna nieposiadająca osobowości
              prawnej, której ustawa przyznaje zdolność prawną, odwiedzająca
              Serwis lub korzystająca z jednej albo kilku Usług czy
              funkcjonalności.
            </Typography>
            <Typography variant="body2">
              <strong>1.6. Urządzenie</strong> - elektroniczne urządzenie takie
              jak komputer, smartfon, tablet i inne, za pośrednictwem którego
              można uzyskać dostęp do Serwisu.
            </Typography>
            <Typography variant="body2">
              <strong>1.7. Polityka prywatności</strong> - polityka prywatności
              Serwisu.
            </Typography>
            <Typography variant="body2">
              <strong>1.8. RODO</strong> - Rozporządzenia UE 2016/679 Parlamentu
              Europejskiego i Rady z dnia 27 kwietnia 2016 r. o ochronie osób
              fizycznych w związku z przetwarzaniem Danych Osobowych i w sprawie
              swobodnego przepływu takich danych oraz uchylenia Dyrektywy
              95/46/WE.
            </Typography>
            <Typography variant="body2">
              <strong>1.9. Zleceniodawca</strong> - Użytkownik korzystający z
              Usług Serwisu w celu poszukiwania Przewoźnika poprzez Zlecenie
              Transportu.
            </Typography>
            <Typography variant="body2">
              <strong>1.10. Przewoźnik</strong> - Użytkownik korzystający z
              Usług Serwisu w celu poszukiwania Zlecenia Transportu, złożenia
              oferty Zleceniodawcy na wykonanie usługi.
            </Typography>
            <Typography variant="body2">
              <strong>1.11. Konto Użytkownika</strong> - Usługa, oznaczona
              indywidualnym adresem e-mail i hasłem podanym przez Użytkownika,
              zbiór zasobów w systemie teleinformatycznym Usługodawcy, w którym
              gromadzone są dane podane przez Użytkownika oraz informacje o
              czynnościach w ramach Serwisu.
            </Typography>
            <Typography variant="body2">
              <strong>1.12. Pomoc</strong> - szczegółowe informacje dotyczące
              dostępnych Usług oraz inne istotne informacje wspierające
              Użytkowników w korzystaniu z Serwisu są dostępne pod adresem{" "}
              <Link target="_blank" href="https://www.transking.eu/pl/help">
                https://www.transking.eu/pl/help
              </Link>
              .
            </Typography>
            <Box pt={1}>
              <Typography variant="body2">
                <strong>2. Postanowienia ogólne</strong>
              </Typography>
            </Box>
            <Typography variant="body2">
              <strong>2.1.</strong> Regulamin określa zasady funkcjonowania
              Serwisu, zasady odpowiedzialności Użytkownika, zasady reklamacji
              usług, zasady ochrony praw autorskich.
            </Typography>
            <Typography variant="body2">
              <strong>2.2.</strong> Usługodawca świadczy Usługi udostępniania
              systemu teleinformatycznego służącego umożliwieniu Przewoźnikom
              oferowania usług transportu Zleceniodawcom oraz umożliwiająca
              Zleceniodawcom poszukiwanie Przewoźników, którzy wykonają dla
              Zleceniodawcy usługę transportu.
            </Typography>
            <Typography variant="body2">
              <strong>2.3.</strong> W celu korzystania z Serwisu i Usług
              świadczonych w jej ramach niezbędne jest Urządzenie końcowe,
              aktywne konto poczty elektronicznej e-mail, włączona obsługa
              plików cookies.
            </Typography>
            <Typography variant="body2">
              <strong>2.4.</strong> Usługodawca zastrzega sobie prawo do
              usunięcia konta Użytkownika bez podania przyczyn.
            </Typography>
            <Typography variant="body2">
              <strong>2.5.</strong> Usługodawca zastrzega sobie prawo do
              wyłączenia Serwisu nie podając przyczyn Użytkownikom.
            </Typography>
            <Box pt={1}>
              <Typography variant="body2">
                <strong>3. Zasady korzystania z Serwisu</strong>
              </Typography>
            </Box>
            <Typography variant="body2">
              <strong>3.1.</strong> Umowa o świadczenie Usług zostaje zawarta z
              chwilą korzystania przez Użytkownika z Serwisu. Umowa zawierana
              jest na czas nieoznaczony.
            </Typography>
            <Typography variant="body2">
              <strong>3.2.</strong> Pełny opis dostępnych Usług, w tym procedury
              rejestracji Konta Użytkownika oraz inne istotne informacje, można
              znaleźć na stronie Pomocy.
            </Typography>
            <Typography variant="body2">
              <strong>3.3.</strong> Użytkownik obowiązany jest do korzystania z
              Serwisu w sposób zgodny z prawem i dobrymi obyczajami mając na
              uwadze poszanowanie dóbr osobistych oraz praw autorskich i
              własności intelektualnej Usługodawcy oraz osób trzecich.
              Użytkownik obowiązany jest do wprowadzania danych zgodnych ze
              stanem faktycznym. Użytkownik obowiązuje zakaz dostarczania treści
              o charakterze bezprawnym.
            </Typography>
            <Box pt={1}>
              <Typography variant="body2">
                <strong>4. Reklamacje</strong>
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="body2" sx={{ pr: "5px" }}>
                <strong>4.1.</strong> Reklamacje dotyczące Usług należy kierować
                na adres
              </Typography>
              <ContactMailLink variant="body2" />

              <Typography>.</Typography>
            </Box>
            <Typography variant="body2">
              <strong>4.2.</strong> Użytkownik może złożyć reklamację w terminie
              14 dni od dnia wystąpienia zdarzenia będącego przyczyną
              reklamacji.
            </Typography>
            <Typography variant="body2">
              <strong>4.3.</strong> Opis reklamacji powinien zawierać:
            </Typography>
            <Typography variant="body2">
              4.3.1. informację i okoliczności dotyczących przedmiotu
              reklamacji, w szczególności rodzaju i daty wystąpienia
              nieprawidłowości lub braku zgodności z umową,
            </Typography>
            <Typography variant="body2">
              4.3.2. żądanie sposobu doprowadzenia do zgodności z umową lub
              odstąpieniu od umowy lub innego roszczenia,
            </Typography>
            <Typography variant="body2">
              4.3.3. dane kontaktowe składającego reklamację.
            </Typography>
            <Typography variant="body2">
              <strong>4.4.</strong> Reklamacje będą rozpatrywane w terminie 14
              dni od dnia otrzymania wiadomości przesłanej pocztą elektroniczną
              przez Usługodawcę.
            </Typography>
            <Typography variant="body2">
              <strong>4.5.</strong> Usługodawca rozpatrując reklamację, stosować
              będzie postanowienia Regulaminu.
            </Typography>
            <Typography variant="body2">
              <strong>4.6.</strong> O decyzji Usługodawcy Użytkownik zostanie
              powiadomiony przy pomocy poczty elektronicznej.
            </Typography>
            <Typography variant="body2">
              <strong>4.7.</strong> Usługodawca nie bierze odpowiedzialności za
              wynik transakcji zawieranych pomiędzy Zleceniodawcą a
              Przewoźnikiem przy użyciu Serwisu.
            </Typography>
            <Typography variant="body2">
              <strong>4.8.</strong> Usługodawca nie sprawuje nadzoru nad
              wykonaniem umowy zawartej pomiędzy Zleceniodawcą a Przewoźnikiem,
              ani nad terminowym jej wykonaniem, nie jest stroną w tej umowie.
            </Typography>
            <Typography variant="body2">
              <strong>4.9.</strong> Wszelkie uwagi oraz reklamacje dotyczące
              przebiegu transakcji zawieranych pomiędzy Zleceniodawcą a
              Przewoźnikiem, które są dokonywane zgodnie z poprzednimi zapisami,
              Użytkownicy powinni kierować bezpośrednio do odpowiedniego
              Zleceniodawcy lub Przewoźnika.
            </Typography>
            <Box pt={1}>
              <Typography variant="body2">
                <strong>5. Prawa autorskie</strong>
              </Typography>
            </Box>
            <Typography variant="body2">
              <strong>5.1.</strong> O ile inne postanowienia Regulaminu nie
              stanowią inaczej, prawa wyłączne do treści udostępnianych w
              Serwisie, w szczególności prawa autorskie, znaki towarowe
              Usługodawcy i innych dostępnych w Serwisie, wchodzące w ich skład
              elementy graficzne, oprogramowanie oraz prawa w zakresie baz
              danych oraz inne prawa własności intelektualnej podlegają ochronie
              prawnej i przysługują ich właścicielom, Usługodawcy lub podmiotom,
              z którymi Usługodawca zawarł stosowne umowy.
            </Typography>
            <Box pt={1}>
              <Typography variant="body2">
                <strong>6. Zmiany w Regulaminie</strong>
              </Typography>
            </Box>
            <Typography variant="body2">
              <strong>6.1.</strong> Regulamin jest na bieżąco weryfikowany i w
              razie potrzeby aktualizowany.
            </Typography>
            <Typography variant="body2">
              <strong>6.2.</strong> Data aktualizacji jest umieszczona na górze
              Regulaminu.
            </Typography>
            <Typography variant="body2">
              <strong>6.3.</strong> Modyfikacje mogą odbywać się bez
              powiadamiania Użytkownika.
            </Typography>
            <Typography variant="body2">
              <strong>6.4.</strong> Użytkownik może zostać powiadomiony w
              Serwisie o istotnych zmianach Regulaminu.
            </Typography>
            <Box pt={1}>
              <Typography variant="body2">
                <strong>7. Postanowienia końcowe</strong>
              </Typography>
            </Box>
            <Typography variant="body2">
              <strong>7.1.</strong> Prawem właściwym dla wszystkich stosunków
              prawnych pomiędzy Użytkownikiem, a Usługodawcą w związku z
              korzystaniem z Serwisu jest prawo polskie.
            </Typography>
            <Typography variant="body2">
              <strong>7.2.</strong> Użytkownik ma możliwość przechowania treści
              zawartej w Regulaminie poprzez archiwizowanie ich na trwałym
              nośniku we własnym zakresie.
            </Typography>
            <Typography variant="body2">
              <strong>7.3.</strong> W sprawach nieuregulowanych w niniejszym
              Regulaminie mają zastosowanie powszechnie obowiązujące przepisy
              prawa polskiego.
            </Typography>
          </Stack>
        </Panel>
      </Grid>
    </ContainerTemplate>
  );
};

export default TermsOfService;
