import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

const useSectionStyles = makeStyles()((theme: Theme) => ({
  iconMoney: {
    width: 23,
    cursor: "pointer",
    textAlign: "center",
    p: 1,
    color: theme.palette.turquoise.light
  }
}));

export default useSectionStyles;
