import React from "react";
import {
  MuiTelInput,
  MuiTelInputCountry,
  MuiTelInputContinent
} from "mui-tel-input";
import useStyles from "./CustomMuiTelInput.styles";

const CustomMuiTelInput: React.FC<any> = (props) => {
  const { className } = props;
  const { classes, cx } = useStyles();
  const continents: MuiTelInputContinent[] = ["EU"];
  const defaultCountry: MuiTelInputCountry = "PL";

  return (
    <MuiTelInput
      {...props}
      langOfCountryName="pl"
      forceCallingCode
      focusOnSelectCountry
      continents={continents}
      defaultCountry={defaultCountry}
      size="small"
      variant="outlined"
      fullWidth
      className={cx(classes.root, className)}
    />
  );
};

export default CustomMuiTelInput;
