import * as yup from "yup";

const validationSchema = (i18n: any) => {
  return yup.object({
    categories: yup.object().shape({
      others: yup.boolean().required(),
      people: yup
        .boolean()
        .notRequired()
        .when("others", {
          is: (val: any) => val !== true,
          then: yup
            .boolean()
            .oneOf([true], i18n.t("yup.validations.base.no_category_selected")),
          otherwise: yup.boolean().required()
        })
    }),
    groupedTransportOrderLineItems: yup
      .array()
      .min(1)
      .of(
        yup
          .object()
          .shape({
            transportOrderLineItems: yup
              .array()
              .min(1)
              .of(
                yup.object().shape({
                  quantity: yup
                    .number()
                    .required()
                    .positive()
                    .integer()
                    .moreThan(0)
                    .lessThan(1000),
                  dimensionX: yup.number().when("category", {
                    is: "others",
                    then: (schema) =>
                      schema
                        .required()
                        .positive()
                        .integer()
                        .moreThan(0)
                        .lessThan(1000)
                  }),
                  dimensionY: yup.number().when("category", {
                    is: "others",
                    then: (schema) =>
                      schema
                        .required()
                        .positive()
                        .integer()
                        .moreThan(0)
                        .lessThan(1000)
                  }),
                  dimensionZ: yup.number().when("category", {
                    is: "others",
                    then: (schema) =>
                      schema
                        .required()
                        .positive()
                        .integer()
                        .moreThan(0)
                        .lessThan(1000)
                  }),
                  weight: yup.number().when("category", {
                    is: "others",
                    then: (schema) =>
                      schema
                        .required()
                        .positive()
                        .integer()
                        .moreThan(0)
                        .lessThan(100000)
                  })
                })
              )
              .required()
          })
          .required()
      )
      .required()
  });
};

export default validationSchema;
