import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@shared/ui/atoms/TextField";
import Typography from "@shared/ui/atoms/Typography";
import { FormControlLabel, Radio, Stack } from "@mui/material";
import LoadingButton from "@shared/ui/atoms/LoadingButton";
import { selectCurrentUser, setCurrentUser } from "@store/authSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  useUpdateAccountMutation,
  useCancelChangeMutation,
  useLazyGetAccountQuery
} from "@api/TranstubeCore/accountApi";
import { useFormik } from "formik";
import CustomMuiTelInput from "@shared/ui/atoms/CustomMuiTelInput";
import RadioColorPicker from "@shared/ui/atoms/RadioColorPicker/RadioColorPicker";
import PasswordTextField from "@shared/ui/atoms/PasswordTextField";
import UserPanelTemplate from "@shared/ui/templates/UserPanelTemplate";
import UserAvatar from "@shared/ui/molecules/UserAvatar";
import Link from "@shared/ui/atoms/Link";
import Panel from "@shared/ui/molecules/Panel/Panel";
import handleFormError from "@shared/utils/handleFormError";
import { useTranslation } from "react-i18next";
import RadioGroup from "@mui/material/RadioGroup";
import LinearDeterminate from "@shared/ui/molecules/LinearDeterminate";
import validationSchema from "./AccountInformation.validationSchema";

const AccountInformation: React.FC<any> = () => {
  const currentUser = useSelector(selectCurrentUser);
  const [open, setOpen] = useState<any>(false);
  const [updateAccount] = useUpdateAccountMutation();
  const [deleteChange] = useCancelChangeMutation();
  const [colors, setColors] = useState<any>([]);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const [getAccount] = useLazyGetAccountQuery();

  const handleSubmit = async (values: any, actions: any) => {
    const attributes: { [index: string]: any } = {
      avatar_color: values.avatarColor,
      name: values.name,
      email: values.email,
      phone_number: values.phoneNumber
    };

    if (values.password !== "**********") {
      attributes.password = values.password;
    }

    const updateAccountParams = {
      body: {
        data: {
          attributes
        }
      }
    };

    await updateAccount(updateAccountParams)
      .unwrap()
      .then(async () => {
        await getAccount({})
          .unwrap()
          .then((payload) => {
            dispatch(setCurrentUser(payload.data));
            actions.setTouched({}, false);
          });
      })
      .catch((ex: any) => {
        const pointers = {
          "/data/attributes/email": {
            field: "email",
            i18n: "user.email"
          },
          "/data/attributes/password": {
            field: "password",
            i18n: "user.password"
          },
          "/data/attributes/name": {
            field: "name",
            i18n: "user.name"
          },
          "/data/attributes/phone_number": {
            field: "phoneNumber",
            i18n: "user.phoneNumber"
          },
          "/data/attributes/company_id": {
            field: "company.nip",
            i18n: "user.company.nip"
          }
        };

        ex.data?.errors?.forEach((error: any) => {
          handleFormError(pointers, error, i18n, actions);
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      avatarColor: currentUser.avatarColor,
      email: currentUser.email,
      password: "**********",
      name: currentUser.name,
      phoneNumber: currentUser.phoneNumber,
      company: {
        id: currentUser.company?.id,
        nip: currentUser.company?.nip
      }
    },
    validationSchema: validationSchema(),
    onSubmit: handleSubmit
  });

  useEffect(() => {
    setColors(
      [
        { name: "violet", value: "#8C5AC9" },
        { name: "pink", value: "#E951B5" },
        { name: "purple", value: "#FF6191" },
        { name: "coral", value: "#FF8F69" },
        { name: "orange", value: "#FFC547" },
        { name: "olive", value: "#ACE1AF" },
        { name: "cyan", value: "#30D5C8" },
        { name: "blue", value: "#0083D5" },
        { name: "rust", value: "#D03B29" },
        { name: "lavender", value: "#97B7FF" },
        { name: "tan", value: "#B5876D" },
        { name: "mustard", value: "#FBDC58" }
      ].sort((x) => {
        return x.name === currentUser.avatarColor ? -1 : 0;
      })
    );
  }, [currentUser.avatarColor]);

  const verificationTitle = () => {
    if (currentUser.meta.is_confirmed === true) {
      return (
        <Typography variant="body2" color="error.main">
          (zweryfikowany)
        </Typography>
      );
    }

    return (
      <Typography variant="body2" color="error.main">
        (niezweryfikowany)
      </Typography>
    );
  };

  const cancelChange = async (actions: any) => {
    await deleteChange({ body: {} })
      .unwrap()
      .then((payload) => {
        dispatch(setCurrentUser(payload.data));
      })
      .catch((ex: any) => {
        const pointers = {
          "/data/attributes/email": {
            field: "email",
            i18n: "user.email"
          }
        };

        ex.data?.errors?.forEach((error: any) => {
          handleFormError(pointers, error, i18n, actions);
        });
      });
  };

  const company = () => {
    if (currentUser.meta.has_company === true) {
      return (
        <Box sx={{ border: "1px solid #96c5e8", p: 2 }}>
          <Typography variant="body2" color="text.grey.main">
            {currentUser.company?.name}
          </Typography>
          <Typography variant="body2" color="text.grey.main">
            {currentUser.company?.streetAddress}
          </Typography>
          <Typography variant="body2" color="text.grey.main">
            {currentUser.company?.postCode} {currentUser.company?.city}
          </Typography>
        </Box>
      );
    }

    return <></>;
  };

  const isVerified = () => {
    if (
      currentUser.meta.unconfirmed_email !== null &&
      currentUser.meta.unconfirmed_email !== currentUser.email
    ) {
      return (
        <>
          <Box display="inline-flex">
            <Stack direction="row" spacing={0.5}>
              <Typography variant="body2" color="error.main">
                Odbierz pocztę z adresu
              </Typography>
              <Typography variant="body2" color="error.main">
                <strong>{currentUser.meta.unconfirmed_email}</strong>
              </Typography>
              <Typography variant="body2" color="error.main">
                i potwierdź swoją zmianę adresu e-mail.
              </Typography>
            </Stack>
          </Box>
          <Box display="inline-flex">
            <Stack direction="row" spacing={1}>
              <Link variant="body2" underline="none" onClick={cancelChange}>
                anuluj zmianę
              </Link>
            </Stack>
          </Box>
        </>
      );
    }

    return <></>;
  };

  const selectedAccountType = () => {
    if (currentUser.meta.has_company === false) {
      return (
        <RadioGroup name="accountType" value="private_person">
          <Box display="flex">
            <FormControlLabel
              disabled
              value="private_person"
              control={<Radio />}
              label="Osoba prywatna"
            />
            <FormControlLabel
              disabled
              value="company"
              control={<Radio />}
              label="Firma"
            />
          </Box>
        </RadioGroup>
      );
    }

    return (
      <RadioGroup name="accountType" value="company">
        <Box display="flex">
          <FormControlLabel
            disabled
            value="private_person"
            control={<Radio />}
            label="Osoba prywatna"
          />
          <FormControlLabel
            disabled
            value="company"
            control={<Radio />}
            label="Firma"
          />
        </Box>
      </RadioGroup>
    );
  };

  const displayNip = () => {
    if (currentUser.meta.has_company === false) return <></>;

    return (
      <Box>
        <Stack spacing={1}>
          <Typography variant="body2" color="text.turquoise.dark">
            <strong>NIP</strong>
          </Typography>
          <TextField
            disabled
            name="company.nip"
            value={formik.values.company?.nip}
            onChange={formik.handleChange}
            error={
              formik.touched.company?.nip && Boolean(formik.errors.company?.nip)
            }
            helperText={
              formik.touched.company?.nip && formik.errors.company?.nip
            }
            placeholder="NIP"
            size="small"
          />
        </Stack>
      </Box>
    );
  };

  if (!currentUser) return <LinearDeterminate />;

  return (
    <UserPanelTemplate seoProps={{ title: "Moje konto" }}>
      <Stack
        direction={{ xs: "column-reverse", md: "row" }}
        spacing={2}
        alignItems="flex-start"
      >
        <Box sx={{ width: "100%" }}>
          <Panel>
            <form onSubmit={formik.handleSubmit}>
              <Stack spacing={2}>
                {selectedAccountType()}
                {displayNip()}
                {company()}
                <Box>
                  <Stack spacing={1}>
                    <Typography variant="body2" color="text.turquoise.dark">
                      <strong>Imię i nazwisko</strong>
                    </Typography>
                    <TextField
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                      placeholder="Imię i nazwisko"
                      size="small"
                    />
                  </Stack>
                </Box>
                <Box>
                  <Stack spacing={1}>
                    <Typography variant="body2" color="text.turquoise.dark">
                      <strong>Kolor avatara</strong>
                    </Typography>
                    <Stack direction="row" spacing={0.5}>
                      {colors.slice(0, 6).map((color: any) => (
                        <RadioColorPicker
                          key={color.name}
                          bgColor={color.value}
                          checked={color.name === formik.values.avatarColor}
                          onClick={() =>
                            formik.setFieldValue("avatarColor", color.name)
                          }
                        />
                      ))}
                    </Stack>
                    {open === false ? (
                      <Link
                        variant="body2"
                        underline="none"
                        onClick={() => setOpen(true)}
                      >
                        pokaż więcej
                      </Link>
                    ) : (
                      <>
                        <Stack direction="row" spacing={0.5}>
                          {colors.slice(6).map((color: any) => (
                            <RadioColorPicker
                              key={color.name}
                              bgColor={color.value}
                              checked={color.name === formik.values.avatarColor}
                              onClick={() =>
                                formik.setFieldValue("avatarColor", color.name)
                              }
                            />
                          ))}
                        </Stack>

                        <Link
                          variant="body2"
                          underline="none"
                          onClick={() => setOpen(false)}
                        >
                          zamknij
                        </Link>
                      </>
                    )}
                  </Stack>
                </Box>
                <Box>
                  <Stack spacing={1}>
                    <Box display="inline-flex">
                      <Stack direction="row" spacing={1}>
                        <Typography variant="body2" color="text.turquoise.dark">
                          <strong>Adres e-mail</strong>
                        </Typography>
                        {verificationTitle()}
                      </Stack>
                    </Box>
                    <TextField
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                      name="email"
                      placeholder="E-mail"
                      autoComplete="on"
                    />
                    {isVerified()}
                  </Stack>
                </Box>
                <Box>
                  <Stack spacing={1}>
                    <Typography variant="body2" color="text.turquoise.dark">
                      <strong>Hasło</strong>
                    </Typography>
                    <PasswordTextField
                      value={formik.values.password}
                      name="password"
                      onFocus={(value: any) => {
                        if (
                          value.target.value === formik.initialValues.password
                        ) {
                          formik.setFieldValue("password", "");
                        }
                      }}
                      onBlur={(value: any) => {
                        if (value.target.value === "") {
                          formik.setFieldValue(
                            "password",
                            formik.initialValues.password
                          );
                        }
                      }}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                      onChange={formik.handleChange}
                    />
                  </Stack>
                </Box>
                <Box>
                  <Stack spacing={1}>
                    <Typography variant="body2" color="text.turquoise.dark">
                      <strong>Telefon</strong>
                    </Typography>
                    <CustomMuiTelInput
                      name="phoneNumber"
                      value={formik.values.phoneNumber}
                      onChange={(value: any) => {
                        formik.setFieldValue("phoneNumber", value);
                      }}
                      error={
                        formik.touched.phoneNumber &&
                        Boolean(formik.errors.phoneNumber)
                      }
                      helperText={
                        formik.touched.phoneNumber && formik.errors.phoneNumber
                      }
                    />
                  </Stack>
                </Box>
                <Box sx={{ mt: 1 }}>
                  <LoadingButton
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                    loading={Boolean(formik.isSubmitting)}
                  >
                    Zapisz
                  </LoadingButton>
                </Box>
              </Stack>
            </form>
          </Panel>
        </Box>

        <Box>
          <Panel sx={{ padding: 1 }}>
            <UserAvatar size={128} color={formik.values.avatarColor}>
              {currentUser.displayName}
            </UserAvatar>
          </Panel>
        </Box>
      </Stack>
    </UserPanelTemplate>
  );
};

export default AccountInformation;
