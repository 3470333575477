import React from "react";
import Box from "@mui/material/Box";
import { Stack } from "@mui/material";
import { useParams, NavLink } from "react-router-dom";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import routes from "@routes/routes";
import Typography from "@shared/ui/atoms/Typography";
import TransportOrderListIcon from "@shared/ui/atoms/icons/TransportOrderListIcon";
import ListIcon from "@shared/ui/atoms/icons/ListIcon";
import ChatIcon from "@shared/ui/atoms/icons/ChatIcon";
import useStyles from "./SideMenuOrder.styles";

const SideMenuOrder: React.FC<any> = () => {
  const { classes } = useStyles();
  const { id } = useParams<any>();

  return (
    <Box sx={{ width: 220 }}>
      <NavLink
        to={routes.root.transportOrders.transportOrder({
          id
        })}
        className={classes.navLink}
        exact
      >
        <Box alignSelf="center" display="flex" className={classes.box}>
          <Stack spacing={1.5} direction="row">
            <Box className={classes.boxItem}>
              <TransportOrderListIcon />
            </Box>
            <Box className={classes.boxItem} textAlign="center">
              <Typography
                variant="body2"
                color="text.turquoise.dark"
                sx={{
                  textDecorationLine: "none"
                }}
              >
                Szczegóły
              </Typography>
            </Box>
          </Stack>
          <Box className={classes.boxItem} alignSelf="flex-end">
            <ArrowRightIcon className="arrow" />
          </Box>
        </Box>
      </NavLink>

      <NavLink
        to={routes.root.transportOrders.transportOrder.offers({
          id
        })}
        className={classes.navLink}
        exact
      >
        <Box alignSelf="center" display="flex" className={classes.box}>
          <Stack spacing={1.5} direction="row">
            <Box className={classes.boxItem}>
              <ListIcon />
            </Box>

            <Box className={classes.boxItem} textAlign="center">
              <Typography
                variant="body2"
                color="text.turquoise.dark"
                sx={{
                  textDecorationLine: "none"
                }}
              >
                Oferty
              </Typography>
            </Box>
          </Stack>
          <Box className={classes.boxItem} alignSelf="flex-end">
            <ArrowRightIcon className="arrow" />
          </Box>
        </Box>
      </NavLink>

      <NavLink
        to={routes.root.transportOrders.transportOrder.messages({
          id
        })}
        className={classes.navLink}
        exact
      >
        <Box alignSelf="center" display="flex" className={classes.box}>
          <Stack spacing={1.5} direction="row">
            <Box className={classes.boxItem}>
              <ChatIcon />
            </Box>

            <Box className={classes.boxItem} textAlign="center">
              <Typography
                variant="body2"
                color="text.turquoise.dark"
                sx={{
                  textDecorationLine: "none"
                }}
              >
                Wiadomości
              </Typography>
            </Box>
          </Stack>
          <Box className={classes.boxItem} alignSelf="flex-end">
            <ArrowRightIcon className="arrow" />
          </Box>
        </Box>
      </NavLink>
    </Box>
  );
};

export default SideMenuOrder;
