import React from "react";
import OriginMarker from "@assets/images/icons/origin_marker.svg";
import SVG from "react-inlinesvg";
import useStyles from "./OriginMarkerIcon.styles";

const OriginMarkerIcon: React.FC<any> = (props) => {
  const { classes } = useStyles();

  return (
    <SVG
      src={OriginMarker}
      width="19px"
      height="19px"
      className={classes.root}
      {...props}
    />
  );
};

export default OriginMarkerIcon;
