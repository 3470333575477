import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  text: {
    fontSize: "0.875rem"
  },
  inputIcon: {
    "&.MuiSvgIcon-root": {
      marginRight: theme.spacing(2),
      fontSize: "1rem"
    }
  }
}));

export default useStyles;
