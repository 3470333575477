import currency from "currency.js";

const currencyOptions: { [key: string]: currency.Options } = {
  EUR: {
    pattern: `!#`,
    negativePattern: "!-#",
    symbol: "€",
    separator: ".",
    decimal: ","
  },
  PLN: {
    pattern: `# !`,
    negativePattern: "-# !",
    symbol: "zł",
    separator: " ",
    decimal: ","
  },
  GBP: {
    pattern: `!#`,
    negativePattern: "!-#",
    symbol: "£",
    separator: ",",
    decimal: "."
  }
};

const currencyPrice = (value: currency.Any): currency => {
  return currency(value);
};

const formattedCurrencyPrice = (
  value: currency.Any,
  currencyIsoCode: string
): string => {
  return currencyPrice(value).format(currencyOptions[currencyIsoCode]);
};

export { formattedCurrencyPrice };
export default currencyPrice;
