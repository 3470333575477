import React from "react";
import DestinationMarker from "@assets/images/icons/destination_marker.svg";
import SVG from "react-inlinesvg";
import useStyles from "./DestinationMarkerIcon.styles";

const DestinationMarkerIcon: React.FC<any> = (props) => {
  const { classes } = useStyles();

  return (
    <SVG
      src={DestinationMarker}
      width="19px"
      height="19px"
      className={classes.root}
      {...props}
    />
  );
};

export default DestinationMarkerIcon;
