import React from "react";
import Box from "@mui/material/Box";
import { Stack } from "@mui/material";
import { NavLink } from "react-router-dom";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import routes from "@routes/routes";
import Typography from "@shared/ui/atoms/Typography";
import MonitorIcon from "@shared/ui/atoms/icons/MonitorIcon/MonitorIcon";
import CustomerIcon from "@shared/ui/atoms/icons/CustomerIcon";
import OrdersIcon from "@shared/ui/atoms/icons/OrdersIcon/OrdersIcon";
import TransactionIcon from "@shared/ui/atoms/icons/TransactionIcon";
import OfferIcon from "@shared/ui/atoms/icons/OfferIcon/OfferIcon";
import InvoiceIcon from "@shared/ui/atoms/icons/InvoiceIcon";
import useStyles from "./SideMenuUser.styles";

const SideMenu: React.FC<any> = () => {
  const { classes } = useStyles();

  return (
    <Box sx={{ width: 220 }}>
      <NavLink to={routes.root.account()} className={classes.navLink} exact>
        <Box alignSelf="center" display="flex" className={classes.box}>
          <Stack spacing={1.5} direction="row">
            <Box className={classes.boxItem}>
              <CustomerIcon />
            </Box>

            <Box className={classes.boxItem} textAlign="center">
              <Typography
                variant="body2"
                color="text.turquoise.dark"
                sx={{
                  textDecorationLine: "none"
                }}
              >
                Moje konto
              </Typography>
            </Box>
          </Stack>
          <Box className={classes.boxItem} alignSelf="flex-end">
            <ArrowRightIcon className="arrow" />
          </Box>
        </Box>
      </NavLink>
      <NavLink
        to={routes.root.account.sessions()}
        className={classes.navLink}
        exact
      >
        <Box alignSelf="center" display="flex" className={classes.box}>
          <Stack spacing={1.5} direction="row">
            <Box className={classes.boxItem}>
              <MonitorIcon />
            </Box>
            <Box className={classes.boxItem} textAlign="center">
              <Typography
                variant="body2"
                color="text.turquoise.dark"
                sx={{
                  textDecorationLine: "none"
                }}
              >
                Sesje
              </Typography>
            </Box>
          </Stack>
          <Box className={classes.boxItem} alignSelf="flex-end">
            <ArrowRightIcon className="arrow" />
          </Box>
        </Box>
      </NavLink>
      <NavLink
        to={routes.root.account.transportOrders()}
        isActive={(match, location) => {
          return (
            location.pathname === routes.root.account.transportOrders() ||
            location.pathname === routes.root.account.transportOrders.inactive()
          );
        }}
        className={classes.navLink}
        exact
      >
        <Box alignSelf="center" display="flex" className={classes.box}>
          <Stack spacing={1.5} direction="row">
            <Box className={classes.boxItem}>
              <OrdersIcon />
            </Box>
            <Box className={classes.boxItem} textAlign="center">
              <Typography
                variant="body2"
                color="text.turquoise.dark"
                sx={{
                  textDecorationLine: "none"
                }}
              >
                Moje zlecenia
              </Typography>
            </Box>
          </Stack>
          <Box className={classes.boxItem} alignSelf="flex-end">
            <ArrowRightIcon className="arrow" />
          </Box>
        </Box>
      </NavLink>
      <NavLink
        to={routes.root.account.purchaseOrders()}
        className={classes.navLink}
        exact
      >
        <Box alignSelf="center" display="flex" className={classes.box}>
          <Stack spacing={1.5} direction="row">
            <Box className={classes.boxItem}>
              <TransactionIcon />
            </Box>

            <Box className={classes.boxItem} textAlign="center">
              <Typography
                variant="body2"
                color="text.turquoise.dark"
                sx={{
                  textDecorationLine: "none"
                }}
              >
                Moje zakupy
              </Typography>
            </Box>
          </Stack>
          <Box className={classes.boxItem} alignSelf="flex-end">
            <ArrowRightIcon className="arrow" />
          </Box>
        </Box>
      </NavLink>
      <NavLink
        to={routes.root.account.offers()}
        className={classes.navLink}
        exact
      >
        <Box alignSelf="center" display="flex" className={classes.box}>
          <Stack spacing={1.5} direction="row">
            <Box className={classes.boxItem}>
              <OfferIcon />
            </Box>

            <Box className={classes.boxItem} textAlign="center">
              <Typography
                variant="body2"
                color="text.turquoise.dark"
                sx={{
                  textDecorationLine: "none"
                }}
              >
                Moje oferty
              </Typography>
            </Box>
          </Stack>
          <Box className={classes.boxItem} alignSelf="flex-end">
            <ArrowRightIcon className="arrow" />
          </Box>
        </Box>
      </NavLink>
      <NavLink
        to={routes.root.account.invoices()}
        className={classes.navLink}
        exact
      >
        <Box alignSelf="center" display="flex" className={classes.box}>
          <Stack spacing={1.5} direction="row">
            <Box className={classes.boxItem}>
              <InvoiceIcon />
            </Box>

            <Box className={classes.boxItem} textAlign="center">
              <Typography
                variant="body2"
                color="text.turquoise.dark"
                sx={{
                  textDecorationLine: "none"
                }}
              >
                Moje faktury
              </Typography>
            </Box>
          </Stack>
          <Box className={classes.boxItem} alignSelf="flex-end">
            <ArrowRightIcon className="arrow" />
          </Box>
        </Box>
      </NavLink>
    </Box>
  );
};

export default SideMenu;
