import React from "react";
import TransportOrderList from "@assets/images/icons/transport_order_list.svg";
import SVG from "react-inlinesvg";
import useColorFromPalette from "@shared/hooks/useColorFromPalette";

const TransportOrderListIcon: React.FC<any> = (props) => {
  const { color = "turquoise.main", ...iconProps } = props;
  const colorValue = useColorFromPalette(color);

  return (
    <SVG
      src={TransportOrderList}
      width="25px"
      height="25px"
      color={colorValue}
      {...iconProps}
    />
  );
};

export default TransportOrderListIcon;
