import { Box, Grid, Stack } from "@mui/material";
import Typography from "@shared/ui/atoms/Typography";
import IODMailLink from "@shared/ui/molecules/IODMailLink";
import Panel from "@shared/ui/molecules/Panel";
import ContainerTemplate from "@shared/ui/templates/ContainerTemplate";
import React from "react";
import { useTranslation } from "react-i18next";

const PrivacyPolicy: React.FC<any> = () => {
  const { i18n } = useTranslation();

  return (
    <ContainerTemplate
      seoProps={{
        title: i18n.t("pages.privacyPolicy.head.title"),
        description: i18n.t("pages.privacyPolicy.head.description"),
        robots: "follow, noindex"
      }}
    >
      <Grid xs={12} sm={12} md={12}>
        <Panel>
          <Box>
            <Typography variant="h5" component="h1">
              Polityka prywatności
            </Typography>
          </Box>
          <Stack spacing={1}>
            <Typography variant="body3" color="text.grey.main">
              Ostatnia aktualizacja: 24.10.2023 r.
            </Typography>
          </Stack>
          <Stack spacing={1}>
            <Box pt={2}>
              <Typography variant="h6" color="text.turquoise.dark">
                <strong>Spis treści</strong>
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2">
                <strong>1.</strong> Informacje ogólne
              </Typography>
              <Typography variant="body2">
                <strong>2.</strong> Cel i podstawa przetwarzania danych
                osobowych
              </Typography>
              <Typography variant="body2">
                <strong>3.</strong> Okresy przetwarzania danych osobowych
              </Typography>
              <Typography variant="body2">
                <strong>4.</strong> Przekazywanie danych osobowych
              </Typography>
              <Typography variant="body2">
                <strong>5.</strong> Prawa właścicieli danych osobowych
              </Typography>
              <Typography variant="body2">
                <strong>6.</strong> Informacje kontaktowe
              </Typography>
              <Typography variant="body2">
                <strong>7.</strong> Zmiany w polityce ochrony prywatności
              </Typography>
            </Box>
          </Stack>
          <Stack spacing={0.5}>
            <Box pt={2}>
              <Typography variant="body2">
                <strong>1. Informacje ogólne</strong>
              </Typography>
            </Box>
            <Typography variant="body2">
              <strong>1.1.</strong> Polityka prywatności przedstawia
              postępowanie administratora danych osobowych w zakresie
              przetwarzania danych osobowych gromadzonych przez Serwis.
            </Typography>
            <Typography variant="body2">
              <strong>1.2.</strong> Polityka prywatności ma charakter
              informacyjny i nie nakłada na Użytkowników żadnych obowiązków.
            </Typography>
            <Typography variant="body2">
              <strong>1.3.</strong> Administratorem danych osobowych jest
              Usługodawca.
            </Typography>
            <Typography variant="body2">
              <strong>1.4.</strong> Usługodawca chroni prywatność i przekazywane
              mu lub zbierane przez niego w inny sposób dane osobowe z
              zachowaniem należytej staranności. W tym celu używa odpowiednich
              środków technicznych i organizacyjnych, a w szczególności
              zabezpiecza dane osobowe Użytkowników przed ich udostępnieniem
              osobom nieupoważnionym, nieuprawnioną modyfikacją oraz
              przetwarzaniem niezgodnym z przepisami prawa.
            </Typography>

            <Typography variant="body2">
              <strong>1.5.</strong> Polityka prywatności ma zastosowanie w
              każdej sytuacji, w której Usługodawca przetwarza dane osobowe
              Użytkowników.
            </Typography>
            <Typography variant="body2">
              <strong>1.6.</strong> Usługodawca oświadcza, że zbierane przez
              niego dane osobowe są przetwarzane zgodnie z prawem oraz nie będą
              przetwarzane dla celów innych aniżeli określone w Polityce
              prywatności.
            </Typography>
            <Typography variant="body2">
              <strong>1.7.</strong> Usługodawca przetwarza dane osobowe nie
              dłużej niż jest to konieczne dla celów przetwarzania określonych w
              Polityce prywatności.
            </Typography>
            <Typography variant="body2">
              <strong>1.8.</strong> Wszelkie wewnętrzne procedury i zalecenia w
              oparciu, o które Usługodawca przetwarza dane osobowe Użytkowników
              są zgodne z przepisami prawa ochrony danych osobowych, w tym z
              RODO, wytycznymi polskich i europejskich organów i organizacji
              zajmujących się ochroną danych osobowych.
            </Typography>
            <Typography variant="body2">
              <strong>1.9.</strong> Wyrazy występujące w niniejszej polityce
              prywatności i rozpoczynające się dużą literą należy rozumieć
              zgodnie z ich definicją zawartą w Regulaminie dostępnym w
              Serwisie.
            </Typography>
            <Typography variant="body2">
              <strong>1.10.</strong> Serwis może zawierać odnośniki do innych
              stron internetowych. Usługodawca namawia, by po przejściu na inne
              strony, zapoznać się z polityką prywatności tam ustaloną. Polityka
              prywatności dotyczy tylko Serwisu.
            </Typography>
            <Box pt={1}>
              <Typography variant="body2">
                <strong>
                  2. Cel i podstawa przetwarzania danych osobowych
                </strong>
              </Typography>
            </Box>
            <Typography variant="body2">
              <strong>2.1.</strong> Dane osobowe Użytkownika przetwarzane są
              przez Usługodawcę w celu:
            </Typography>
            <Typography variant="body2">
              2.1.1. korzystania przez Użytkownika z Usług Serwisu lub
              realizacji działań przed zawarciem umowy (art. 6 ust. 1 lit. b
              RODO);
            </Typography>
            <Typography variant="body2">
              2.1.2. wykonania obowiązku prawnego ciążącego na Usługodawcy (art.
              6 ust. 1 lit. c RODO);
            </Typography>
            <Typography variant="body2">
              2.1.3. prowadzenia statystyk i analiza ruchu w Serwisie (art. 6
              ust. 1 lit. f RODO);
            </Typography>
            <Typography variant="body2">
              2.1.4. zebrania informacji o aktywności i preferencji Użytkowników
              (art. 6 ust. 1 lit. f RODO);
            </Typography>
            <Typography variant="body2">
              2.1.5. udzielenia odpowiedzi na pytania, reklamację (art. 6 ust. 1
              lit. f RODO);
            </Typography>
            <Typography variant="body2">
              2.1.6. ustalenia, dochodzenia lub obrony roszczeń jakie może
              podnosić Usługodawca lub jakie mogą być podnoszone wobec
              Usługodawcy (art. 6 ust. 1 lit. f RODO);
            </Typography>
            <Typography variant="body2">
              2.1.7. prowadzenia statystyk i analiza ruchu w Serwisie (art. 6
              ust. 1 lit. f RODO);
            </Typography>
            <Typography variant="body2">
              2.1.8. wykrycia zdarzeń mogące zagrażać bezpieczeństwu
              Użytkowników (art. 6 ust. 1 lit. f RODO);
            </Typography>
            <Box pt={1}>
              <Typography variant="body2">
                <strong>3. Okresy przetwarzania danych osobowych</strong>
              </Typography>
            </Box>
            <Typography variant="body2">
              <strong>3.1.</strong> Usługodawca przetwarza dane Użytkownika
              przez okres:
            </Typography>
            <Typography variant="body2">
              3.1.1. korzystania z Serwisu;
            </Typography>
            <Typography variant="body2">
              3.1.2. zgodnie z obowiązującym prawem
            </Typography>
            <Typography variant="body2">
              3.1.3. wycofania udzielonej zgody lub zgłoszenia sprzeciwu
            </Typography>
            <Typography variant="body2">
              3.1.4. dla realizacji ewentualnych roszczeń
            </Typography>
            <Box pt={1}>
              <Typography variant="body2">
                <strong>4. Przekazywanie danych osobowych</strong>
              </Typography>
            </Box>
            <Typography variant="body2">
              <strong>4.1.</strong> Dane osobowe Użytkowników mogą być
              przekazywane następującym kategoriom podmiotów:
            </Typography>
            <Typography variant="body2">
              4.1.1. dostawcy usług księgowych, prawnych i doradczych
              zapewniający Usługodawcy wsparcie księgowe, prawne lub doradcze,
            </Typography>
            <Typography variant="body2">
              4.1.2. pracownicy Usługodawcy oraz osoby związane z Usługodawcą
              umowami cywilnoprawnymi,
            </Typography>
            <Typography variant="body2">
              4.1.3. dostawcy usług zaopatrujący Usługodawcę w rozwiązania
              techniczne,
            </Typography>
            <Typography variant="body2">
              4.1.4. podmioty lub organy uprawnione na podstawie przepisów
              prawa,
            </Typography>
            <Typography variant="body2">4.1.5. Użytkownicy.</Typography>
            <Typography variant="body2">
              <strong>4.2.</strong> Dane osobowe Użytkowników mogą być również
              przekazywane podmiotom trzecim, które zapewniają odpowiedni poziom
              ochrony danych osobowych zgodny ze standardami Europejskiego
              Obszaru Gospodarczego.
            </Typography>
            <Box pt={1}>
              <Typography variant="body2">
                <strong>5. Prawa właścicieli danych osobowych</strong>
              </Typography>
            </Box>
            <Typography variant="body2">
              <strong>5.1.</strong> Użytkownik ma prawo do:
            </Typography>
            <Typography variant="body2">
              5.1.1. dostępu do swoich danych osobowych, cofnięcia zgody na ich
              przetwarzanie,
            </Typography>
            <Typography variant="body2">
              5.1.2. żądania sprostowania swoich danych osobowych lub ich
              aktualizacji,
            </Typography>
            <Typography variant="body2">
              5.1.3. żądania usunięcia swoich danych osobowych, jeżeli przestały
              być niezbędne dla celów, dla których zostały zebrane, bądź jeżeli
              cofnął on zgodę na ich przetwarzanie, a nie zachodzi żadna inna
              przesłanka przetwarzania danych osobowych,
            </Typography>
            <Typography variant="body2">
              5.1.4. może wnieść sprzeciw wobec przetwarzania swoich danych
              osobowych.
            </Typography>
            <Typography variant="body2">
              <strong>5.2.</strong> Jeśli Użytkownik stwierdzi, że przetwarzanie
              jego danych osobowych narusza przepisy RODO, ma prawo złożyć
              skargę do organu nadzorczego.
            </Typography>
            <Box pt={1}>
              <Typography variant="body2">
                <strong>6. Informacje kontaktowe</strong>
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="body2" sx={{ pr: "5px" }}>
                <strong>6.1.</strong> Wszelkie pytania i uwagi dotyczące
                Polityki prywatności lub przetwarzania danych osobowych należy
                kierować na adres
              </Typography>
              <IODMailLink variant="body2" />

              <Typography>.</Typography>
            </Box>
            <Box pt={1}>
              <Typography variant="body2">
                <strong>7. Zmiany w polityce ochrony prywatności</strong>
              </Typography>
            </Box>
            <Typography variant="body2">
              <strong>7.1.</strong> Polityka prywatności jest na bieżąco
              weryfikowana i w razie potrzeby aktualizowana.
            </Typography>
            <Typography variant="body2">
              <strong>7.2.</strong> Data aktualizacji jest umieszczona na górze
              Polityki prywatności.
            </Typography>
            <Typography variant="body2">
              <strong>7.3.</strong> Modyfikacje mogą odbywać się bez
              powiadamiania Użytkownika.
            </Typography>
            <Typography variant="body2">
              <strong>7.4.</strong> Użytkownik może zostać powiadomiony w
              Serwisie o istotnych zmianach zasad przetwarzania danych
              osobowych.
            </Typography>
          </Stack>
        </Panel>
      </Grid>
    </ContainerTemplate>
  );
};

export default PrivacyPolicy;
