import React from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import HelpTemplate from "@shared/ui/templates/HelpTemplate";
import Typography from "@shared/ui/atoms/Typography";
import Panel from "@shared/ui/molecules/Panel/Panel";
import { useHistory } from "react-router-dom";
import routes from "@routes/routes";
import Link from "@shared/ui/atoms/Link";
import { useTranslation } from "react-i18next";

const HowToSearchForOrders = () => {
  const history = useHistory();
  const { i18n } = useTranslation();

  const searchOrders = () => {
    history.push(routes.root.transportOrders.pattern);
  };

  return (
    <HelpTemplate
      seoProps={{
        title: i18n.t("pages.howToSearchForOrders.head.title")
      }}
    >
      <Grid item xs={12} sm={12} md={8} mt={3}>
        <Panel>
          <Stack spacing={2}>
            <Typography variant="h5" component="h1">
              Jak wyszukać zlecenia?
            </Typography>

            <Box pt={1}>
              <Stack spacing={2}>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    <Typography>
                      Przejdź do zakładki{" "}
                      <Box display="inline-block">
                        <Typography color="text.turquoise.main">
                          <Link onClick={searchOrders}>Szukaj zleceń</Link>
                        </Typography>
                      </Box>
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      Uzupełnij filtry, które znajdują się nad listą zleceń
                      według własnych kryteriów
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      Przeglądaj zlecenia, które Cię interesują
                    </Typography>
                  </li>
                </ul>
                <Typography>
                  Zlecenia możesz filtrować na podstawie tras, terminów, ilości
                  pasażerów oraz odległości.
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </Panel>
      </Grid>
    </HelpTemplate>
  );
};

export default HowToSearchForOrders;
