import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import DoneIcon from "@mui/icons-material/Done";
import { useTranslation } from "react-i18next";
import useStyles from "./HorizontalLabelPositionBelowSteppe.styles";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50%)",
    right: "calc(50%)"
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: theme.palette.primary.main
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: theme.palette.primary.main
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: 0,
    backgroundColor: theme.palette.border.grey.light,
    borderRadius: 1
  }
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.border.grey.light,
  zIndex: 1,
  width: 20,
  height: 20,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  border: `2px solid ${theme.palette.border.grey.light}`,
  svg: {
    color: theme.palette.background.default
  },
  ...(ownerState.active && {
    border: `2px solid ${theme.palette.border.turquoise.main}`,
    svg: {
      color: theme.palette.primary.main
    },
    background: theme.palette.background.paper
  }),
  ...(ownerState.completed && {
    border: `2px solid ${theme.palette.border.turquoise.main}`,
    svg: {
      color: theme.palette.background.paper
    },
    background: theme.palette.primary.main
  })
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const result = () => {
    if (completed) {
      return <DoneIcon sx={{ width: "14px" }} />;
    }

    if (active) {
      return <FiberManualRecordIcon sx={{ width: "12px" }} />;
    }

    return <FiberManualRecordIcon sx={{ width: "24px" }} />;
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {result()}
    </ColorlibStepIconRoot>
  );
}

const HorizontalLabelPositionBelowStepper: React.FC<any> = (value) => {
  const val = value;
  const { classes } = useStyles();
  const { i18n } = useTranslation();

  const steps = [
    i18n.t("ui.horizontal_stepper.category"),
    i18n.t("ui.horizontal_stepper.shipment_route_and_dates"),
    i18n.t("ui.horizontal_stepper.additional_information"),
    i18n.t("ui.horizontal_stepper.summary")
  ];

  return (
    <Box>
      <Stepper
        alternativeLabel
        activeStep={val.step}
        connector={<ColorlibConnector />}
      >
        {steps.map((label) => (
          <Step key={label} className={classes.fontColor}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default HorizontalLabelPositionBelowStepper;
