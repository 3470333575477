import ContainerTemplate from "@shared/ui/templates/ContainerTemplate";
import { Box, Grid } from "@mui/material";
import Stack from "@mui/material/Stack";
import Button from "@shared/ui/atoms/Button";
import routes from "@routes/routes";
import { Link as RouterLink } from "react-router-dom";
import React from "react";
import Typography from "@shared/ui/atoms/Typography";
import AskQuestionIcon from "@shared/ui/atoms/icons/AskQuestionIcon";
import SecuredLetterIcon from "@shared/ui/atoms/icons/SecuredLetterIcon";
import Company from "@assets/images/company.svg";
import ContactMailLink from "@shared/ui/molecules/ContactMailLink";
import { useTranslation } from "react-i18next";
import useStyles from "./Contact.styles";

const Contact: React.FC<any> = () => {
  const { i18n } = useTranslation();
  const { classes } = useStyles();

  return (
    <ContainerTemplate
      seoProps={{
        title: i18n.t("pages.contact.head.title"),
        description: i18n.t("pages.contact.head.description")
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Stack spacing={2}>
            <Typography variant="h3" component="h1">
              Kontakt
            </Typography>

            <Typography variant="subtitle1">
              Masz jakieś pytania które Cię nurtują i nie wiesz jak sobie z
              czymś poradzić?
            </Typography>
          </Stack>
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={3}>
        <Grid item xs={12} md={6} lg={3}>
          <Box className={classes.box} p={4}>
            <Stack spacing={1}>
              <Box textAlign="center">
                <SecuredLetterIcon />
              </Box>
              <Box>
                <Typography
                  variant="h6"
                  component="h3"
                  color="text.turquoise.dark"
                  textAlign="left"
                >
                  Adres kontaktowy
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2" textAlign="left">
                  Jeżeli masz jakieś pytania dotyczące obsługi aplikacji to
                  napisz do Nas na adres:{" "}
                </Typography>
                <ContactMailLink variant="body2" />
              </Box>
            </Stack>
          </Box>
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <Box className={classes.box} p={4}>
            <Stack spacing={1}>
              <Box textAlign="center" justifyContent="center">
                <AskQuestionIcon />
              </Box>

              <Box>
                <Typography
                  variant="h6"
                  color="text.turquoise.dark"
                  textAlign="left"
                >
                  Pytania pomocnicze
                </Typography>
              </Box>
              <Stack spacing={2}>
                <Box>
                  <Typography variant="body2" textAlign="left">
                    Na stronie Pomoc znajdziesz podstawowe pytania które pomogą
                    Ci swobodniej poruszać się po aplikacji.
                  </Typography>
                </Box>
                <Box textAlign="center" justifyContent="center">
                  <Button
                    component={RouterLink}
                    variant="outlined"
                    to={routes.root.help()}
                  >
                    Pokaż więcej
                  </Button>
                </Box>
              </Stack>
            </Stack>
          </Box>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Stack direction="column" height="100%" justifyContent="center">
            <img src={Company} alt="company" height="300" />
          </Stack>
        </Grid>
      </Grid>
    </ContainerTemplate>
  );
};

export default Contact;
