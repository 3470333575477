import * as yup from "yup";
import { AsYouType } from "mui-tel-input";

const validationSchema = () => {
  return yup.object({
    company: yup.object().when("accountType", {
      is: "company",
      then: (schema) =>
        schema
          .shape({
            id: yup.string().required(),
            nip: yup.string().min(10).required()
          })
          .required()
    }),
    avatarColor: yup.string().required(),
    email: yup.string().required(),
    password: yup.string().required().min(8),
    name: yup.string().required(),
    phoneNumber: yup
      .string()
      .transform((value) => {
        const asYouType = new AsYouType();

        asYouType.input(value);

        return asYouType.getNationalNumber();
      })
      .required()
  });
};

export default validationSchema;
