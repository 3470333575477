import React from "react";
import MUIMenu from "@mui/material/Menu";

const Menu: React.FC<any> = (props) => {
  const { children, anchorEl, onClose } = props;

  const open = Boolean(anchorEl);

  return (
    <MUIMenu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      PaperProps={{
        elevation: 0,
        sx: {
          minWidth: "230px",
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1
          }
        }
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      {children}
    </MUIMenu>
  );
};

export default Menu;
